import {useEffect, useMemo} from 'react';
import {faLock} from '@fortawesome/free-solid-svg-icons';

import {OpenOrdersQuery, useOpenOrdersQuery} from '@/graphql/types';
import {
  ADMIN_ORDER_PATH,
  ADMIN_PATH,
  ORDERS_PATH,
  ROOT_PATH,
  SAVED_CATERERS_PATH,
  SIGN_IN_PATH,
  START_NEW_ORDER_PATH,
} from '@/paths';
import compilePath from '@/utils/compilePath';
import gtm from '@/utils/gtm';
import RequiredPath from '@/utils/RequiredPath';
import {type TrackingFunction} from '../TrackingProvider';
import ConsumerCart, {type ConsumerCartProps} from './components/ConsumerCart';
import DirectConnectButton from './components/DirectConnectButton';
import {NotificationType} from './components/DirectConnectButton/Notification.types';
import LandingDropdownMenus from './components/LandingDropdownMenus';
import ContactUsDropdownMenu from './components/LandingDropdownMenus/ContactUsDropdownMenu';
import LargeDesktopExperience from './components/LargeDesktopExperience';
import Logo2023 from './components/Logo';
import MobileAndTabletExperience from './components/MobileAndTabletExperience';
import {MobileMenuBars} from './components/ProfileAppBarItem';
import ProfileAppBarItem from './components/ProfileAppBarItem/ProfileAppBarItem';
import StartANewOrderButton from './components/ProfileAppBarItem/StartANewOrderButton';
import {CurrentUserType} from './CurrentUser.types';
import {
  AdminLink,
  ChannelPartnerLogo,
  StyledEzIcon,
  StyledEzLink,
  StyledSupportNumberLink,
} from './LegacyAppBar.styles';
import {type Order} from './types';

export type LegacyAppBarProps = Omit<ConsumerCartProps, 'trackLink'> & {
  contactUsNavItemHandler?: () => void;
  notifications?: NotificationType[] | undefined;
  currentUser?: CurrentUserType;
  supportPhoneNumber: string;
  supportFaxNumber?: string;
  openLibertyChat?: () => void;
  onStartNewOrderClick: () => void;
  simplified?: boolean;
  landingPage?: boolean;
  track: TrackingFunction;
  cartId?: string | undefined;
  corpPortalHost: string;
  ezRewardsEligibility?: {
    rewardsEnabled: boolean;
    balanceEnabled: boolean;
    earningsEnabled: boolean;
  };
  ez449Eligibility?: {
    startANewOrderButtonSearchPath: string;
    shouldShowStartANewOrderButton: boolean;
  };
  pointsGivenForReview: number;
  channelPartnerLogoUrl?: string | undefined;
};

type FulfillmentDetailConnection = RequiredPath<
  OpenOrdersQuery,
  ['identity', 'fulfillmentDetails']
>;

type FulfillmentDetailConnectionConsumerCart = RequiredPath<
  FulfillmentDetailConnection,
  ['edges', number, 'node', 'consumerCarts', number]
>;

const formatConsumerCart = ({
  consumerCart,
  eventName,
  updatedAt,
  accountName,
}: {
  consumerCart: FulfillmentDetailConnectionConsumerCart;
  eventName?: string | null;
  updatedAt?: string | null;
  accountName?: string;
}): Order => {
  return {
    eventName,
    id: consumerCart.id,
    caterer: consumerCart.caterer,
    updatedAt,
    accountName,
  };
};

const formatOrders = (fulfillmentDetailConnection?: FulfillmentDetailConnection | null) => {
  if (!fulfillmentDetailConnection?.edges) return [];

  return fulfillmentDetailConnection.edges
    .flatMap(fulfillmentDetailEdge => {
      const fulfillmentDetail = fulfillmentDetailEdge.node;
      if (!fulfillmentDetail?.consumerCarts) return null;

      return fulfillmentDetail.consumerCarts.map(cart =>
        formatConsumerCart({
          consumerCart: cart,
          eventName: fulfillmentDetail.eventName,
          updatedAt: fulfillmentDetail.order?.updatedAt,
          accountName: fulfillmentDetail?.corpAccount?.name,
        }),
      );
    })
    ?.filter(cart => cart != null);
};

const LegacyAppBar: React.FC<LegacyAppBarProps> = ({
  startNewOrderPath,
  loginPath = compilePath(SIGN_IN_PATH),
  compileOrderPath,
  onOpen,
  onClickOrder,
  menuPageClickHandler,
  cartHasItems,
  contactUsNavItemHandler,
  notifications,
  currentUser,
  supportPhoneNumber,
  supportFaxNumber,
  openLibertyChat,
  onStartNewOrderClick,
  simplified = false,
  landingPage = false,
  track,
  cartId = null,
  corpPortalHost,
  ezRewardsEligibility = {
    rewardsEnabled: false,
    balanceEnabled: false,
    earningsEnabled: false,
  },
  ez449Eligibility = {
    shouldShowStartANewOrderButton: false,
    startANewOrderButtonSearchPath: '',
  },
  pointsGivenForReview,
  channelPartnerLogoUrl = null,
}) => {
  const {startANewOrderButtonSearchPath, shouldShowStartANewOrderButton} = ez449Eligibility;
  const isLoggedIn = !!currentUser;
  const isAdmin = !!currentUser?.adminAccount?.id;
  const canAccessCorpPortal = !!currentUser?.canAccessCorpPortal;
  const {data, loading, called} = useOpenOrdersQuery();

  const orders = useMemo(() => formatOrders(data?.identity?.fulfillmentDetails), [data]);

  const trackLink = (path: string, title: string) => {
    track('navlink-clicked', {
      url: window.location.href,
      misc_json: JSON.stringify({item_clicked: {link: path, text: title}}),
    });

    gtm.dataLayer({
      event: 'track_event_header_nav',
      event_category: 'Navigation',
      event_action: 'Header',
      event_label: title,
      event_value: 0,
      non_interaction: false,
    });
  };

  const cartProps = {
    isLoggedIn,
    startNewOrderPath,
    loginPath,
    orders,
    loading,
    compileOrderPath,
    onOpen,
    onClickOrder,
    accountHierarchyPresent: Boolean(currentUser?.hasAccountHierarchy),
  } satisfies Partial<React.ComponentProps<typeof ConsumerCart>>;

  useEffect(() => {
    if (called && !loading)
      track('consumer-cart-data-loaded', {
        misc_json: JSON.stringify({cartCount: orders.length}),
      });
  }, [called, loading, orders.length, track]);

  return (
    <>
      <LargeDesktopExperience>
        <div className="flex h-20 min-h-20 items-center justify-between gap-3 border-b border-[#e6e9eb] bg-white p-[25px] text-[#323232]">
          <div className="flex items-center justify-start gap-3">
            <Logo2023
              href={isLoggedIn ? compilePath(START_NEW_ORDER_PATH) : compilePath(ROOT_PATH)}
              trackLink={trackLink}
            />
            {channelPartnerLogoUrl && (
              <>
                <div className="ml-1.5 mr-2.5 h-[30px] w-px bg-[#d9d9d9]" />
                <ChannelPartnerLogo
                  data-testid="channel-partner-logo"
                  src={channelPartnerLogoUrl}
                />
              </>
            )}
            {landingPage && !isLoggedIn && <LandingDropdownMenus trackLink={trackLink} />}
            {!simplified && !landingPage && (
              <>
                {isLoggedIn && (
                  <>
                    {!shouldShowStartANewOrderButton && (
                      <StyledEzLink
                        use="reset"
                        onClick={() => {
                          trackLink(compilePath(START_NEW_ORDER_PATH), 'Home');
                          onStartNewOrderClick?.();
                        }}
                      >
                        <a href={compilePath(START_NEW_ORDER_PATH)}>Home</a>
                      </StyledEzLink>
                    )}

                    <StyledEzLink
                      use="reset"
                      onClick={() => {
                        trackLink(compilePath(ORDERS_PATH), 'Orders');
                      }}
                    >
                      <a href={compilePath(ORDERS_PATH)}>Orders</a>
                    </StyledEzLink>
                    <StyledEzLink
                      use="reset"
                      onClick={() => {
                        trackLink(compilePath(SAVED_CATERERS_PATH), 'Saved restaurants');
                      }}
                    >
                      <a href={compilePath(SAVED_CATERERS_PATH)}>Saved restaurants</a>
                    </StyledEzLink>

                    {shouldShowStartANewOrderButton && (
                      <StartANewOrderButton
                        startANewOrderButtonSearchPath={startANewOrderButtonSearchPath}
                        trackLink={trackLink}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="flex items-center justify-start gap-3">
            {!simplified && canAccessCorpPortal && (
              <AdminLink
                onClick={() => {
                  trackLink(corpPortalHost, 'Corporate Admin');
                }}
              >
                <a href={corpPortalHost}>Corporate Admin</a>
              </AdminLink>
            )}
            {shouldShowStartANewOrderButton ? (
              <StyledSupportNumberLink
                href={`tel:${supportPhoneNumber}`}
                onClick={() => {
                  trackLink(`tel:${supportPhoneNumber}`, supportPhoneNumber);
                }}
              >
                {supportPhoneNumber}
              </StyledSupportNumberLink>
            ) : (
              <StyledEzLink
                href={`tel:${supportPhoneNumber}`}
                onClick={() => {
                  trackLink(`tel:${supportPhoneNumber}`, supportPhoneNumber);
                }}
              >
                {supportPhoneNumber}
              </StyledEzLink>
            )}
            {contactUsNavItemHandler ? (
              <button
                type="button"
                className="mr-1 rounded px-2 py-[11px] leading-4 outline-none hover:bg-green-90 focus:bg-green-90 focus:ring-2 focus:ring-[#323232] active:bg-green-90"
                onClick={() => {
                  trackLink('#', 'Contact Us Button');
                  contactUsNavItemHandler();
                }}
              >
                Contact Us
              </button>
            ) : (
              <ContactUsDropdownMenu
                supportPhoneNumber={supportPhoneNumber}
                supportFaxNumber={supportFaxNumber}
                openLibertyChat={openLibertyChat}
                trackLink={trackLink}
              />
            )}
            {!isLoggedIn && (
              <StyledEzLink
                use="reset"
                onClick={() => {
                  trackLink(loginPath, 'Sign In');
                }}
              >
                <a href={loginPath}>Sign In</a>
              </StyledEzLink>
            )}
            {!simplified && (
              <>
                <div className="ml-1.5 mr-2.5 h-[30px] w-px bg-[#d9d9d9]" />
                {isLoggedIn && (
                  <ProfileAppBarItem
                    currentUser={currentUser}
                    track={track}
                    trackLink={trackLink}
                    pointsGivenForReview={pointsGivenForReview}
                    ezRewardsEligibility={ezRewardsEligibility}
                  />
                )}
                {isLoggedIn && notifications && notifications.length > 0 && (
                  <DirectConnectButton notifications={notifications} trackLink={trackLink} />
                )}
                {isAdmin && (
                  <StyledEzLink
                    href={
                      cartId ? compilePath(ADMIN_ORDER_PATH, {cartId}) : compilePath(ADMIN_PATH)
                    }
                    data-testid="admin-nav-bar-item"
                    onClick={() => {
                      trackLink(
                        cartId ? compilePath(ADMIN_ORDER_PATH, {cartId}) : compilePath(ADMIN_PATH),
                        'admin-lock-icon',
                      );
                    }}
                  >
                    <StyledEzIcon icon={faLock} />
                  </StyledEzLink>
                )}
                <ConsumerCart {...cartProps} trackLink={trackLink} />
              </>
            )}
          </div>
        </div>
      </LargeDesktopExperience>
      <MobileAndTabletExperience>
        <div className="flex h-20 min-h-20 items-center justify-between gap-3 border-b border-[#e6e9eb] bg-white p-[25px] pl-[min(17px,3%)] text-[#323232]">
          <div className="flex items-center justify-start gap-3">
            <MobileMenuBars
              supportPhoneNumber={supportPhoneNumber}
              supportFaxNumber={supportFaxNumber}
              landingPage={landingPage}
              track={track}
              trackLink={trackLink}
              currentUser={currentUser}
              corpPortalHost={corpPortalHost}
              ezRewardsEligibility={ezRewardsEligibility}
              pointsGivenForReview={pointsGivenForReview}
            />
            <Logo2023
              href={isLoggedIn ? compilePath(START_NEW_ORDER_PATH) : compilePath(ROOT_PATH)}
              trackLink={trackLink}
            />
          </div>
          {!simplified && (
            <div className="flex items-center justify-start gap-3">
              {notifications && (
                <DirectConnectButton notifications={notifications} trackLink={trackLink} />
              )}
              {isAdmin && (
                <StyledEzLink
                  href={cartId ? compilePath(ADMIN_ORDER_PATH, {cartId}) : compilePath(ADMIN_PATH)}
                  data-testid="admin-nav-bar-item"
                  onClick={() => {
                    trackLink(
                      cartId ? compilePath(ADMIN_ORDER_PATH, {cartId}) : compilePath(ADMIN_PATH),
                      'admin-lock-icon',
                    );
                  }}
                >
                  <StyledEzIcon icon={faLock} />
                </StyledEzLink>
              )}
              <ConsumerCart
                {...cartProps}
                menuPageClickHandler={menuPageClickHandler}
                cartHasItems={cartHasItems}
                trackLink={trackLink}
              />
            </div>
          )}
        </div>
      </MobileAndTabletExperience>
    </>
  );
};

export default LegacyAppBar;
