import {useRef} from 'react';
import {EzPopover} from '@ezcater/recipe';
import {faChevronDown, faCircleUser} from '@fortawesome/free-solid-svg-icons';

import {type TrackingFunction} from '@/components/TrackingProvider';
import useToggleState from '@/hooks/useToggleState';
import {CurrentUserType} from '../../CurrentUser.types';
import MyAccountNav from './MyAccountNav';
import {
  DropdownTriangle,
  Name,
  NameContainer,
  NavDropdownMenu,
  Points,
  ProfileAppBarMouseContainer,
  ProfileButton,
  StyledChevron,
  StyledEzIcon,
} from './ProfileAppBarItem.styles';
import {formatPoints} from './utils';

type ProfileToolbarItemProps = {
  currentUser: CurrentUserType | undefined;
  track: TrackingFunction;
  trackLink: (path: string, title: string) => void;
  pointsGivenForReview: number;
  ezRewardsEligibility: {
    rewardsEnabled: boolean;
    balanceEnabled: boolean;
    earningsEnabled: boolean;
  };
};

const ProfileAppBarItem: React.FC<ProfileToolbarItemProps> = ({
  currentUser,
  track,
  trackLink,
  pointsGivenForReview,
  ezRewardsEligibility,
}) => {
  const {toggleValue: isOpen, on: show, off: hide} = useToggleState();
  const buttonRef = useRef(null);
  const {balanceEnabled} = ezRewardsEligibility;

  return (
    <ProfileAppBarMouseContainer onMouseEnter={show} onMouseLeave={hide}>
      <ProfileButton
        type="button"
        ref={buttonRef}
        data-testid="profile-nav-bar-item"
        isOpen={isOpen}
      >
        <StyledEzIcon icon={faCircleUser} />
        <NameContainer>
          <Name>{currentUser?.consumerAccount.firstName}</Name>
          {currentUser && balanceEnabled && (
            <Points>
              {formatPoints(currentUser.consumerAccount.ezRewardsAccount?.balance || 0)} pts
            </Points>
          )}
        </NameContainer>
        <StyledChevron icon={faChevronDown} />
      </ProfileButton>

      {isOpen && (
        <EzPopover targetRef={buttonRef} shouldCloseOnBlur onClose={() => null}>
          <DropdownTriangle />
          <NavDropdownMenu>
            <MyAccountNav
              currentUser={currentUser}
              track={track}
              trackLink={trackLink}
              area="desktop"
              pointsGivenForReview={pointsGivenForReview}
              ezRewardsEligibility={ezRewardsEligibility}
            />
          </NavDropdownMenu>
        </EzPopover>
      )}
    </ProfileAppBarMouseContainer>
  );
};

export default ProfileAppBarItem;
