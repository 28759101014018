import Accordion from '@/components/Accordion';

type MenuAccordionProps = React.PropsWithChildren<{
  ariaLabel?: string;
  header: React.ReactNode;
}>;

const MenuAccordion: React.FC<MenuAccordionProps> = ({
  ariaLabel = 'menu dropdown',
  children,
  header,
}) => (
  <Accordion
    slotProps={{
      headerButton: {
        'aria-label': ariaLabel,
        className: 'py-2 px-4 hover:bg-peppercorn-100 mb-0 font-normal',
      },
    }}
    header={header}
  >
    <div className="flex flex-col gap-4 px-4 pb-6 pt-2">{children}</div>
  </Accordion>
);

export default MenuAccordion;
