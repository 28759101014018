import {twMerge} from 'tailwind-merge';

import useOrderingExperience from '@/hooks/useOrderingExperience';

export enum RibbonState {
  Warning,
  Success,
  Error,
}

type RibbonPropType = {
  message: React.ReactNode;
  arrowPlacement?: 'right' | 'left' | 'up' | null;
  state?: RibbonState;
  icon?: JSX.Element;
  className?: string;
};

const Ribbon = ({
  message,
  arrowPlacement = null,
  state = RibbonState.Warning,
  icon,
  className,
}: RibbonPropType) => {
  const {isEzOrdering} = useOrderingExperience();

  const isWarning = state === RibbonState.Warning;
  const isSuccess = state === RibbonState.Success;
  const isError = state === RibbonState.Error;

  return (
    <div
      className={twMerge(
        'relative inline-block max-w-[12.5rem] rounded p-2 text-left text-xs font-bold text-white',
        isWarning && 'bg-orange-400',
        isSuccess && 'bg-ez-green',
        isEzOrdering && 'bg-ezo-secondary',
        isError && 'bg-red-110',
        className,
      )}
    >
      <div className="flex gap-1">
        {icon}
        {message}
      </div>
      {arrowPlacement === 'right' && (
        <span
          className={twMerge(
            'absolute -right-7 bottom-0 top-0 block h-0 w-0 border-b-[17px] border-l-[18px] border-t-[15px] border-b-transparent border-t-transparent',
            isWarning && 'border-l-orange-400',
            isSuccess && 'border-l-ez-green',
            isEzOrdering && 'border-l-ezo-secondary',
            isError && 'border-l-red-110',
            'left-[calc(100%-2px)]',
          )}
          aria-hidden="true"
        />
      )}
      {arrowPlacement === 'left' && (
        <span
          className={twMerge(
            'absolute -left-[15px] -right-7 bottom-0 top-0 block h-0 w-0 border-b-[17px] border-r-[18px] border-t-[15px] border-b-transparent border-t-transparent',
            isWarning && 'border-r-orange-400',
            isSuccess && 'border-r-ez-green',
            isEzOrdering && 'border-r-ezo-secondary',
            isError && 'border-r-red-110',
          )}
          aria-hidden="true"
        />
      )}
      {arrowPlacement === 'up' && (
        <span
          className={twMerge(
            'absolute bottom-[calc(100%-2px)] right-0 block h-0 w-0 border-b-[9px] border-l-[10px] border-r-[10px] border-l-transparent border-r-transparent',
            isWarning && 'border-b-orange-400',
            isSuccess && 'border-b-ez-green',
            isEzOrdering && 'border-b-ezo-secondary',
            isError && 'border-b-red-110',
          )}
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default Ribbon;
