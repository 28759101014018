import {EzPopover} from '@ezcater/recipe';
import {faEnvelope} from '@fortawesome/pro-light-svg-icons';

import MobileModalHeader from '../MobileModalHeader';
import ScrollLock from '../ProfileAppBarItem/Modal/ScrollLock';
import DirectConnectNotification from './DirectConnectNotification';
import {
  BetaMessage,
  BetaMessageIcon,
  BetaMessageText,
  DirectConnectPopper,
  DirectConnectPopperMessage,
  EventList,
  Title,
} from './DirectConnectPopover.styles';
import {NotificationType} from './Notification.types';

export type DirectConnectPopoverProps = {
  navItemRef: React.MutableRefObject<null>;
  notifications: NotificationType[];
  onCloseCallback: () => void;
  isMobile?: boolean;
  trackLink: (path: string, title: string) => void;
};

const DirectConnectPopover: React.FC<DirectConnectPopoverProps> = ({
  navItemRef,
  notifications,
  onCloseCallback,
  isMobile = false,
  trackLink,
}) => {
  const openFeedbackEmail = () => {
    const path = 'mailto:support@ezcater.com';
    trackLink(path, 'Give Us Feedback');
    window.location.href = 'mailto:support@ezcater.com';
  };

  if (notifications.length > 0) {
    return (
      <EzPopover targetRef={navItemRef} shouldCloseOnBlur onClose={onCloseCallback}>
        {isMobile && <ScrollLock />}
        <DirectConnectPopper>
          {isMobile && <MobileModalHeader hideModal={onCloseCallback} />}
          <Title>Messages from the Caterer</Title>
          <EventList>
            {notifications.map(
              ({id, orderUuid, isUnread, brandName, messageType, changeType, message}) => (
                <DirectConnectNotification
                  key={id}
                  brandName={brandName}
                  changeType={changeType}
                  isUnread={isUnread}
                  message={message}
                  messageType={messageType}
                  orderUuid={orderUuid}
                  onCloseCallback={onCloseCallback}
                  trackLink={trackLink}
                />
              ),
            )}
          </EventList>
        </DirectConnectPopper>
      </EzPopover>
    );
  }

  return (
    <EzPopover targetRef={navItemRef} shouldCloseOnBlur onClose={onCloseCallback}>
      {isMobile && <ScrollLock />}
      <DirectConnectPopperMessage>
        <MobileModalHeader hideModal={onCloseCallback} />
        <BetaMessage>
          <BetaMessageIcon icon={faEnvelope} size="large" />
          <BetaMessageText>
            Caterer Messages is a new experience we are trying out. Some things will change about
            this experience in the future.
          </BetaMessageText>
          <button
            type="button"
            onClick={openFeedbackEmail}
            className="whitespace-nowrap rounded border border-blue-90 bg-blue-90 px-4 py-1.5 font-bold leading-5 text-white hover:border-[#316da1] hover:bg-[#316da1] focus:border-[#3a81be] focus:bg-[#3a81be] focus:ring-2 focus:ring-[#151515] active:border-[#2b608e] active:bg-[#2b608e]"
          >
            Give Us Feedback
          </button>
        </BetaMessage>
      </DirectConnectPopperMessage>
    </EzPopover>
  );
};

export default DirectConnectPopover;
