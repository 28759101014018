import {useMemo, useState} from 'react';
import {useDebounce} from 'react-use';

import {type SavedAddressSearchQuery, useSavedAddressSearchQuery} from '@/graphql/types';
import {type Address as AddressResult} from '@/utils/googleAutocomplete';
import RequiredPath from '@/utils/RequiredPath';

const DEBOUNCE_TIMEOUT = 150; // in milliseconds (ms)
const EMPTY_CONNECTION = {edges: [], totalCount: 0};

export type SavedAddressSearchProps = {
  searchTerm?: string;
  skip?: boolean;
  limit?: number;
};

type QueryAddressEdge = RequiredPath<
  SavedAddressSearchQuery,
  ['me', 'consumerAccount', 'addresses', 'edges']
>;
type QueryAddress = RequiredPath<QueryAddressEdge, [number, 'node']>;

type SavedAddressSearchResults = {
  addresses: AddressResult[];
  totalCount: number;
};

const savedAddressToAddress = (address: QueryAddress): AddressResult => ({
  mainText: address.name || '',
  secondaryText: address.fullAddress,
  name: address.fullAddress,
  isAutocompleteResult: false,
  placeId: address.uuid,
  corporate: address.addressBookEntry?.corporate,
});

const extractAddressResults = (data?: SavedAddressSearchQuery): SavedAddressSearchResults => {
  const {edges, totalCount} = data?.me?.consumerAccount?.addresses ?? EMPTY_CONNECTION;
  const addresses = edges
    .map(({node}) => (node ? savedAddressToAddress(node) : null))
    .filter(node => node) as AddressResult[];

  return {addresses, totalCount};
};

const useSavedAddressSearch = ({
  searchTerm,
  skip,
  limit,
}: SavedAddressSearchProps): SavedAddressSearchResults => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    DEBOUNCE_TIMEOUT,
    [searchTerm],
  );

  const {data} = useSavedAddressSearchQuery({
    variables: {searchString: debouncedSearchTerm, limit},
    skip: skip || !debouncedSearchTerm,
  });

  return useMemo(() => extractAddressResults(data), [data]);
};

export default useSavedAddressSearch;
