import {Icon} from '@ezcater/tapas';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import useTranslation from 'next-translate/useTranslation';

import AboutUsMenu from './AboutUsMenu';
import CompanyMenu from './CompanyMenu';
import MenuTooltip from './MenuTooltip';
import RestaurantMenu from './RestaurantMenu';

const LoggedOutMenu: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  return (
    <div className="flex items-center gap-10">
      <MenuTooltip
        content={<CompanyMenu />}
        ariaLabel={t('navLinks.corporateCateringSolutions')}
        arrowClasses="right-[18px]"
        placement="bottom"
      >
        <div className="flex cursor-pointer items-center gap-2">
          {t('navLinks.corporateCateringSolutions')} <Icon icon={faChevronDown} />
        </div>
      </MenuTooltip>

      <MenuTooltip
        content={<RestaurantMenu />}
        ariaLabel={t('navLinks.forRestaurants')}
        arrowClasses="right-[70px]"
        placement="bottom"
      >
        <div className="flex cursor-pointer items-center gap-2">
          {t('navLinks.forRestaurants')} <Icon icon={faChevronDown} />
        </div>
      </MenuTooltip>

      <MenuTooltip
        content={<AboutUsMenu />}
        ariaLabel={t('navLinks.aboutUs')}
        arrowClasses="right-10"
        placement="bottom"
      >
        <div className="flex cursor-pointer items-center gap-2">
          {t('navLinks.aboutUs')} <Icon icon={faChevronDown} />
        </div>
      </MenuTooltip>
    </div>
  );
};

export default LoggedOutMenu;
