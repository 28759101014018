import styled from '@emotion/styled';
// eslint-disable-next-line no-restricted-imports
import {EzIcon} from '@ezcater/recipe';

import appColors from '@/components/RootProvider/appColors';
import appFonts from '@/components/RootProvider/appFonts';
import recipeMq from '@/styles/recipeMq';

export const DirectConnectPopper = styled.div`
  position: absolute;
  background-color: ${appColors.white};
  border-radius: 0.25rem;
  box-shadow: 0 2px 20px 0 rgba(153, 153, 153, 0.35);
  overflow: auto;
  top: 100%;
  width: 350px;
  right: -10px;
  z-index: 10000 !important;
  list-style: none;

  ${recipeMq('baseToMedium')} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    box-shadow: none;
    border-radius: 0;
  }
`;

export const DirectConnectPopperMessage = styled(DirectConnectPopper)`
  border-radius: 0.5rem;
  box-shadow: 0 2px 20px 10px rgba(153, 153, 153, 0.35);
  left: auto;
  right: -10px;
  width: 325px;

  ${recipeMq('baseToMedium')} {
    left: 0;
    right: auto;
  }
`;

// padding: ${({theme}) => theme.spacing(1.5)};
export const Title = styled.div`
  font-weight: ${appFonts.fontWeightSemiBold};
  color: ${appColors.black};
  font-size: ${appFonts.textBase};
  padding: 16px;
  border-bottom: 1px solid ${appColors.gray300};
`;

export const EventList = styled.div`
  max-height: calc(100vh - 100px);

  ${recipeMq('medium')} {
    max-height: 500px;
    overflow-y: auto;
  }
`;

export const BetaMessage = styled.div`
  padding: 20px;
  text-align: center;
`;

export const BetaMessageIcon = styled(EzIcon)`
  display: inline-block;
`;

export const BetaMessageText = styled.div`
  margin: 16px 0;
  ${recipeMq('baseToMedium')} {
    color: ${appColors.gray600};
  }
`;
