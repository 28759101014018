// eslint-disable-next-line no-restricted-imports
import {Icon} from '@ezcater/tapas';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';

import {type TrackingFunction} from '@/components/TrackingProvider';
import {CurrentUserType} from '../../CurrentUser.types';
import ContactUsDropdownMenu from '../LandingDropdownMenus/ContactUsDropdownMenu';
import LandingDropdownMenus from '../LandingDropdownMenus/LandingDropdownMenus';
import Logo2023 from '../Logo';
import Modal from './Modal';
import MyAccountNav from './MyAccountNav';
import {
  CloseButton,
  ModalContent,
  NeedHelpContent,
  WelcomeMessage,
} from './ProfileMenuModal.styles';

type ProfileMenuModalProps = {
  isOpen: boolean;
  onDismiss?: () => void;
  close: () => void;
  currentUser: CurrentUserType | undefined;
  supportPhoneNumber?: string;
  supportFaxNumber?: string;
  landingPage?: boolean;
  track: TrackingFunction;
  trackLink: (path: string, title: string) => void;
  corpPortalHost: string;
  pointsGivenForReview: number;
  ezRewardsEligibility: {
    rewardsEnabled: boolean;
    balanceEnabled: boolean;
    earningsEnabled: boolean;
  };
};

const ProfileMenuModal: React.FC<ProfileMenuModalProps> = ({
  isOpen,
  onDismiss,
  close,
  currentUser,
  supportPhoneNumber,
  supportFaxNumber,
  landingPage = false,
  track,
  trackLink,
  corpPortalHost,
  pointsGivenForReview,
  ezRewardsEligibility,
}) => {
  const isLoggedIn = currentUser != null;

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <div className="flex h-20 min-h-20 items-center justify-between gap-3 border-b border-[#e6e9eb] bg-white p-[25px] text-[#323232]">
        <Logo2023 trackLink={trackLink} />
        <CloseButton type="button" onClick={close}>
          <Icon icon={faXmark} aria-label="Close menu" />
        </CloseButton>
      </div>
      <ModalContent>
        <WelcomeMessage>
          {isLoggedIn ? `Welcome, ${currentUser.consumerAccount.firstName}` : 'Welcome to ezCater!'}
        </WelcomeMessage>
        {!isLoggedIn && landingPage && (
          <>
            <LandingDropdownMenus trackLink={trackLink} />
            <ContactUsDropdownMenu
              supportPhoneNumber={supportPhoneNumber}
              supportFaxNumber={supportFaxNumber}
              trackLink={trackLink}
            />
          </>
        )}
        <MyAccountNav
          track={track}
          trackLink={trackLink}
          area="mobile"
          corpPortalHost={corpPortalHost}
          currentUser={currentUser}
          showMobileItems
          pointsGivenForReview={pointsGivenForReview}
          ezRewardsEligibility={ezRewardsEligibility}
        />
        <NeedHelpContent>
          <div>Need help?</div>
          <a href="tel:18004881803">1-800-488-1803</a>
          <a href="mailto:support@ezcater.com">support@ezcater.com</a>
        </NeedHelpContent>
      </ModalContent>
    </Modal>
  );
};

export default ProfileMenuModal;
