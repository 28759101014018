import {useCallback, useMemo, useRef, useState} from 'react';
import {captureException} from '@sentry/nextjs';

import {type FulfillmentDetailAddressInput} from '@/graphql/types';
import {getPlaceData} from '@/utils/googleAutocomplete';
import {useGoogleMapsGeocode} from './useGoogleMapsGeocode';

type PlaceDataType = {
  mapsAddress: FulfillmentDetailAddressInput;
  utcOffsetMinutes: number | undefined;
};

const useFetchGeoData = () => {
  const emptyPlaceData = useMemo(() => {
    return {
      mapsAddress: {
        street1: undefined,
        city: undefined,
        state: undefined,
      },
      utcOffsetMinutes: undefined,
    };
  }, []);

  const data = useRef<PlaceDataType | undefined>(emptyPlaceData);
  const error = useRef<Error | null>(null);
  const [loading, setLoading] = useState(false);
  const {geocodePlaceResult} = useGoogleMapsGeocode();

  const fetchGeoData = useCallback(
    async (
      googlePlaceId?: string,
    ): Promise<{
      data: PlaceDataType;
      loading: boolean;
      error: Error | null;
    }> => {
      setLoading(true);
      error.current = null;

      const getPlaceResult = async (googlePlaceId: string) => {
        try {
          const placeResult = await new Promise<google.maps.places.PlaceResult>(
            (resolve, reject) => {
              getPlaceData({
                placeId: googlePlaceId,
                callback: placeData => {
                  if (placeData) {
                    resolve(placeData);
                  } else {
                    reject(new Error('No place data found'));
                  }
                },
              });
            },
          );

          const geocodedAddress = await geocodePlaceResult(placeResult);

          if (!geocodedAddress) {
            throw new Error('No geocoded address found');
          }

          const {street, ...address} = geocodedAddress;

          return {
            mapsAddress: {...address, street1: street},
            utcOffsetMinutes: placeResult.utc_offset_minutes,
          };
        } catch (err) {
          captureException(err);
          return null;
        }
      };

      if (!googlePlaceId) {
        data.current = emptyPlaceData;
        setLoading(false);
        return {data: data.current, loading, error: error.current};
      }

      try {
        const placeData = await getPlaceResult(googlePlaceId);

        if (!placeData) {
          throw new Error('No place data found');
        }

        data.current = placeData;
        return {data: data.current, loading, error: error.current};
      } catch (err) {
        error.current = err instanceof Error ? err : new Error('An unknown error occurred');
        return {data: emptyPlaceData, loading, error: error.current};
      } finally {
        setLoading(false);
      }
    },
    [geocodePlaceResult, emptyPlaceData, loading],
  );

  return fetchGeoData;
};

export default useFetchGeoData;
