import styled from '@emotion/styled';
// eslint-disable-next-line no-restricted-imports
import {EzIcon} from '@ezcater/recipe';

import appColors from '@/components/RootProvider/appColors';
import {NavDropdownMenu} from '../ProfileAppBarItem/MyAccountNav.styles';

export const StyledNavBarItem = styled.div`
  padding: 11px 4px;
  cursor: pointer;
  position: relative;
`;

export const StyledEzIcon = styled(EzIcon)`
  margin-left: 6px;
  font-size: 10px;
`;

export const LandingDropdownMouseContainer = styled.div`
  padding: 12px 0;
  cursor: pointer;
`;

export const LandingNavDropdownMenu = styled(NavDropdownMenu)`
  right: -35px;
`;

export const NavDropdownClickableItem = styled.div`
  display: flex;
  align-items: center;
  color: ${appColors.gray500};
  text-decoration: none;
  cursor: pointer;
  padding-left: 10px;
  height: 50px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    color: ${appColors.gray600};
  }
`;
