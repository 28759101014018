type StartANewOrderButtonProps = {
  startANewOrderButtonSearchPath: string;
  trackLink: (path: string, label: string) => void;
};

const StartANewOrderButton: React.FC<StartANewOrderButtonProps> = ({
  startANewOrderButtonSearchPath,
  trackLink,
}) => {
  const handleClick = () => {
    trackLink(startANewOrderButtonSearchPath, 'Start a new order');
  };

  if (startANewOrderButtonSearchPath.trim().length === 0) {
    return null;
  }

  return (
    <a
      href={startANewOrderButtonSearchPath}
      onClick={handleClick}
      aria-label="Navigate to the Start New Order page"
      className="relative inline-block whitespace-nowrap rounded-lg border border-ezgreen-400 bg-ezgreen-400 px-4 py-2 font-bold leading-5 text-white outline-none transition-colors hover:bg-ezgreen-500 hover:text-white hover:no-underline focus:bg-ezgreen-600 focus:ring-2 focus:ring-[#323232] active:bg-ezgreen-600 max-[320px]:hidden"
    >
      Start a new order
    </a>
  );
};

export default StartANewOrderButton;
