import {Icon} from '@ezcater/tapas';
import {faBars} from '@fortawesome/pro-light-svg-icons';

import {type TrackingFunction} from '@/components/TrackingProvider';
import useToggleState from '@/hooks/useToggleState';
import {CurrentUserType} from '../../CurrentUser.types';
import ProfileMenuModal from './ProfileMenuModal';

type MobileMenuBarsProps = {
  currentUser: CurrentUserType | undefined;
  supportPhoneNumber?: string;
  supportFaxNumber?: string;
  landingPage?: boolean;
  track: TrackingFunction;
  trackLink: (path: string, title: string) => void;
  corpPortalHost: string;
  pointsGivenForReview: number;
  ezRewardsEligibility: {
    rewardsEnabled: boolean;
    balanceEnabled: boolean;
    earningsEnabled: boolean;
  };
};

const MobileMenuBars: React.FC<MobileMenuBarsProps> = ({
  currentUser,
  supportPhoneNumber,
  supportFaxNumber,
  landingPage = false,
  track,
  trackLink,
  corpPortalHost,
  pointsGivenForReview,
  ezRewardsEligibility,
}) => {
  const {toggleValue: isOpen, toggle, off} = useToggleState();

  return (
    <>
      <button
        type="button"
        aria-label="Open menu"
        data-testid="mobile-menu-button"
        onClick={toggle}
        onKeyDown={e => e.key === 'Escape' && off()}
        className="text-green-110"
      >
        <Icon icon={faBars} size="medium" className="m-2" />
      </button>
      {isOpen && (
        <ProfileMenuModal
          isOpen={isOpen}
          close={off}
          onDismiss={off}
          currentUser={currentUser}
          supportPhoneNumber={supportPhoneNumber}
          supportFaxNumber={supportFaxNumber}
          landingPage={landingPage}
          trackLink={trackLink}
          corpPortalHost={corpPortalHost}
          track={track}
          pointsGivenForReview={pointsGivenForReview}
          ezRewardsEligibility={ezRewardsEligibility}
        />
      )}
    </>
  );
};

export default MobileMenuBars;
