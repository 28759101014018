import {useMemo} from 'react';
import {Alert, DatePicker} from '@ezcater/tapas';
import {isBefore, parseISO, startOfToday} from 'date-fns';
import {capitalize} from 'lodash-es';
import useTranslation from 'next-translate/useTranslation';

import {useGlobalFulfillmentDetailMutation} from '@/components/GlobalFulfillmentDetailProvider/GlobalFulfillmentDetailProvider';
import {AppBarFulfillmentDetailFragment} from '@/graphql/types';
import useIsAdmin from '@/hooks/useIsAdmin';
import {convertMilitaryTimeToIsoTime} from '@/utils/dateFormat';
import TimePicker from './TimePicker';

type DateTimeProps = {
  fulfillmentDetail: AppBarFulfillmentDetailFragment | null;
};

type changeProps = {
  date?: Date | null;
  time?: number | null;
};

const DateTime: React.FC<DateTimeProps> = ({fulfillmentDetail}) => {
  const {t} = useTranslation('app-bar');
  const isAdmin = useIsAdmin();
  const {updateFulfillmentDetail} = useGlobalFulfillmentDetailMutation() || {};
  const {eventLocalTime, eventOn, strategy} = fulfillmentDetail || {};
  const parsedDate = useMemo(() => (eventOn == null ? null : parseISO(eventOn)), [eventOn]);
  const savedTime = eventLocalTime
    ? parseInt(eventLocalTime.replace(':', '').slice(0, 4), 10)
    : 1200;

  const changeFulfillmentDetail = ({date, time}: changeProps) => {
    if (updateFulfillmentDetail) {
      updateFulfillmentDetail({
        eventOn: date?.toString(),
        eventLocalTime: convertMilitaryTimeToIsoTime(time ?? savedTime),
      });
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-2">
        <label className="text-xl font-bold" htmlFor="app-bar-date-picker">
          {t('eventBar.eventDate.inputs.date', {strategy: capitalize(strategy)})}
        </label>
        <DatePicker
          inline
          onSelectedDateChanged={date => changeFulfillmentDetail({date})}
          shouldDisableDate={date => isBefore(date, startOfToday())}
          slotProps={{
            input: {id: 'app-bar-date-picker'},
            root: {
              className: 'w-full',
            },
            popup: {
              className: 'w-full',
            },
          }}
          value={parsedDate}
        />
        <div className="mt-2" />
        <TimePicker
          isAdmin={isAdmin}
          onChange={time => changeFulfillmentDetail({time})}
          strategy={strategy}
          time={savedTime}
        />
        <Alert variant="info">{t('eventBar.eventDate.info')}</Alert>
      </div>
    </div>
  );
};

export default DateTime;
