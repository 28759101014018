import {memo, useEffect, useRef} from 'react';

const ScrollLock: React.FC<unknown> = () => {
  const documentRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const doc = documentRef.current?.ownerDocument;
    if (doc) {
      const {paddingRight, overflow} = doc.body.style;

      doc.body.style.paddingRight = `${
        (doc.defaultView?.innerWidth || 0) - doc.documentElement.clientWidth
      }px`;
      doc.body.style.overflow = 'hidden';

      return () => {
        doc.body.style.overflow = overflow;
        doc.body.style.paddingRight = paddingRight;
      };
    }

    return null as unknown as void;
  }, []);

  return <span hidden ref={documentRef} />;
};

export default memo(ScrollLock);
