import styled from '@emotion/styled';
// eslint-disable-next-line no-restricted-imports
import {EzIcon} from '@ezcater/recipe';

import appColors from '@/components/RootProvider/appColors';
import appFonts from '@/components/RootProvider/appFonts';

export const ProfileAppBarMouseContainer = styled.div`
  padding: 12px;
  padding-left: 0;
`;

export const ProfileButton = styled('button', {shouldForwardProp: prop => prop !== 'isOpen'})<{
  isOpen: boolean;
}>`
  appearance: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #d6d9e1;
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  font-weight: 700;
  height: 2.5rem;
  width: 9rem;
  color: #212223;
  background-color: ${({isOpen}) => (isOpen ? '#eafff9' : '#fff')};
  font-family: ${appFonts.fontFamilyBase};
  text-align: left;

  &:hover {
    background-color: #eafff9;
  }

  &:focus-visible {
    outline: transparent solid 2px;
    outline-offset: 2px;
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 1px,
      rgb(33, 34, 35) 0px 0px 0px 3.5px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
`;

export const StyledEzIcon = styled(EzIcon)`
  flex-shrink: 0;
  font-size: 1.125rem !important;
`;

export const StyledChevron = styled(EzIcon)`
  height: 100%;
  position: absolute;
  right: 0.5rem;
  top: 0;
  font-size: 1rem;
`;

export const NameContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.25;
  font-weight: 700;
`;

export const Points = styled(Name)`
  color: #2f7f00;
  font-size: 0.75rem;
`;

export const NavDropdownMenu = styled.div`
  padding: 1.5rem 1rem;
  margin: 8px 10px 10px;
  background-color: ${appColors.white};
  border: 1px solid ${appColors.gray300};
  border-radius: 8px;
  box-shadow: 0 2px 20px 0 rgba(153, 153, 153, 0.35);
  position: absolute;
  top: 0;
  right: -82px;
  width: 340px;
  z-index: 10000 !important;
  max-height: calc(100vh - 75px);
  overflow-y: auto;
`;

export const DropdownTriangle = styled.div`
  cursor: pointer;
  z-index: 100000;
  top: 0;
  bottom: 100%;
  right: -55px;
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  transform: translateX(0px) rotate(45deg);
  transform-origin: center;
  border-left: 1px solid ${appColors.gray300};
  border-top: 1px solid ${appColors.gray300};
  background-color: ${appColors.white};
`;
