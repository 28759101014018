import {cloneElement, useEffect, useState} from 'react';
import {useLatest} from 'react-use';
import {ClickAwayListener, Unstable_Popup as MuiPopup} from '@mui/base';
import {AnimatePresence, m as motion} from 'framer-motion';
import {twMerge} from 'tailwind-merge';

type PopupProps = React.PropsWithChildren<{
  animationProps?: React.ComponentProps<typeof motion>;
  hideArrow?: boolean;
  id?: string;
  onPopupToggle: (open: boolean) => void;
  open: boolean;
  placement?: React.ComponentProps<typeof MuiPopup>['placement'];
  slotProps?: {
    arrow?: React.ComponentProps<'div'>;
    popup?: React.ComponentProps<typeof MuiPopup>;
    popupCard?: React.ComponentProps<'div'>;
    root?: React.ComponentProps<'div'>;
  };
  trigger: React.ReactElement;
}>;

const Popup: React.FC<PopupProps> = ({
  animationProps,
  children,
  hideArrow = false,
  id = 'popup',
  onPopupToggle,
  open,
  placement = 'bottom',
  slotProps = {},
  trigger,
  ...props
}) => {
  const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);

  const getArrowClasses = () => {
    switch (placement) {
      case 'bottom-end':
        return '-top-2 right-2 border-l border-t';
      case 'bottom-start':
        return '-top-2 left-6 border-l border-t';
      case 'bottom':
        return '-top-2 left-1/2 border-l border-t';
      case 'left-end':
        return '-right-4 bottom-4 border-r border-t';
      case 'left-start':
        return '-right-4 top-4 border-r border-t';
      case 'left':
        return '-right-4 bottom-[calc(50%_-7.5px)] border-r border-t';
      case 'right-end':
        return 'bottom-4 border-l border-b';
      case 'right-start':
        return 'top-4 border-l border-b';
      case 'right':
        return 'top-[calc(50%_-7.5px)] border-l border-b';
      case 'top-end':
        return '-bottom-2 right-2 border-r border-b';
      case 'top-start':
        return '-bottom-2 left-6 border-r border-b';
      case 'top':
        return '-bottom-2 left-1/2 border-r border-b';
      default:
        return '-top-2 left-1/2';
    }
  };

  const triggerElement = cloneElement(trigger, {
    'aria-labelledby': open ? id : undefined,
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      if (trigger.props.onClick) trigger.props.onClick(event);
      onPopupToggle(!open);
    },
    onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
      if (trigger.props.onKeyDown) trigger.props.onKeyDown(event);
      if (event.key === 'Escape') onPopupToggle(false);
    },
    tabIndex: 0,
    type: 'button',
    ref: setTriggerRef,
  });

  const onPopupToggleRef = useLatest(onPopupToggle);
  useEffect(() => {
    onPopupToggleRef.current?.(open);
  }, [open, onPopupToggleRef]);

  return (
    <div {...props} {...slotProps?.root}>
      {triggerElement}

      <AnimatePresence>
        {open && Boolean(triggerRef) && (
          <ClickAwayListener onClickAway={() => onPopupToggle(false)}>
            <MuiPopup
              anchor={triggerRef}
              disablePortal
              id={open ? id : undefined}
              offset={hideArrow ? 0 : 10}
              open={open}
              placement={placement}
              strategy="fixed"
              tabIndex={-1}
              {...(slotProps?.popup as React.HTMLAttributes<HTMLDivElement>)}
              className={twMerge('z-1300', slotProps?.popup?.className)}
            >
              <motion.div
                animate={{opacity: 1}}
                className="relative z-1300 rounded-xl border shadow-outer"
                exit={{opacity: 0}}
                initial={{opacity: 0}}
                transition={{duration: 0.1}}
                {...animationProps}
              >
                <>
                  {!hideArrow && (
                    <div
                      {...slotProps?.arrow}
                      className={twMerge(
                        'absolute h-[15px] w-[15px] -translate-x-1/2 rotate-45 transform bg-white',
                        getArrowClasses(),
                        slotProps?.arrow?.className,
                      )}
                    />
                  )}

                  <div
                    {...slotProps?.popupCard}
                    className={twMerge(
                      'rounded-xl bg-white p-6 text-peppercorn-800',
                      slotProps?.popupCard?.className,
                    )}
                  >
                    {children}
                  </div>
                </>
              </motion.div>
            </MuiPopup>
          </ClickAwayListener>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Popup;
