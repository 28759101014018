import styled from '@emotion/styled';

import recipeMq from '@/styles/recipeMq';

const LargeDesktopExperience = styled.div`
  display: none;
  ${recipeMq('xlarge')} {
    display: block;
  }
`;

export default LargeDesktopExperience;
