import {useState} from 'react';
import {faBars, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {m as motion} from 'framer-motion';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import useIdentity from '@/hooks/useIdentity';
import {ROOT_PATH} from '@/paths';
import LoggedOutMenu from './Menus/LoggedOutMenu';
import MenuLink from './Menus/MenuLink';
import Admin from './Admin';
import AppBarIconButton from './AppBarIconButton';
import Cart from './Cart';
import ContactUs from './ContactUs';
import EventBar from './EventBar';
import Logo from './Logo';
import {MenuModal} from './Menus';
import Notifications from './Notifications';
import SearchBar from './SearchBar';
import {SignIn, User} from './User';

const NewAppBar: React.FC<unknown> = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {isAdmin, isLoggedIn} = useIdentity();
  const router = useRouter();
  const isLoggedOutLandingPage = !isLoggedIn && router.pathname === ROOT_PATH;
  const {t} = useTranslation('app-bar');

  return (
    <div
      className="flex h-20 items-center justify-between gap-1 overflow-hidden border-b bg-white px-2 text-peppercorn-800 tablet:pl-6 tablet:pr-8"
      data-testid="app-bar"
    >
      {/* --- Left side --- */}
      <div className="flex items-center justify-between gap-4">
        <AppBarIconButton
          aria-label={t('menus.label')}
          className="desktop1061:hidden"
          data-testid="mobile-menu-button"
          icon={faBars}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          onKeyDown={(event: React.KeyboardEvent) => event.key === 'Escape' && setIsMenuOpen(false)}
        />

        {isMenuOpen && <MenuModal onDismiss={() => setIsMenuOpen(false)} />}

        <Logo />

        {isLoggedOutLandingPage ? (
          <div className="hidden desktop:block">
            <LoggedOutMenu />
          </div>
        ) : (
          <div className="relative flex w-full items-center gap-3 tablet:gap-4">
            <div className="hidden desktop:block">
              <motion.div
                animate={isSearchOpen ? 'hidden' : 'visible'}
                initial={false}
                variants={{
                  hidden: {opacity: 0},
                  visible: {opacity: 1},
                }}
              >
                <EventBar />
              </motion.div>
            </div>

            <SearchBar isAdmin={isAdmin} isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} />
          </div>
        )}
      </div>

      {/* --- Right side --- */}
      <div className="flex items-center gap-2 tablet590:gap-4">
        <AppBarIconButton
          aria-label={t('search.input.label')}
          className="desktop1279:hidden"
          icon={faMagnifyingGlass}
          title={t('search.input.label')}
        />
        {isLoggedOutLandingPage && (
          <div className="mr-2 hidden desktop1279:block">
            <MenuLink
              label={t('navLinks.supportPhoneNumber')}
              href={t('navLinks.supportPhoneNumberTel')}
            />
          </div>
        )}
        <ContactUs />
        <div className="h-8 border-r" />
        <div className="flex items-center gap-2">
          {isLoggedIn ? <User /> : <SignIn />}
          {isLoggedIn && <Notifications isAdmin={isAdmin} />}
          <Cart />
          {isAdmin && <Admin />}
        </div>
      </div>
    </div>
  );
};

export default NewAppBar;
