import useTranslation from 'next-translate/useTranslation';

import {
  CATERER_COMMUNITY_RESOURCES_PATH,
  CATERER_PORTAL_PATH,
  CATERING_GROWTH_PLATFORM_PATH,
  CATERING_MANAGEMENT_SOLUTIONS_PATH,
  DELIVERY_SOLUTIONS_PATH,
  GROWTH_SOLUTIONS_PATH,
  HELP_CENTER_PATH,
} from '@/paths';
import MenuAccordion from './MenuAccordion';
import MenuLink from './MenuLink';

type RestaurantMenuProps = {
  isAccordion?: boolean;
};

const RestaurantLinks: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  return (
    <div className="flex flex-col gap-6 p-2">
      <MenuLink label={t('navLinks.signInToEzManage')} path={CATERER_PORTAL_PATH} />
      <MenuLink label={t('navLinks.cateringGrowthPlatform')} path={CATERING_GROWTH_PLATFORM_PATH} />
      <MenuLink label={t('navLinks.growthSolutions')} path={GROWTH_SOLUTIONS_PATH} />

      <MenuLink
        label={t('navLinks.cateringManagementSolutions')}
        path={CATERING_MANAGEMENT_SOLUTIONS_PATH}
      />

      <MenuLink label={t('navLinks.deliverySolutions')} path={DELIVERY_SOLUTIONS_PATH} />

      <MenuLink
        label={t('navLinks.cateringPartnerCommunity')}
        path={CATERER_COMMUNITY_RESOURCES_PATH}
      />

      <MenuLink label={t('navLinks.helpCenter')} href={HELP_CENTER_PATH} />
    </div>
  );
};

const RestaurantMenu: React.FC<RestaurantMenuProps> = ({isAccordion = false}) => {
  const {t} = useTranslation('app-bar');

  if (!isAccordion) return <RestaurantLinks />;

  return (
    <MenuAccordion ariaLabel={t('navLinks.forRestaurants')} header={t('navLinks.forRestaurants')}>
      <RestaurantLinks />
    </MenuAccordion>
  );
};

export default RestaurantMenu;
