import {useRef} from 'react';
import {Icon} from '@ezcater/tapas';
import {faBell} from '@fortawesome/free-solid-svg-icons';

import useIsMobileCheck from '@/hooks/useIsMobileCheck';
import useToggleState from '@/hooks/useToggleState';
import LargeDesktopExperience from '../LargeDesktopExperience';
import MobileAndTabletExperience from '../MobileAndTabletExperience';
import NavBarItem from '../NavBarItem';
import {DirectConnectMouseContainer, NotificationBadge} from './DirectConnectButton.styles';
import DirectConnectPopover from './DirectConnectPopover';
import {NotificationType} from './Notification.types';

export type DirectConnectButtonProps = {
  notifications: NotificationType[];
  trackLink: (path: string, title: string) => void;
};

const DirectConnectButton: React.FC<DirectConnectButtonProps> = ({
  notifications = [],
  trackLink,
}) => {
  const {toggleValue: showDesktopPopover, on: show, off: hide} = useToggleState();
  const {
    toggleValue: showMobileTabletPopover,
    off: hideMobile,
    toggle: toggleMobile,
  } = useToggleState();
  const navItemRef = useRef(null);
  const mobileButtonRef = useRef(null);
  const isMobile = useIsMobileCheck();
  const unreadNotificationCount = notifications.filter(
    notification => notification.isUnread,
  ).length;

  return (
    <>
      <LargeDesktopExperience>
        <DirectConnectMouseContainer onMouseEnter={show} onMouseLeave={hide}>
          <div className="flex items-center justify-start gap-3">
            <NavBarItem
              ref={navItemRef}
              shouldShow
              icon={faBell}
              testId="direct-connect-nav-bar-item"
              ariaLabel={`Messages: ${unreadNotificationCount} unread`}
            />
            {unreadNotificationCount > 0 && (
              <NotificationBadge>
                <p>{unreadNotificationCount}</p>
              </NotificationBadge>
            )}
          </div>
          {showDesktopPopover && (
            <DirectConnectPopover
              navItemRef={navItemRef}
              notifications={notifications}
              onCloseCallback={hide}
              trackLink={trackLink}
            />
          )}
        </DirectConnectMouseContainer>
      </LargeDesktopExperience>
      <MobileAndTabletExperience>
        <div className="flex items-center justify-start gap-3">
          <button
            type="button"
            ref={mobileButtonRef}
            data-testid="direct-connect-nav-bar-item"
            onClick={toggleMobile}
          >
            <Icon icon={faBell} className="text-[#323232]" />
          </button>
          {unreadNotificationCount > 0 && (
            <NotificationBadge>
              <p>{unreadNotificationCount}</p>
            </NotificationBadge>
          )}
        </div>
        {showMobileTabletPopover && (
          <DirectConnectPopover
            navItemRef={isMobile ? navItemRef : mobileButtonRef}
            notifications={notifications}
            onCloseCallback={hideMobile}
            isMobile={isMobile}
            trackLink={trackLink}
          />
        )}
      </MobileAndTabletExperience>
    </>
  );
};

export default DirectConnectButton;
