import {NumericFormat} from 'react-number-format';
import {Button, Icon, TextField} from '@ezcater/tapas';
import {faMinus, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {Form, Formik} from 'formik';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

const Details: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  const buttonClasses =
    'h-10 w-10 hover:bg-peppercorn-800 hover:text-white active:bg-peppercorn-800 active:text-white';

  const initialValues = {
    attendees: null,
    eventName: null,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {({setFieldValue, values}) => {
        return (
          <Form className="mb-4 flex flex-col gap-4">
            {/* --- Attendees --- */}
            <div className="flex items-center justify-between">
              <label className="font-bold" htmlFor="event-details-attendees">
                {t('eventBar.eventDetails.attendees.label')}
              </label>

              <div className="flex gap-2">
                <Button
                  className={buttonClasses}
                  disabled={!values.attendees || values.attendees === 0}
                  onClick={() => {
                    if (values.attendees && values.attendees > 0)
                      setFieldValue('attendees', values.attendees - 1);
                  }}
                  variant="outlined"
                >
                  <Icon className="shrink-0" icon={faMinus} size="medium" />
                </Button>

                <TextField
                  allowNegative={false}
                  as={NumericFormat}
                  className="h-10 w-16"
                  id="event-details-attendees"
                  isAllowed={({floatValue}: {floatValue: number | undefined}) =>
                    (floatValue || 0) < 1000000
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('attendees', parseInt(event.target.value, 10))
                  }
                  value={values.attendees}
                />

                <Button
                  className={buttonClasses}
                  onClick={() => setFieldValue('attendees', (values?.attendees || 0) + 1)}
                  variant="outlined"
                >
                  <Icon className="shrink-0" icon={faPlus} size="medium" />
                </Button>
              </div>
            </div>

            {/* --- Event name --- */}
            <div className="flex flex-col gap-2">
              <label htmlFor="event-details-name">
                <Trans
                  i18nKey="app-bar:eventBar.eventDetails.eventName.label"
                  components={{strong: <strong />}}
                />
              </label>

              <div className="flex gap-2">
                <TextField
                  id="event-details-name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('eventName', event.target.value)
                  }
                  type="text"
                />

                <Button disabled={!values.eventName} variant="outlined">
                  {t('eventBar.eventDetails.cta.update')}
                </Button>
              </div>
            </div>

            <Button>{t('eventBar.eventDetails.cta.search')}</Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Details;
