import {useEffect} from 'react';
import {useLatest} from 'react-use';
import {Button, Icon} from '@ezcater/tapas';
import {
  faArrowRightToBracket,
  faBookmark,
  faBowlHot,
  faBuildingUser,
  faCirclePlus,
  faCog,
  faCommentPlus,
  faCreditCard,
  faGift,
  faHouse,
  faLocationDot,
  faLockOpen,
  faMegaphone,
  faMoneyCheckDollar,
  faPencil,
  faReceipt,
  faRightFromBracket,
  faSquarePollVertical,
} from '@fortawesome/pro-regular-svg-icons';
import getConfig from 'next/config';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import EzCaterLogoSquare from '@/assets/icons/ezcater-logo-square.svg?react';
import {useSignOutUserMutation} from '@/graphql/types';
import useEzRewardsEligibility from '@/hooks/useEzRewardsEligibility';
import useIdentity from '@/hooks/useIdentity';
import useTracking from '@/hooks/useTracking';
import AppDownloadCTA from '@/pageComponents/app-download-cta/AppDownloadCTA';
import {
  ADDRESSES_PATH,
  ADMIN_PATH,
  EXPENSE_REPORTING_PATH,
  EZ_REWARDS_PATH,
  GROUP_POLLS_PATH,
  MY_PROFILE_PATH,
  ORDERS_PATH,
  PAYMENT_METHODS_PATH,
  RECEIPTS_PATH,
  REFER_A_FRIEND_PATH,
  REVIEWS_PATH,
  SAVED_CATERERS_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  START_NEW_ORDER_PATH,
  SUGGEST_CATERER_PATH,
} from '@/paths';
import {POINTS_GIVEN_FOR_REFER_A_FRIEND, POINTS_GIVEN_FOR_REVIEW} from '../constants';
import {formatPoints} from '../utils';
import MenuLink from './MenuLink';

type UserMenuProps = {
  isMobileMenu?: boolean;
};

const UserMenu: React.FC<UserMenuProps> = ({isMobileMenu = false}) => {
  const {data: identityData, isAdmin, isLoggedIn} = useIdentity();
  const {rewardsEnabled, balanceEnabled, earningsEnabled} = useEzRewardsEligibility();
  const [signOut, {client, loading: isSigningOut}] = useSignOutUserMutation();
  const {track, trackClick} = useTracking();
  const {t} = useTranslation('app-bar');
  const {ezRewardsAccount, canAccessConcur, canAccessCorpPortal, fullName} =
    identityData?.me?.consumerAccount || {};

  const {
    publicRuntimeConfig: {corpPortalUrl: corpPortalHost},
  } = getConfig();

  // Track when the user opens the menu
  const trackRef = useLatest(track);
  useEffect(() => {
    trackRef.current?.('appbar-profile-menu-opened', {
      misc_json: JSON.stringify({area: isMobileMenu ? 'mobile' : 'desktop'}),
      url: window.location.href,
    });
  }, [isMobileMenu, trackRef]);

  return (
    <div className={twJoin('flex w-full flex-col gap-4 divide-y', isMobileMenu && 'px-8')}>
      {isLoggedIn ? (
        <>
          <div className="flex flex-col items-start gap-4">
            <MenuLink icon={faHouse} label={t('navLinks.home')} path={START_NEW_ORDER_PATH} />
            <MenuLink icon={faBowlHot} label={t('navLinks.orders')} path={ORDERS_PATH} />

            <MenuLink
              icon={faBookmark}
              label={t('navLinks.savedRestaurants')}
              path={SAVED_CATERERS_PATH}
            />

            <MenuLink icon={faReceipt} label={t('navLinks.receipts')} path={RECEIPTS_PATH} />

            <MenuLink
              icon={faSquarePollVertical}
              label={t('navLinks.groupPolls')}
              path={GROUP_POLLS_PATH}
            />
          </div>

          {rewardsEnabled && isLoggedIn && (
            <div className="flex flex-col items-start gap-4 pt-3">
              <MenuLink
                chip={
                  balanceEnabled
                    ? t('user.points', {balance: formatPoints(ezRewardsAccount?.balance || 0)})
                    : undefined
                }
                icon={faGift}
                label={t('navLinks.ezRewards')}
                path={EZ_REWARDS_PATH}
              />

              <MenuLink
                chip={earningsEnabled && t('user.plusPoints', {balance: POINTS_GIVEN_FOR_REVIEW})}
                chipGreen
                icon={faPencil}
                label={t('navLinks.reviews')}
                path={REVIEWS_PATH}
              />

              <MenuLink
                chip={
                  earningsEnabled &&
                  t('user.plusPoints', {balance: POINTS_GIVEN_FOR_REFER_A_FRIEND})
                }
                chipGreen
                icon={faMegaphone}
                label={t('navLinks.referAFriend')}
                path={REFER_A_FRIEND_PATH}
              />
            </div>
          )}

          <div className="flex flex-col items-start gap-4 pt-3">
            <MenuLink
              icon={faCommentPlus}
              label={t('navLinks.suggestARestaurant')}
              path={SUGGEST_CATERER_PATH}
            />

            <MenuLink icon={faCog} label={t('navLinks.accountSettings')} path={MY_PROFILE_PATH} />
            <MenuLink
              icon={faLocationDot}
              label={t('navLinks.deliveryAddresses')}
              path={ADDRESSES_PATH}
            />
            <MenuLink
              icon={faCreditCard}
              label={t('navLinks.paymentMethods')}
              path={PAYMENT_METHODS_PATH}
            />

            {canAccessConcur && (
              <MenuLink
                icon={faMoneyCheckDollar}
                label={t('navLinks.expenseReporting')}
                path={EXPENSE_REPORTING_PATH}
              />
            )}
          </div>

          <div className="flex flex-col items-start gap-4 pt-3">
            {isMobileMenu && isAdmin && (
              <MenuLink icon={faLockOpen} label={t('navLinks.admin')} path={ADMIN_PATH} />
            )}

            {isMobileMenu && canAccessCorpPortal && Boolean(corpPortalHost) && (
              <MenuLink
                icon={faBuildingUser}
                label={t('navLinks.corporateAdmin')}
                href={corpPortalHost}
              />
            )}

            {isMobileMenu && (
              <MenuLink
                href="https://ezcater.onelink.me/T4QA/7sd7rtk1"
                icon={<EzCaterLogoSquare className="ml-[3px]" />}
                label={t('navLinks.downloadApp')}
                onClick={() => trackClick('app-download-cta-nav-mobile')}
                rel="noopener noreferrer"
                target="_blank"
              />
            )}

            {isLoggedIn && (
              <Button
                className="p-1 font-normal no-underline hover:bg-transparent hover:underline active:bg-transparent"
                disabled={isSigningOut}
                onClick={async () => {
                  const {data} = await signOut();
                  if (data?.signOutUser?.success) {
                    // .clearStore will clear the cache but it will not re-fetch the queries like .resetStore()
                    // This is to prevent the re-fetch queries from hitting the ErrorBoundary which
                    // can result in flashes of "oh crumbs" before redirecting the users
                    await client.clearStore();

                    // Prevent users from using the back button to navigate
                    window.location.replace('/');
                  }
                }}
                title={t('menus.navigatesTo', {label: t('navLinks.signOut')})}
                variant="secondary"
              >
                <div className="flex items-center gap-4">
                  <Icon className="text-md flex-shrink-0" icon={faRightFromBracket} />
                  <div>{t('navLinks.signOutFullName', {fullName})}</div>
                </div>
              </Button>
            )}

            {!isMobileMenu && <AppDownloadCTA isRenderedInAppBar />}
          </div>
        </>
      ) : (
        <div className="flex flex-col items-start gap-4">
          <MenuLink icon={faArrowRightToBracket} label={t('navLinks.signIn')} path={SIGN_IN_PATH} />
          <MenuLink icon={faCirclePlus} label={t('navLinks.createAnAccount')} path={SIGN_UP_PATH} />
        </div>
      )}
    </div>
  );
};

export default UserMenu;
