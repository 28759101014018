import {forwardRef} from 'react';
import styled from '@emotion/styled';
// eslint-disable-next-line no-restricted-imports
import {EzIcon} from '@ezcater/recipe';
import {EzIconTypes} from '@ezcater/recipe/dist/types/components/EzIcon/EzIcon.types';

import appFonts from '@/components/RootProvider/appFonts';

const StyledNavBarItem = styled.div`
  margin-right: 10px;
`;

export const StyledEzIcon = styled(EzIcon)`
  font-size: ${appFonts.textBase};
`;

type NavBarItemProps = {
  shouldShow: boolean;
  icon: EzIconTypes;
  testId: string;
  ariaLabel?: string;
};

const NavBarItem = forwardRef<HTMLDivElement, NavBarItemProps>(
  ({shouldShow, icon, testId, ariaLabel}, ref) => {
    if (!shouldShow) {
      return null;
    }

    return (
      <StyledNavBarItem tabIndex={0} ref={ref} data-testid={testId}>
        <StyledEzIcon icon={icon} title={ariaLabel} />
      </StyledNavBarItem>
    );
  },
);
export default NavBarItem;
