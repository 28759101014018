import styled from '@emotion/styled';
// eslint-disable-next-line no-restricted-imports
import {EzIcon, EzLink} from '@ezcater/recipe';
import {faCartXmark} from '@fortawesome/pro-light-svg-icons';

import recipeMq from '@/styles/recipeMq';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  ${recipeMq('medium')} {
    margin-top: 0;
    padding: 21px;
  }
`;

export type EmptyStateProps = {
  startNewOrderPath: string;
  loginPath: string;
};

const LoggedInMessage: React.FC<{startNewOrderPath: string}> = ({startNewOrderPath}) => (
  <>
    <p>You don&apos;t have any draft orders to display.</p>
    <EzLink>
      <a
        href={startNewOrderPath}
        onClick={() => {
          window.location.href = startNewOrderPath;
        }}
      >
        Home
      </a>
    </EzLink>
  </>
);

const LoggedOutMessage: React.FC<{loginPath: string}> = ({loginPath}) => (
  <>
    <p>You don&apos;t currently have any draft orders.</p>
    <p>
      If you&apos;re looking for an order that you
      <br />
      don&apos;t see,{' '}
      <EzLink>
        <a
          href={loginPath}
          onClick={() => {
            window.location.href = loginPath;
          }}
        >
          click here to log in
        </a>
      </EzLink>
      .
    </p>
  </>
);
const EmptyState: React.FC<EmptyStateProps & {isLoggedIn: boolean}> = ({
  startNewOrderPath,
  loginPath,
  isLoggedIn,
}) => {
  const element = isLoggedIn ? (
    <LoggedInMessage startNewOrderPath={startNewOrderPath} />
  ) : (
    <LoggedOutMessage loginPath={loginPath} />
  );

  return (
    <Container>
      <EzIcon title="Empty shopping cart" size="large" icon={faCartXmark} />
      {element}
    </Container>
  );
};

export default EmptyState;
