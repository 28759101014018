import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import useToggleState from '@/hooks/useToggleState';
import {NavDropdownMenuItem, NavDropdownNavLink} from '../ProfileAppBarItem/MyAccountNav.styles';
import {MenuItemType} from './MenuItem.types';
import {
  MobileLandingNavDropdownMenu,
  NavDropdownClickableItem,
  StyledEzIcon,
} from './MobileLandingDropdown.styles';

export type MobileLandingDropdownProps = {
  navItemText: string;
  menuItems: MenuItemType[];
  trackLink: (path: string, title: string) => void;
  testId?: string;
};

const MobileLandingDropdown: React.FC<MobileLandingDropdownProps> = ({
  navItemText,
  menuItems,
  trackLink,
  testId = '',
}) => {
  const {toggleValue: showDropdown, toggle: toggleDropdown} = useToggleState();

  return (
    <>
      <NavDropdownMenuItem>
        <div>
          <NavDropdownClickableItem data-testid={testId} onClick={toggleDropdown}>
            {navItemText}
            {!showDropdown && (
              <StyledEzIcon data-testid={'mobile-chevron-down'} icon={faChevronDown} />
            )}
            {showDropdown && <StyledEzIcon data-testid={'mobile-chevron-up'} icon={faChevronUp} />}
          </NavDropdownClickableItem>
        </div>
      </NavDropdownMenuItem>
      {showDropdown && (
        <MobileLandingNavDropdownMenu>
          {menuItems.map(item => (
            <NavDropdownMenuItem data-testid="landing-dropdown-menu-item" key={item.text}>
              {item.link && (
                <NavDropdownNavLink
                  data-testid="landing-dropdown-menu-item-link"
                  href={item.link}
                  title={`Navigates to ${item.text} page`}
                  onClick={() => trackLink(item.link || '', item.text)}
                >
                  {item.text}
                </NavDropdownNavLink>
              )}
              {item.onClick && (
                <NavDropdownClickableItem
                  data-testid="landing-dropdown-menu-item-link"
                  title={`Opens ${item.text}`}
                  onClick={() => {
                    trackLink(item.link || '', item.text);
                    item.onClick?.();
                  }}
                >
                  {item.text}
                </NavDropdownClickableItem>
              )}
            </NavDropdownMenuItem>
          ))}
        </MobileLandingNavDropdownMenu>
      )}
    </>
  );
};

export default MobileLandingDropdown;
