import styled from '@emotion/styled';
// eslint-disable-next-line no-restricted-imports
import {EzIcon} from '@ezcater/recipe';

import recipeMq from '@/styles/recipeMq';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
`;

export const AppBarLink = styled('a', {shouldForwardProp: prop => prop !== 'justify'})<{
  justify?: 'between';
}>`
  appearance: none;
  border: 0;
  background-color: inherit;
  padding: 0.5rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.justify === 'between' ? 'space-between' : 'flex-start')};
  border-radius: 0.25rem;
  text-decoration: none;
  text-underline-offset: 4px;
  width: 100%;
  cursor: pointer;
  color: #000;
  font-size: 0.875rem;
  line-height: 1.25rem;

  &:focus,
  &:hover {
    text-decoration: underline;
    color: #000;
  }
`;

export const PillNavBarLink = styled(AppBarLink)`
  &:focus,
  &:hover {
    text-decoration: none;
    color: #000;

    .link-container {
      text-decoration: underline;
    }
  }
`;

export const SignOutButton = AppBarLink.withComponent('button');

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Divider = styled.hr`
  width: 100%;
  border-width: 0;
  border-top-width: 1px;
  border-style: solid;
  border-color: #d6d9e1;
  height: 0;
  color: inherit;
`;

export const PeppercornPill = styled.div`
  color: #666974;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
  background-color: #f0f2f7;
  border-radius: 9999px;
`;

export const EzGreenPill = styled(PeppercornPill)`
  color: #008054;
  background-color: #d3faeb;
`;

export const SemiSmallEzIcon = styled(EzIcon)`
  font-size: 1.125rem !important;
  flex-shrink: 0;
`;
export const NavDropdownMenu = styled.div`
  padding: 0;
  margin: 10px;
  background-color: #ffffff;
  border: 1px solid #cccfd5;
  box-shadow: 0 2px 20px 0 rgba(153, 153, 153, 0.35);
  position: absolute;
  top: 100%;
  right: -35px;
  width: 320px;
  z-index: 10000 !important;
  list-style: none;

  &::before {
    cursor: pointer;
    z-index: -1;
    top: -9px;
    bottom: 100%;
    right: 16px;
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    transform: translateX(0px) rotate(45deg);
    transform-origin: center;
    border-left: 1px solid #cccfd5;
    border-top: 1px solid #cccfd5;
    background-color: #ffffff;
  }
`;

export const NavDropdownMenuItem = styled.li`
  display: block;
  border-bottom: 1px solid #cccfd5;

  ${recipeMq('large')} {
    border-bottom: 0;
  }
`;

export const NavDropdownNavLink = styled.a`
  display: flex;
  align-items: center;
  color: #565a5c;
  text-decoration: none;
  cursor: pointer;
  padding-left: 10px;
  height: 50px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #32353e;
  }
`;
