import {Icon} from '@ezcater/tapas';
import {faAddressCard, faLocationDot} from '@fortawesome/pro-solid-svg-icons';
import parse from 'autosuggest-highlight/parse';
import {twMerge} from 'tailwind-merge';

import {type AddressSearchAutocompleteOptionProps} from './types';

const AddressSearchAutocompleteOption: React.FC<AddressSearchAutocompleteOptionProps> = ({
  option,
  ...props
}) => {
  const isSavedAddress = option.group === 'Saved addresses';
  const matches = option.structured_formatting?.main_text_matched_substrings || [];
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match: any) => [match.offset, match.offset + match.length]),
  );

  return (
    <div {...props} className={twMerge('w-full text-sm', props.className)}>
      <div className="flex max-w-full items-center gap-2">
        <Icon
          className="shrink-0 fill-peppercorn-300"
          icon={isSavedAddress ? faAddressCard : faLocationDot}
        />

        <div className="flex items-end gap-1 overflow-hidden whitespace-nowrap text-peppercorn-800">
          {isSavedAddress ? (
            <div className="flex items-center gap-1 overflow-hidden whitespace-nowrap">
              <span className="font-extrabold">{option.structured_formatting.main_text}</span>{' '}
              <span className="text-lg text-peppercorn-200">|</span>
              <span className="truncate text-peppercorn-500">
                {option.structured_formatting.secondary_text}
              </span>
            </div>
          ) : (
            <div className="flex items-center gap-1 overflow-hidden whitespace-nowrap py-1">
              <span>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    className={`${part.highlight ? 'font-extrabold' : 'font-medium'}`}
                  >
                    {part.text}
                  </span>
                ))}
              </span>

              <div className="truncate text-peppercorn-500">
                {option.structured_formatting.secondary_text}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressSearchAutocompleteOption;
