import styled from '@emotion/styled';

export const CloseButton = styled.button`
  appearance: none;
  border: 0;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  padding: 0.5rem;
  color: #212223;
  cursor: pointer;

  &:hover {
    background-color: #f0f2f7;
  }

  &:active {
    background-color: #d6d9e1;
  }
`;

export const ModalContent = styled.div`
  padding: 1.5rem 1rem;
`;

export const WelcomeMessage = styled.h3`
  margin: 0 0 0.75rem 0;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: #000;
`;

export const NeedHelpContent = styled.div`
  margin-top: 0.75rem;
  color: #000;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  gap: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;

  a {
    color: #000;
    text-decoration: none;
    text-underline-offset: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
`;
