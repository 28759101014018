import {FC} from 'react';
import {Link} from '@ezcater/tapas';
import {twJoin} from 'tailwind-merge';

import {useConsumerCart} from '@/pageComponents/catering-menu/hooks';
import {CATERING_MENU_PATH} from '@/paths';
import {compilePath} from '@/utils';
import {Order} from './Cart';

type DraftOrderProps = {
  order: Order;
};

const eventAtFormat: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const DraftOrder: FC<DraftOrderProps> = ({order}) => {
  const compileOrderPath = compilePath(
    CATERING_MENU_PATH,
    {catererUrlId: order.caterer.urlId},
    {cartId: order.id},
  );
  const consumerCart = useConsumerCart();

  const isCurrentOrder = consumerCart?.id === order.id;

  const draftOrderContent = (
    <>
      {order.eventAt && (
        <span className="sr-only">
          {`Event at ${new Date(order.eventAt).toLocaleString('en-US', eventAtFormat)}`}
        </span>
      )}
      <div className="text-base font-medium text-gray-900 group-hover:text-white">
        {order.caterer.name} {isCurrentOrder && '(current)'}
      </div>

      {order.caterer.address && (
        <div className="mb-1 text-sm text-gray-500 group-hover:text-white">
          {`${order.caterer.address.city}, ${order.caterer.address.state}`}
        </div>
      )}

      {order.accountName && (
        <div className="text-sm text-gray-500 group-hover:text-white">{order.accountName}</div>
      )}
    </>
  );

  return (
    <div className="w-full border-b pb-2 last:border-none">
      {order.eventAt && (
        <div className="flex items-center py-1">
          <div className={twJoin('mr-2 w-1 bg-green-500', order.eventName ? 'h-8' : 'h-4')}></div>
          <div>
            <div className="text-left text-sm font-semibold text-gray-900">
              {new Date(order.eventAt).toLocaleString('en-US', eventAtFormat)}
            </div>
            {order.eventName && (
              <div className="text-left text-sm italic text-gray-500">{order.eventName}</div>
            )}
          </div>
        </div>
      )}
      {isCurrentOrder ? (
        <div className="flex w-full cursor-default flex-col items-start justify-start rounded-md bg-gray-200 p-2 text-left">
          {draftOrderContent}
        </div>
      ) : (
        <Link
          href={compileOrderPath}
          className="group flex w-full flex-col items-start justify-start rounded-md px-2 text-left hover:bg-ezgreen-400 hover:no-underline"
        >
          {draftOrderContent}
        </Link>
      )}
    </div>
  );
};

export default DraftOrder;
