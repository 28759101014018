import FocusScope from '@/components/FocusScope';
import Portal from '@/components/Portal';
import useDialog from '@/hooks/useDialog';
import {Dialog, Overlay} from './Modal.styles';
import ScrollLock from './ScrollLock';

type Props = {
  id?: string;
  isOpen: boolean;
  onDismiss?: () => void;
  className?: string;
};

const noop = () => {};

const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  isOpen,
  onDismiss = noop,
  children,
  className,
}) => {
  const dialog = useDialog({onDismiss});

  if (!isOpen) return null;

  return (
    <Portal>
      <Overlay onClick={e => e.target === e.currentTarget && onDismiss()}>
        <ScrollLock />
        <FocusScope contain restoreFocus autoFocus>
          <Dialog {...dialog} aria-labelledby={id} className={className}>
            {children}
          </Dialog>
        </FocusScope>
      </Overlay>
    </Portal>
  );
};

export default Modal;
