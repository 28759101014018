import {Link} from '@ezcater/tapas';
import useTranslation from 'next-translate/useTranslation';

import {SIGN_IN_PATH, SIGN_UP_PATH} from '@/paths';
import {compilePath} from '@/utils';

const SignIn: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  return (
    <div className="hidden items-center gap-2 mobile375:flex">
      <Link
        className="hidden whitespace-nowrap px-2 text-peppercorn-800 no-underline hover:text-peppercorn-800 active:text-peppercorn-800 tablet590:block"
        href={compilePath(SIGN_IN_PATH)}
      >
        {t('navLinks.signIn')}
      </Link>

      <Link
        className="mx-1 whitespace-nowrap rounded-lg bg-ezgreen-400 px-4 py-2 text-white no-underline hover:bg-ezgreen-500 hover:text-white hover:no-underline active:bg-ezgreen-600 active:text-white active:no-underline"
        href={compilePath(SIGN_UP_PATH)}
      >
        {t('navLinks.signUp')}
      </Link>
    </div>
  );
};

export default SignIn;
