import {Icon} from '@ezcater/tapas';
import {faSpinnerThird} from '@fortawesome/pro-solid-svg-icons';
import useTranslation from 'next-translate/useTranslation';

type LoadingSpinnerProps = {
  hideText?: boolean;
  className?: string;
};
const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({hideText = true, className}) => {
  const {t} = useTranslation('common');

  return (
    <div>
      <div aria-hidden className="m-auto h-8 w-8 align-middle">
        <Icon size="large" icon={faSpinnerThird} className="animate-spin" />
      </div>
      {!hideText && <div className={className}>{t('components.Loading')}</div>}
    </div>
  );
};

export default LoadingSpinner;
