import {forwardRef} from 'react';
import {Button, Icon} from '@ezcater/tapas';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import {twMerge} from 'tailwind-merge';

type AppBarIconButtonProps = React.ComponentProps<typeof Button> & {
  icon: IconDefinition;
  hasRedDot?: boolean;
};

type Ref = HTMLButtonElement;

const AppBarIconButton = forwardRef<Ref, AppBarIconButtonProps>(
  ({icon, hasRedDot, ...props}, ref) => (
    <Button
      ref={ref}
      variant="secondary"
      {...props}
      className={twMerge('p-2 font-normal no-underline', props?.className)}
    >
      <Icon icon={icon} size="medium" />
      {hasRedDot && (
        <span
          className="absolute -right-2 -top-2 block h-4 w-4 rounded-full border-2 border-white bg-red-100"
          data-testid="red-dot"
        />
      )}
    </Button>
  ),
);

export default AppBarIconButton;
