import {Link} from '@ezcater/tapas';
import Image from 'next/image';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import ezcaterLogo from '@/assets/icons/ezcater-logo.svg';
import StickyModal from '@/components/StickyModal';
import useIdentity from '@/hooks/useIdentity';
import {ROOT_PATH} from '@/paths';
import AboutUsMenu from './AboutUsMenu';
import CompanyMenu from './CompanyMenu';
import ContactUsMenu from './ContactUsMenu';
import RestaurantMenu from './RestaurantMenu';
import UserMenu from './UserMenu';

type MenuModalProps = {
  onDismiss: () => void;
};

const MenuModal: React.FC<MenuModalProps> = ({onDismiss}) => {
  const {data: identityData, isLoggedIn} = useIdentity();
  const router = useRouter();
  const {t} = useTranslation('app-bar');
  const isLoggedOutHomepage = router.pathname === ROOT_PATH;
  const firstName = identityData?.me?.consumerAccount?.firstName;

  return (
    <StickyModal
      header={<Image alt="ezCater" className="ml-1 h-10" height={30} src={ezcaterLogo} />}
      onDismiss={onDismiss}
      open
      slotProps={{
        body: {className: 'p-0 tablet:p-4'},
        content: {
          className: 'p-0 tablet:h-auto tablet:min-h-[480px] tablet:max-w-[480px]',
        },
      }}
    >
      <div className="flex flex-col gap-6 py-6">
        <div className="px-8 font-bold leading-5 text-peppercorn-500">
          {firstName ? t('menus.welcomeFirstName', {firstName}) : t('menus.welcomeToEzCater')}
        </div>

        {!isLoggedIn && isLoggedOutHomepage && (
          <div className="flex flex-col px-4">
            <CompanyMenu isAccordion />
            <RestaurantMenu isAccordion />
            <AboutUsMenu isAccordion />
            <ContactUsMenu />
          </div>
        )}

        <UserMenu isMobileMenu />

        <div className="mx-4 mt-4 flex flex-col items-start gap-1 rounded-xl bg-peppercorn-100 p-4">
          <div className="font-bold leading-5 text-peppercorn-500">{t('menus.needHelp')}</div>

          <Link
            className="-mb-1 text-peppercorn-800 hover:text-peppercorn-800 active:text-peppercorn-800"
            href={t('navLinks.supportPhoneNumberTel')}
          >
            {t('navLinks.supportPhoneNumber')}
          </Link>

          <Link
            className="text-peppercorn-800 hover:text-peppercorn-800 active:text-peppercorn-800"
            href={t('navLinks.supportEmailMailTo')}
          >
            {t('navLinks.supportEmail')}
          </Link>
        </div>
      </div>
    </StickyModal>
  );
};

export default MenuModal;
