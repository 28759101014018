import {useMemo} from 'react';

import FeatureFlags from '@/FeatureFlags';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIdentity from '@/hooks/useIdentity';

const useOrderAccounts = () => {
  const {data} = useIdentity();
  const isInParentCorpAccount = data?.me?.consumerAccount?.corporateAccount?.isParent || false;
  const canAccessCorpPortal = data?.me?.consumerAccount?.canAccessCorpPortal || false;
  const nonAdminSelectOrderAccountEnabled = useFeatureFlag(FeatureFlags.NonAdminSelectOrderAccount);
  const shouldRenderSelectOrderAccount =
    useFeatureFlag(FeatureFlags.ShouldRenderSelectOrderAccount) &&
    (nonAdminSelectOrderAccountEnabled || canAccessCorpPortal) &&
    isInParentCorpAccount;

  const availableCorpAccountOptions = useMemo(() => {
    const availableCorpAccounts = data?.me?.consumerAccount?.ownedCorporateAccounts ?? [];
    return [...availableCorpAccounts]
      .sort((a, b) => {
        if (a.isParent && !b.isParent) {
          return -1;
        }

        if (!a.isParent && b.isParent) {
          return 1;
        }
        const aName = a.nickname || a.name;
        const bName = b.nickname || b.name;

        return aName.localeCompare(bName);
      })
      .map(account => ({
        label: account.nickname || account.name,
        value: account.id,
      }));
  }, [data]);

  return {
    availableCorpAccountOptions,
    shouldRenderSelectOrderAccount,
  };
};

export default useOrderAccounts;
