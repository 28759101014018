import {useMemo, useState} from 'react';
import {Button} from '@ezcater/tapas';
import {addMinutes, format, startOfHour} from 'date-fns';
import {capitalize} from 'lodash-es';
import useTranslation from 'next-translate/useTranslation';

import chunk from '@/utils/chunk';
import clamp from '@/utils/clamp';
import TimeCell from './TimeCell';

const START_HOUR = 5;
const ADMIN_START_HOUR = 0;

const MAX_TIME_INCREMENTS = 72;
const ADMIN_MAX_TIME_INCREMENTS = 96;

type GetAvailableTimeProps = {
  maxIncrements: number;
  startHour: number;
};

const getAvailableTimes = ({startHour, maxIncrements}: GetAvailableTimeProps): Date[] => {
  const beginning = startOfHour(new Date().setHours(startHour));

  const cycle = [];
  for (let i = 0; i < maxIncrements; i++) {
    cycle.push(addMinutes(beginning, 15 * i));
  }

  return cycle;
};

const timeRowKey = (row: Date[]) => {
  const firstTime = row[0];
  const lastTime = row[row.length - 1];

  return `${format(firstTime, 'Hmm')}-${format(lastTime, 'Hmm')}`;
};

type TimePickerProps = {
  isAdmin: boolean;
  onChange: (newTime: number) => void;
  strategy?: string;
  time: number | null;
};

const TimePicker: React.FC<TimePickerProps> = ({isAdmin, onChange, strategy, time}) => {
  const {t} = useTranslation('app-bar');

  // For admins: generates an array of Date objects, ranging from 12:00 AM to 11:45 PM in 15 minute intervals
  // For non-admins: generates an array of Date objects, ranging from 5:00 AM to 10:45 PM in 15 minute intervals
  const availableTimes = useMemo(
    () =>
      getAvailableTimes({
        maxIncrements: isAdmin ? ADMIN_MAX_TIME_INCREMENTS : MAX_TIME_INCREMENTS,
        startHour: isAdmin ? ADMIN_START_HOUR : START_HOUR,
      }),
    [isAdmin],
  );

  // chunk that array into groups of 4 (this will be our grid)
  const timeGroups = useMemo(() => chunk(availableTimes, 4), [availableTimes]);

  // the offset controls which time groups are displayed in the UI
  const [offset, setOffset] = useState(() => {
    // if no time is selected, Noon should be displayed in the middle
    const desiredTime = time ? `${time}` : '1200';
    return timeGroups.findIndex(group =>
      group.find((groupTime: Date) => format(groupTime, 'Hmm') === desiredTime),
    );
  });

  // two rows above and two rows below the current offset is the window
  const startOffset = clamp(offset - 2, 0, timeGroups.length);
  const endOffset = clamp(offset + 3, 0, timeGroups.length);
  const timeGroupWindow = useMemo(
    () => timeGroups.slice(startOffset, endOffset),
    [timeGroups, startOffset, endOffset],
  );

  const isDisplayingEarliestTime = availableTimes[0] === timeGroupWindow[0][0];
  const isDisplayingLatestTime =
    availableTimes[availableTimes.length - 1] === timeGroupWindow[timeGroupWindow.length - 1][3];

  const displayEarlierTimes = () => setOffset(clamp(offset - 2, 0, timeGroups.length));
  const displayLaterTimes = () => setOffset(clamp(offset + 2, 0, timeGroups.length - 2));

  return (
    <>
      <label className="text-xl font-bold" htmlFor="app-bar-date-picker">
        {t('eventBar.eventDate.inputs.time', {strategy: capitalize(strategy)})}
      </label>
      <div className="-mt-2">{strategy === 'DELIVERY' && t('eventBar.eventDate.helperText')}</div>
      {!isDisplayingEarliestTime && (
        <Button
          className="border-peppercorn-200 font-normal"
          onClick={displayEarlierTimes}
          variant="outlined"
        >
          {t('eventBar.eventDate.inputs.earlier')}
        </Button>
      )}

      {timeGroupWindow.map(times => (
        <div className="-mb-px flex last-of-type:mb-0" key={timeRowKey(times)}>
          {times.map((cellTime: Date) => {
            const cellTimeFormatted = parseInt(format(cellTime, 'Hmm'), 10);

            return (
              <TimeCell
                key={cellTimeFormatted}
                time={cellTime}
                onChange={onChange}
                isSelected={cellTimeFormatted === time}
              />
            );
          })}
        </div>
      ))}

      {!isDisplayingLatestTime && (
        <Button
          className="border-peppercorn-200 font-normal"
          onClick={displayLaterTimes}
          variant="outlined"
        >
          {t('eventBar.eventDate.inputs.later')}
        </Button>
      )}
    </>
  );
};

export default TimePicker;
