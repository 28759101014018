import styled from '@emotion/styled';

import recipeMq from '@/styles/recipeMq';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;
  justify-content: center;

  ${recipeMq('medium')} {
    margin-top: 0;
    padding: 21px;
  }
`;

const SpinnerSVG = styled.svg`
  height: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;
  width: 20px;
`;

const Message = styled.div`
  color: #777;
`;

function LoadingState() {
  return (
    <Container>
      <div>
        <SpinnerSVG
          version="1.1"
          id="L9"
          xmlns="http://www.w3.org/2000/svg” xmlns:xlink=“http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="25 25 50 50"
          enableBackground="new 0 0 0 0"
          xmlSpace="preserve"
        >
          <path
            fill="#ccc"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </SpinnerSVG>
      </div>
      <Message>Loading</Message>
    </Container>
  );
}

export default LoadingState;
