import {useEffect, useRef, useState} from 'react';
import {CookiesProvider} from 'react-cookie';
import type {SerializedStyles} from '@emotion/react';
import {ClassNames} from '@emotion/react';

import MobileExperience from '@/components/MobileExperience';
import TabletAndDesktopExperience from '@/components/TabletAndDesktopExperience';
import CartButton from '../../components/CartButton/CartButton';
import {type Order as OrderType} from '../../types';
import {ConsumerCartMouseContainer} from './components/ConsumerCart.styles';
import type {EmptyStateProps} from './components/EmptyState';
import EmptyState from './components/EmptyState';
import LoadingState from './components/LoadingState';
import Modal from './components/Modal';
import type {OrderProps} from './components/Order';
import Order from './components/Order';
import Popover from './components/Popover';

export type ConsumerCartOrderPathParams = {
  orderId: string;
  catererUrlId: string;
};

export type ConsumerCartProps = EmptyStateProps & {
  currentOrderId?: string;
  currentCatererId?: string;
  compileOrderPath: (args: {orderId: string; catererUrlId: string}) => string;
  onOpen: () => void;
  onClickOrder: OrderProps['onClick'];
  className?: string; // implements styled(ConsumerCart)
  popoverClassNames?: SerializedStyles;
  menuPageClickHandler?: () => void;
  cartHasItems?: boolean;
  isLoggedIn?: boolean;
  orders?: OrderType[];
  loading?: boolean;
  trackLink: (path: string, title: string) => void;
  accountHierarchyPresent?: boolean;
};

// None of the clicks on this element should escape, since it may appear inside
// an element with its own click handler
const catchAllClicks = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
  e.preventDefault();
};

const ConsumerCart: React.FC<ConsumerCartProps> = ({
  compileOrderPath,
  startNewOrderPath,
  onOpen,
  onClickOrder,
  currentOrderId,
  currentCatererId,
  className: containerClassNames,
  popoverClassNames,
  loginPath,
  menuPageClickHandler,
  cartHasItems,
  isLoggedIn = false,
  orders = [],
  loading = false,
  trackLink,
  accountHierarchyPresent = false,
}) => {
  const onOpenRef = useRef(onOpen);
  onOpenRef.current = onOpen;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const ref = useRef(null);
  const hasCarts = orders.length > 0;
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (isModalOpen || isPopoverVisible) {
      onOpenRef?.current?.();
    }
  }, [isModalOpen, isPopoverVisible]);

  const orderComponents = orders.map(order => (
    <Order
      currentOrderId={currentOrderId}
      currentCatererId={currentCatererId}
      key={order.id}
      order={order}
      path={compileOrderPath({
        orderId: order.id,
        catererUrlId: order.caterer.urlId,
      })}
      trackLink={trackLink}
      onClick={onClickOrder}
      accountHierarchyPresent={accountHierarchyPresent}
    />
  ));

  let content: React.ReactNode;

  if (loading && !hasCarts) {
    content = <LoadingState />;
  } else if (hasCarts) {
    content = orderComponents;
  } else {
    content = (
      <EmptyState
        startNewOrderPath={startNewOrderPath}
        loginPath={loginPath}
        isLoggedIn={isLoggedIn}
      />
    );
  }

  return (
    <CookiesProvider>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div className={containerClassNames} data-testid="cart-container" onClick={catchAllClicks}>
        <TabletAndDesktopExperience>
          <ConsumerCartMouseContainer
            onMouseEnter={() => setIsPopoverVisible(true)}
            onMouseLeave={() => setIsPopoverVisible(false)}
          >
            <CartButton buttonRef={ref} hasCarts={hasCarts} />
            {isPopoverVisible && (
              <ClassNames>
                {({css: cssHelper}) => (
                  <Popover
                    className={cssHelper(popoverClassNames)}
                    close={() => setIsPopoverVisible(false)}
                    buttonRef={ref}
                  >
                    {content}
                  </Popover>
                )}
              </ClassNames>
            )}
          </ConsumerCartMouseContainer>
        </TabletAndDesktopExperience>
        <MobileExperience>
          <CartButton
            hasCarts={hasCarts}
            onClick={openModal}
            menuPageClickHandler={menuPageClickHandler}
            cartHasItems={cartHasItems}
          />
          <Modal isOpen={isModalOpen} close={closeModal}>
            {content}
          </Modal>
        </MobileExperience>
      </div>
    </CookiesProvider>
  );
};

export default ConsumerCart;
