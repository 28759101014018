import {useRef} from 'react';
import {Button, Icon, tapasClassNames, TextField} from '@ezcater/tapas';
import {faArrowTrendUp, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {ClickAwayListener} from '@mui/base';
import {m as motion} from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import useMediaQuery from '@/hooks/useMediaQuery';
import SearchLink from './SearchLink';

type SearchBarProps = {
  isAdmin?: boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({isAdmin = false, isOpen, setIsOpen}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const useShortPlaceholder = useMediaQuery('(max-width: 1439px)') || isAdmin;
  const {t} = useTranslation('app-bar');

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsOpen(false);
    searchRef.current?.blur();
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <motion.div
        className={twJoin(
          'hidden w-auto min-w-[145px] max-w-[800px] bg-white desktop1279:block',
          tapasClassNames.input.border,
          !isOpen && tapasClassNames.focusWithin,
          !isAdmin && 'desktop1440:min-w-[330px]',
          isOpen
            ? 'fixed left-44 top-[18px] z-1300 w-full rounded-xl p-4 pt-2 shadow-outer'
            : '!rounded-full',
        )}
        initial={false}
        key="SearchBar"
        layout
        layoutDependency={isOpen}
        onClick={() => setIsOpen(true)}
        ref={searchRef}
        transition={{layout: {duration: isOpen ? 0.15 : 0}}}
      >
        <TextField
          autoFocus={isOpen}
          className="w-full rounded-full border-0 focus-within:ring-0 [&>input]:truncate"
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
            event.key === 'Enter' && setIsOpen(!isOpen)
          }
          placeholder={t(`search.input.placeholder.${useShortPlaceholder ? 'short' : 'long'}`)}
          prefix={
            <Icon
              className={isOpen ? 'text-ezgreen-300' : 'text-peppercorn-800'}
              icon={faMagnifyingGlass}
              size="xsmall"
            />
          }
          style={{paddingRight: 0}}
          suffix={
            isOpen && (
              <Button className="-mr-4 px-8" onClick={handleSearch}>
                {t('search.input.label')}
              </Button>
            )
          }
        />

        {isOpen && (
          <div className="mt-2 flex w-[inherit] justify-between gap-4 rounded-none border-t !border-peppercorn-100 bg-white px-4 py-6">
            <div className="flex flex-1 flex-col gap-4">
              <div className="flex gap-2">
                <Icon className="text-ezgreen-300" icon={faArrowTrendUp} size="xsmall" />
                <div className="text-xs font-bold uppercase tracking-wide text-peppercorn-300">
                  {t('search.content.popularSearches.label')}
                </div>
              </div>

              <SearchLink label="Breakfast" tag="Cuisines" />
              <SearchLink label="Pizza" tag="Cuisines" />
              <SearchLink label="Mexican" tag="Cuisines" />
            </div>

            <div className="flex flex-1 flex-col gap-4">
              <div className="text-xs font-bold uppercase tracking-wide text-peppercorn-300">
                {t('search.content.exploration.label')}
              </div>

              <SearchLink label="Best for group ordering" />
              <SearchLink label="Hidden gems" />
              <SearchLink label="New to ezcater" />
            </div>

            <div className="flex-1"></div>
          </div>
        )}
      </motion.div>
    </ClickAwayListener>
  );
};

export default SearchBar;
