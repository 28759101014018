const chunk = (array: any[], size = 1): any[] => {
  const desiredSize = Math.max(size, 0);
  if (desiredSize < 1) return [];

  let index = 0;
  let resIndex = 0;
  const result = new Array(Math.ceil(array.length / desiredSize));
  while (index < array.length) {
    result[resIndex++] = array.slice(index, (index += desiredSize));
  }
  return result;
};

export default chunk;
