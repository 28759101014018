import {faCartShopping} from '@fortawesome/pro-regular-svg-icons';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import appsFlyerDismissal from '@/utils/appsFlyerDismissal';
import AppBarIconButton from '../AppBarIconButton';

type CartButtonProps = {
  buttonRef?: React.Ref<HTMLButtonElement>;
  hasCarts?: boolean;
  onClick?: () => void;
  menuPageClickHandler?: () => void;
  cartHasItems?: boolean;
};

const cartButtonStyles = {
  populated:
    'border-green-110 bg-green-110 text-green-90 hover:border-green-110 hover:bg-green-110 focus:border-green-110 focus:bg-green-110 active:border-green-110 active:bg-green-110',
};

const CartButton: React.FC<CartButtonProps> = ({hasCarts, onClick, menuPageClickHandler}) => {
  const {t} = useTranslation('app-bar');
  const mobileClickHandler = menuPageClickHandler || onClick;

  const handleMobileClick = () => {
    appsFlyerDismissal();
    mobileClickHandler?.();
  };

  return (
    <AppBarIconButton
      icon={faCartShopping}
      type="button"
      data-testid="cart-button-component"
      aria-label={t('cart.label')}
      title={t('cart.label')}
      onClick={handleMobileClick}
      className={twJoin(hasCarts && cartButtonStyles.populated)}
      hasRedDot={hasCarts}
    />
  );
};

export default CartButton;
