import LargeDesktopExperience from '../LargeDesktopExperience';
import MobileAndTabletExperience from '../MobileAndTabletExperience';
import LandingDropdown from './LandingDropdown';
import {MenuItemType} from './MenuItem.types';
import MobileLandingDropdown from './MobileLandingDropdown';

export type LandingDropdownMenusProps = {
  trackLink: (path: string, title: string) => void;
};

const LandingDropdownMenus: React.FC<LandingDropdownMenusProps> = ({trackLink}) => {
  const companyDropdownItems: MenuItemType[] = [
    {
      text: 'Corporate catering overview',
      link: '/company/corporate-solutions/',
      trackingName: 'nav-corp-solutions-overview',
    },
    {
      text: 'Meetings & events catering',
      link: '/company/corporate-solutions/catered-meetings-and-events',
      trackingName: 'nav-catered-meetings-events',
    },
    {
      text: 'Employee meal programs',
      link: '/company/corporate-solutions/recurring-employee-meals',
      trackingName: 'nav-recurring-employee-meals',
    },
    {
      text: 'Concierge ordering',
      link: '/company/corporate-solutions/concierge-ordering',
      trackingName: 'nav-special-orders-services',
    },
    {
      text: 'Food spend management tools',
      link: '/company/corporate-solutions/food-spend-management',
      trackingName: 'nav-food-spend-mgmt',
    },
    {
      text: 'Tax-exempt ordering',
      link: '/company/corporate-solutions/tax-exempt',
      trackingName: 'nav-tax-exempt',
    },
  ];

  const restaurantDropdownItems: MenuItemType[] = [
    {text: 'Sign in to ezManage', link: '/caterer_portal'},
    {text: 'Catering Growth Platform', link: '/company/catering-growth-platform/'},
    {text: 'Growth Solutions', link: '/company/catering-growth-platform/growth-solutions/'},
    {
      text: 'Catering Management Solutions',
      link: '/company/catering-growth-platform/catering-management-solutions/',
    },
    {text: 'Delivery Solutions', link: '/company/catering-growth-platform/delivery-solutions/'},
    {
      text: 'Catering Partner Community',
      link: 'https://www.ezcater.com/company/lp/caterer-community-resources/',
    },
    {text: 'Help center', link: 'https://catering.ezcater.com/en/help'},
  ];

  const AboutUsDropdownItems: MenuItemType[] = [
    {text: 'Our story', link: 'https://www.ezcater.com/company/about-us/'},
    {text: 'Our team', link: 'https://www.ezcater.com/company/team/'},
    {text: 'Careers', link: 'https://www.ezcater.com/company/careers/'},
    {text: 'News and events', link: 'https://www.ezcater.com/company/news/'},
    {text: 'Blog', link: 'https://www.ezcater.com/lunchrush/'},
  ];

  return (
    <>
      <LargeDesktopExperience>
        <div className="flex items-center justify-start gap-3">
          <LandingDropdown
            testId="for-companies-dropdown"
            navItemText="Corporate catering solutions"
            menuItems={companyDropdownItems}
            trackLink={trackLink}
          />
          <LandingDropdown
            testId="for-restaurants-dropdown"
            navItemText="For restaurants"
            menuItems={restaurantDropdownItems}
            trackLink={trackLink}
          />
          <LandingDropdown
            testId="about-us-dropdown"
            navItemText="About us"
            menuItems={AboutUsDropdownItems}
            trackLink={trackLink}
          />
        </div>
      </LargeDesktopExperience>
      <MobileAndTabletExperience>
        <MobileLandingDropdown
          testId="for-companies-dropdown"
          navItemText="Corporate catering solutions"
          menuItems={companyDropdownItems}
          trackLink={trackLink}
        />
        <MobileLandingDropdown
          testId="for-restaurants-dropdown"
          navItemText="For restaurants"
          menuItems={restaurantDropdownItems}
          trackLink={trackLink}
        />
        <MobileLandingDropdown
          testId="about-us-dropdown"
          navItemText="About us"
          menuItems={AboutUsDropdownItems}
          trackLink={trackLink}
        />
      </MobileAndTabletExperience>
    </>
  );
};

export default LandingDropdownMenus;
