export const formatPoints = (points: number) => {
  const symbolLookup = [
    {value: 1e6, symbol: 'm'},
    {value: 1e3, symbol: 'k'},
    {value: 1, symbol: ''},
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const symbol = symbolLookup.find(({value}) => points >= value);

  return symbol
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
      })
        .format(symbol.value > 1 ? Math.floor((points / symbol.value) * 100) / 100 : points)
        .replace(regexp, '')
        .concat(symbol.symbol)
    : '0';
};

type SubTextProps = {
  pointsGivenForReview?: number;
};

export const linkSubText = (
  subText: string | ((args: SubTextProps) => string),
  subTextProps: SubTextProps,
) => (typeof subText === 'function' ? subText(subTextProps) : subText);
