import useIdentity from '@/hooks/useIdentity';

const useEzRewardsEligibility = () => {
  let rewardsEnabled = false;
  let balanceEnabled = false;
  let earningsEnabled = false;

  const {data: identityData} = useIdentity();
  const {canCollectOwnEzRewards, canEarnEzRewards, canEarnReferralRewards, enrolledInEzRewards} =
    identityData?.me?.consumerAccount ?? {};

  // Should see EzRewards link
  if (canCollectOwnEzRewards) {
    rewardsEnabled = true;
  }

  // Should see EzRewards balance
  if (canCollectOwnEzRewards && enrolledInEzRewards) {
    balanceEnabled = true;
  }

  // Should see reward points and should be highlighted
  if (canCollectOwnEzRewards && enrolledInEzRewards && canEarnReferralRewards && canEarnEzRewards) {
    earningsEnabled = true;
  }

  return {rewardsEnabled, balanceEnabled, earningsEnabled};
};

export default useEzRewardsEligibility;
