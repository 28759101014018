import styled from '@emotion/styled';

export const StyledEzPopoverContentContainer = styled.div`
  border: 1px solid #cccfd5;
  box-shadow: 0px 2px 10px hsla(0, 0%, 0%, 0.25);
  background-color: #ffffff;
  position: relative;
  top: 8px;
  right: -5px;
`;

export const StyledEzPopoverContent = styled.div`
  max-height: calc(100vh - 208px);
  overflow-y: auto;
  min-width: 250px;
  position: relative;
`;

export const StyledPopoverArrow = styled.div`
  height: 16px;
  top: -9px;
  width: 16px;

  &::before {
    content: '';
    transform: translateX(0px) rotate(45deg);
    transform-origin: center;
    height: 16px;
    width: 16px;
    position: absolute;
    border-left: 1px solid #cccfd5;
    border-top: 1px solid #cccfd5;
    background: #fff;
  }
`;
