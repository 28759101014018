import {createContext} from 'react';

import {type ConsumerCartCustomerMessage} from '@/graphql/types';

export type UserWarning = Pick<ConsumerCartCustomerMessage, 'code' | 'message'>;

export type UserWarningsContextPayload = {
  cartMessages: UserWarning[];
  fulfillmentDetailMessages: UserWarning[];
};

const UserWarningsContext = createContext<UserWarningsContextPayload>({
  cartMessages: [],
  fulfillmentDetailMessages: [],
});

export default UserWarningsContext;
