import {useContext} from 'react';

import {ContactUsModalContext} from '@/components/ContactUsModal';

type ContactUsModalPayload = {
  isOpen: boolean;
  toggleContactUsModal: () => void;
};

const useContactUsModal = (): ContactUsModalPayload => useContext(ContactUsModalContext);

export default useContactUsModal;
