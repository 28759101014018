import {GeocodedAddressResult} from '@/graphql/types';

export const getPlaceData = ({
  placeId,
  callback,
}: {
  placeId: string;
  callback: (
    result: google.maps.places.PlaceResult | null,
    status: google.maps.places.PlacesServiceStatus,
  ) => void;
}): void => {
  const map = new window.google.maps.Map(document.createElement('div'));
  const googlePlaces = new window.google.maps.places.PlacesService(map);
  googlePlaces.getDetails({placeId}, callback);
};

export type Address = {
  mainText: string;
  secondaryText: string;
  name: string;
  isAutocompleteResult: boolean;
  placeId: string;
  corporate?: boolean;
};

export const predictionToAddress = (
  prediction: google.maps.places.AutocompletePrediction,
): Address => ({
  mainText: prediction.structured_formatting.main_text,
  secondaryText: prediction.structured_formatting.secondary_text,
  name: prediction.description,
  isAutocompleteResult: true,
  placeId: prediction.place_id,
});

export type GeocodedAddress = {
  street: string;
  street2: null;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
};

export const geocodedAddressResultsToGeocodedAddresses = (
  geocodedAddressResult: GeocodedAddressResult[],
) => {
  return geocodedAddressResult.map(address => ({
    street: address.street,
    street2: null,
    city: address.city,
    state: address.state,
    zip: address.zip ?? '',
    latitude: address.latitude ?? 0,
    longitude: address.longitude ?? 0,
  }));
};

export const geocodedAddressToStartNewSearchParams = (address: GeocodedAddress) => ({
  'address[street]': address.street,
  'address[street2]': address.street2,
  'address[city]': address.city,
  'address[state]': address.state,
  'address[zip]': address.zip,
  'address[latitude]': address.latitude.toString(),
  'address[longitude]': address.longitude.toString(),
});
