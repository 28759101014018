import {useState} from 'react';
import {faUserCircle} from '@fortawesome/pro-regular-svg-icons';
import useTranslation from 'next-translate/useTranslation';

import Popup from '@/components/Popup';
import useEzRewardsEligibility from '@/hooks/useEzRewardsEligibility';
import useIdentity from '@/hooks/useIdentity';
import AppBarIconButton from '../AppBarIconButton';
import {UserMenu} from '../Menus';
import MenuTooltip from '../Menus/MenuTooltip';
import UserProfileButton from './UserProfileButton';

const User: React.FC<unknown> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {data: identityData} = useIdentity();
  const {balanceEnabled} = useEzRewardsEligibility();
  const {t} = useTranslation('app-bar');
  const ezRewardsBalance = identityData?.me?.consumerAccount?.ezRewardsAccount?.balance;
  const firstName = identityData?.me?.consumerAccount?.firstName || '';

  return (
    <>
      <div className="hidden tablet590:block desktop1279:hidden">
        <Popup
          onPopupToggle={setIsOpen}
          open={isOpen}
          placement="bottom-end"
          slotProps={{popup: {offset: {alignmentAxis: -3, mainAxis: 10}}}}
          trigger={
            <AppBarIconButton
              aria-label={t('user.label')}
              icon={faUserCircle}
              title={t('user.label')}
            />
          }
        >
          <UserMenu />
        </Popup>
      </div>

      <div className="hidden desktop1279:block">
        <MenuTooltip
          ariaLabel={t('user.label')}
          arrowClasses="right-2"
          className="min-w-80"
          content={<UserMenu />}
        >
          <UserProfileButton
            balance={balanceEnabled ? ezRewardsBalance : undefined}
            firstName={firstName}
          />
        </MenuTooltip>
      </div>
    </>
  );
};

export default User;
