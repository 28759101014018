import {memo} from 'react';
import {type ReactElement} from 'react';
import {format} from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

type TimeCellProps = {
  time: Date;
  onChange: (newTime: number) => void;
  isSelected: boolean;
};

const TimeCell = ({time, onChange, isSelected}: TimeCellProps): ReactElement => {
  const {t} = useTranslation('common');
  const onClick = () => {
    onChange(parseInt(format(time, 'Hmm'), 10));
  };
  const isNoon = time.getHours() === 12 && time.getMinutes() === 0;

  return (
    <button
      onClick={onClick}
      className={twJoin(
        '-mr-px flex-1 cursor-pointer border px-3 py-2 font-semibold text-peppercorn-800 last-of-type:mr-0',
        isSelected
          ? 'bg-ezo-secondary text-white hover:border-ezo-secondary'
          : 'hover:bg-peppercorn-100',
      )}
    >
      {isNoon ? (
        t('components.EventBar.EventTimeDropdownForm.noon')
      ) : (
        <>
          <span className="">{format(time, 'h:mm')}</span>
          <span className={twJoin('ml-0.5 text-xs', isSelected ? 'text-white' : 'text-gray-400')}>
            {format(time, 'a')}
          </span>
        </>
      )}
    </button>
  );
};

export default memo(TimeCell);
