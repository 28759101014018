import useTranslation from 'next-translate/useTranslation';

import {
  ABOUT_US_PATH,
  BLOG_PATH,
  CAREERS_PATH,
  COMPANY_NEWS_PATH,
  COMPANY_TEAM_PATH,
} from '@/paths';
import MenuAccordion from './MenuAccordion';
import MenuLink from './MenuLink';

type AboutUsMenuProps = {
  isAccordion?: boolean;
};

const AboutUsLinks: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  return (
    <div className="flex flex-col gap-6 p-2">
      <MenuLink label={t('navLinks.ourStory')} path={ABOUT_US_PATH} />
      <MenuLink label={t('navLinks.ourTeam')} path={COMPANY_TEAM_PATH} />
      <MenuLink label={t('navLinks.careers')} path={CAREERS_PATH} />
      <MenuLink label={t('navLinks.newsAndEvents')} path={COMPANY_NEWS_PATH} />
      <MenuLink label={t('navLinks.blog')} path={BLOG_PATH} />
    </div>
  );
};

const AboutUsMenu: React.FC<AboutUsMenuProps> = ({isAccordion = false}) => {
  const {t} = useTranslation('app-bar');

  if (!isAccordion) return <AboutUsLinks />;

  return (
    <MenuAccordion ariaLabel={t('navLinks.aboutUs')} header={t('navLinks.aboutUs')}>
      <AboutUsLinks />
    </MenuAccordion>
  );
};

export default AboutUsMenu;
