// eslint-disable-next-line no-restricted-imports
import {EzIcon} from '@ezcater/recipe';
import {faX} from '@fortawesome/pro-light-svg-icons';

import {Header, ToolbarButton, ToolbarItem} from './MobileModalHeader.styles';

type MobileModalHeaderProps = {
  hideModal: () => void;
};

const MobileModalHeader: React.FC<MobileModalHeaderProps> = ({hideModal}) => (
  <Header data-testid={'mobile-modal-header'}>
    <ToolbarItem>
      <ToolbarButton onClick={hideModal}>
        <EzIcon icon={faX} />
      </ToolbarButton>
    </ToolbarItem>
  </Header>
);

export default MobileModalHeader;
