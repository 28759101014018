import LargeDesktopExperience from '../LargeDesktopExperience';
import MobileAndTabletExperience from '../MobileAndTabletExperience';
import LandingDropdown from './LandingDropdown';
import {MenuItemType} from './MenuItem.types';
import MobileLandingDropdown from './MobileLandingDropdown';

export type ContactUsDropdownMenusProps = {
  supportPhoneNumber?: string;
  supportFaxNumber?: string;
  openLibertyChat?: () => void;
  trackLink: (path: string, title: string) => void;
};

const ContactUsDropdownMenu: React.FC<ContactUsDropdownMenusProps> = ({
  openLibertyChat,
  supportPhoneNumber,
  supportFaxNumber,
  trackLink,
}) => {
  const contactUsDropdownItems: MenuItemType[] = [
    {text: 'Send us a message', link: 'https://www.ezcater.com/company/contact-us/'},
    {text: 'Email: support@ezcater.com', link: 'mailto:support@ezcater.com'},
  ];

  if (openLibertyChat) {
    contactUsDropdownItems.push({text: 'Chat', onClick: openLibertyChat});
  }

  if (supportPhoneNumber) {
    contactUsDropdownItems.push({
      text: `Phone: ${supportPhoneNumber}`,
      link: `tel:${supportPhoneNumber}`,
    });
  }

  if (supportFaxNumber) {
    contactUsDropdownItems.push({
      text: `Fax: ${supportFaxNumber}`,
      link: `fax:${supportFaxNumber}`,
    });
  }

  return (
    <>
      <LargeDesktopExperience>
        <div className="flex items-center justify-start gap-3">
          <LandingDropdown
            testId="contact-us-dropdown"
            navItemText="Contact Us"
            menuItems={contactUsDropdownItems}
            trackLink={trackLink}
          />
        </div>
      </LargeDesktopExperience>
      <MobileAndTabletExperience>
        <MobileLandingDropdown
          testId="contact-us-dropdown"
          navItemText="Contact Us"
          menuItems={contactUsDropdownItems}
          trackLink={trackLink}
        />
      </MobileAndTabletExperience>
    </>
  );
};

export default ContactUsDropdownMenu;
