import {useContext, useMemo} from 'react';

import {ConsumerCartCustomerMessageCode} from '@/graphql/types';
import UserWarningsContext, {type UserWarning} from '../UserWarningsProvider/context';

export enum MessageAreas {
  Menu = 'menu',
  CatererAvailable = 'catererAvailable',
  CatererDelivers = 'catererDelivers',
  Cart = 'cart',
  Modal = 'modal',
  Mobile = 'mobile',
}

const GLOBAL_MESSAGES = [
  ConsumerCartCustomerMessageCode.CatererClosed,
  ConsumerCartCustomerMessageCode.CatererClosedForVacation,
  ConsumerCartCustomerMessageCode.CatererDeliveryOnly,
  ConsumerCartCustomerMessageCode.CatererNoBusinessOrders,
  ConsumerCartCustomerMessageCode.CatererNoResidentialOrders,
  ConsumerCartCustomerMessageCode.CatererNotInStore,
  ConsumerCartCustomerMessageCode.CatererOutOfRange,
  ConsumerCartCustomerMessageCode.CatererTakeoutOnly,
  ConsumerCartCustomerMessageCode.EarlyDeliveryTime,
  ConsumerCartCustomerMessageCode.ImpreciseOrderAddress,
  ConsumerCartCustomerMessageCode.ItemsPastCutoff,
  ConsumerCartCustomerMessageCode.OrderEventPastCutoff,
  ConsumerCartCustomerMessageCode.OrderOverCorpSpendingLimit,
  ConsumerCartCustomerMessageCode.OrderOverGroupBudget,
];

const AREA_MESSAGES = {
  menu: new Set(GLOBAL_MESSAGES.concat([ConsumerCartCustomerMessageCode.NoOrderEventDateTime])),
  catererAvailable: new Set([
    ConsumerCartCustomerMessageCode.CatererClosed,
    ConsumerCartCustomerMessageCode.CatererClosedForVacation,
  ]),
  catererDelivers: new Set([
    ConsumerCartCustomerMessageCode.CatererNoBusinessOrders,
    ConsumerCartCustomerMessageCode.CatererOutOfRange,
    ConsumerCartCustomerMessageCode.CatererNoBusinessOrders,
    ConsumerCartCustomerMessageCode.CatererNoResidentialOrders,
  ]),
  cart: new Set(GLOBAL_MESSAGES.concat([ConsumerCartCustomerMessageCode.NoOrderEventDateTime])),
  modal: new Set([
    ConsumerCartCustomerMessageCode.CatererClosed,
    ConsumerCartCustomerMessageCode.CatererClosedForVacation,
    ConsumerCartCustomerMessageCode.CatererDeliveryOnly,
    ConsumerCartCustomerMessageCode.CatererNoBusinessOrders,
    ConsumerCartCustomerMessageCode.CatererNoResidentialOrders,
    ConsumerCartCustomerMessageCode.CatererNotInStore,
    ConsumerCartCustomerMessageCode.CatererOutOfRange,
    ConsumerCartCustomerMessageCode.CatererTakeoutOnly,
    ConsumerCartCustomerMessageCode.NoCaterer,
    ConsumerCartCustomerMessageCode.OrderEventPastCutoff,
  ]),
  mobile: new Set(GLOBAL_MESSAGES.concat([ConsumerCartCustomerMessageCode.OrderItemsNotAvailable])),
};

type MessageArea = `${MessageAreas}`;

const useUserWarnings = (area: MessageArea): UserWarning | undefined => {
  const contextValue = useContext(UserWarningsContext);

  const activeMessage = useMemo(() => {
    let activeMessages = [];

    const cartMessages = contextValue?.cartMessages;

    if (cartMessages.length > 0) {
      activeMessages = cartMessages;
    } else {
      activeMessages = contextValue?.fulfillmentDetailMessages;
    }

    return activeMessages.find(message => AREA_MESSAGES[area]?.has(message.code));
  }, [area, contextValue?.cartMessages, contextValue?.fulfillmentDetailMessages]);

  return activeMessage;
};

export default useUserWarnings;
