import {Button} from '@ezcater/tapas';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import HeadsetIcon from '@/assets/icons/headset.svg?react';
import ContactUsModal from '@/components/ContactUsModal';
import useContactUsModal from '@/hooks/useContactUsModal';
import useIdentity from '@/hooks/useIdentity';
import useNavLinkTracking from '../hooks/useNavlinkTracking';

const ContactUs: React.FC<unknown> = () => {
  const router = useRouter();
  const path = router.pathname;
  const {trackLink} = useNavLinkTracking();
  const {isAdmin, isLoggedIn} = useIdentity();
  const {t} = useTranslation('app-bar');
  const {toggleContactUsModal} = useContactUsModal();
  const contactUsButtonClick = () => {
    toggleContactUsModal();
    trackLink(path, 'Contact Us Button');
  };

  return (
    <div
      className={twJoin(
        'whitespace-nowrap',
        isLoggedIn || isAdmin ? 'hidden' : 'mobile375:hidden',
        isAdmin || !isLoggedIn ? 'tablet590:block' : 'mobile375:block',
      )}
    >
      <div className={twJoin('hidden', isAdmin ? 'desktop1440:block' : 'desktop1279:block')}>
        <Button
          className="text-md font-normal no-underline underline-offset-4 hover:bg-transparent hover:underline active:bg-peppercorn-100"
          size="small"
          variant="secondary"
          onClick={contactUsButtonClick}
        >
          {t('contactUs.label')}
        </Button>
        <ContactUsModal />
      </div>

      <div className={twJoin(isAdmin ? 'desktop1440:hidden' : 'desktop1279:hidden')}>
        <Button
          aria-label={t('contactUs.label')}
          className="p-2 font-normal no-underline"
          title={t('contactUs.label')}
          variant="secondary"
          onClick={contactUsButtonClick}
        >
          <HeadsetIcon height={24} width={24} />
          <ContactUsModal />
        </Button>
      </div>
    </div>
  );
};

export default ContactUs;
