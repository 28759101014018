import {useContext} from 'react';
import invariant from 'tiny-invariant';

import MenuGTMContext, {type MenuGTMContextPayload} from '../MenuGTMProvider/context';

const useMenuGTM = (): MenuGTMContextPayload => {
  const contextValue = useContext(MenuGTMContext);

  invariant(
    contextValue != null,
    'useMenuGTM cannot be used outside of the MenuGTMProvider component',
  );

  return contextValue;
};

export default useMenuGTM;
