import {Chip, Icon, Link} from '@ezcater/tapas';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import {compilePath} from '@/utils';
import useNavLinkTracking from '../hooks/useNavlinkTracking';

type MenuLinkProps = React.ComponentPropsWithRef<typeof Link> & {
  chip?: React.ReactNode;
  chipGreen?: boolean;
  href?: string;
  icon?: IconDefinition | React.ReactNode;
  label: string;
  path?: string;
};

const iconIsFontAwesome = (icon: IconDefinition | React.ReactNode): icon is IconDefinition =>
  !!icon && typeof icon === 'object' && 'icon' in icon;

const MenuLink: React.FC<MenuLinkProps> = ({
  chip,
  chipGreen = false,
  href,
  icon,
  label,
  path,
  ...props
}) => {
  const {trackLink} = useNavLinkTracking();
  const {t} = useTranslation('app-bar');

  return (
    <Link
      className="w-full justify-start text-peppercorn-800 hover:text-peppercorn-800 hover:no-underline active:text-peppercorn-800"
      href={href || compilePath(path as string)}
      onClick={() => trackLink(href || compilePath(path as string), label)}
      title={t('menus.navigatesTo', {label})}
      {...props}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-1 items-center gap-4 hover:underline">
          {icon ? (
            iconIsFontAwesome(icon) ? (
              <Icon className="flex shrink-0 text-lg" icon={icon} />
            ) : (
              <div className="flex shrink-0">{icon}</div>
            )
          ) : null}

          {label}
        </div>

        {chip && (
          <Chip
            className={twJoin(
              'flex items-center gap-2 text-xs font-bold',
              chipGreen && 'bg-ezgreen-100 text-ezgreen-400',
            )}
            size="small"
          >
            {chip}
          </Chip>
        )}
      </div>
    </Link>
  );
};

export default MenuLink;
