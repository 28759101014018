import {format, isDate, parse, parseISO, subDays, subMinutes} from 'date-fns';
import {enUS} from 'date-fns/locale/en-US';
import {formatInTimeZone, fromZonedTime} from 'date-fns-tz';
import {type Translate} from 'next-translate';
import invariant from 'tiny-invariant';

import {type AdvanceNotice, type Maybe, type Scalars} from '@/graphql/types';

export enum DateTimeFormats {
  IsoDateFormat = 'yyyy-MM-dd',
  IsoTimeFormat = "HH:mm':00'",
  MilitaryTimeFormat = 'HHmm',
  EzFieldDateFormat = 'MM/dd/yyyy',
  EzFieldTimeFormat = 'hh:mm aaa',
  HourWithMinutes = 'h:mm a',
  MonthDateNoYear = 'MMMM d',
  MonthDate = 'MMMM d, yyyy',
  DateYear = 'd, yyyy',
  LongDateNoYear = 'LLLL d',
  LongDate = 'LLLL d, yyyy',
  VerboseShortDate = 'iii MM/dd/yyyy',
  VerboseDateTime = "iiii, LLLL do 'at' h:mm a",
  ShortDate = 'L/d/yyyy',
  ShortDateTime = 'EEE L/d, h:mm a',
  ShortDayNameDateTime = "iii, MM/dd/yyyy 'at' h:mm a",
  ShortDayDateTimeWithTimeZone = 'EEE. MM/dd, h:mm aa z',
  ShortMonthDate = 'MM/dd',
  VerboseLongDateTime = 'h:mm a L/dd/yyyy',
}

/**
 * Reference to a default identifier for UTC time offset
 */
export const UTC_TIMEZONE = 'Etc/UTC' as const;

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isValidDate = (target: unknown): target is Date => isDate(target);

// Fri, 5/13, 1:30 PM
export const convertEventToReadable = (
  date: string | null,
  time: number | null,
  t: Translate,
): string => {
  if (date == null) return t('components.EventBar.dateAndTime');
  if (time == null) return format(parseISO(date), 'EEE, L/d');

  const timestampDate = parse(`${time}`.padStart(4, '0'), 'HHmm', parseISO(date));
  const formattedDate = format(timestampDate, 'EEE, L/d');

  if (timestampDate.getHours() === 12 && timestampDate.getMinutes() === 0) {
    return `${formattedDate}, ${t('components.EventBar.EventTimeDropdownForm.noon')}`;
  }
  return `${formattedDate}, ${format(timestampDate, 'h:mm a')}`;
};

export const convertMilitaryTimeToEzFieldTime = (time: number): string | null => {
  const parsedTime = parse(
    `${time}`.padStart(4, '0'),
    DateTimeFormats.MilitaryTimeFormat,
    new Date(),
  );
  try {
    return format(parsedTime, DateTimeFormats.EzFieldTimeFormat);
  } catch (_RangeError) {
    return '';
  }
};

export const convertEzFieldTimeToMilitaryTime = (
  time?: string | null,
): Scalars['TimeWithoutZone']['output'] | null => {
  if (time == null) return null;

  try {
    const dateTime = parse(time, DateTimeFormats.EzFieldTimeFormat, new Date());
    return convertDateToMilitaryTime(dateTime);
  } catch (_RangeError) {
    return null;
  }
};

export const convertEzFieldTimeToIsoTime = (
  time?: string | null,
): Scalars['LocalTime']['output'] | null => {
  if (time == null || time === '') return null;

  try {
    const parsedTime = parse(time, DateTimeFormats.EzFieldTimeFormat, new Date());
    return format(parsedTime, DateTimeFormats.IsoTimeFormat);
  } catch (_e) {
    return null;
  }
};

export const convertIsoDateToEzFieldDate = (date: Scalars['Date']['output'] | null): string => {
  if (date == null) return '';

  try {
    const parsedDate = parse(date, DateTimeFormats.IsoDateFormat, new Date());
    return format(parsedDate, DateTimeFormats.EzFieldDateFormat);
  } catch (_RangeError) {
    return '';
  }
};

export const convertIsoDateToShortDate = (date: string | null): string => {
  if (date == null) return '';

  try {
    const parsedDate = parseISO(date);
    return format(parsedDate, DateTimeFormats.ShortDate);
  } catch (_RangeError) {
    return '';
  }
};

export const convertIsoDateToShortDayDateTimeWithTimeZone = (date?: string | null): string => {
  if (date == null) return '';

  try {
    const parsedDate = parseISO(date);

    return formatInTimeZone(
      parsedDate,
      localTimeZone,
      DateTimeFormats.ShortDayDateTimeWithTimeZone,
      {locale: enUS},
    );
  } catch (_RangeError) {
    return '';
  }
};

export const convertEzFieldDateToIso = (date: string | null): Scalars['Date']['output'] | null => {
  if (date == null) return null;

  try {
    const parsedDate = parse(date, DateTimeFormats.EzFieldDateFormat, new Date());
    return format(parsedDate, DateTimeFormats.IsoDateFormat);
  } catch (_RangeError) {
    return null;
  }
};

export function convertIsoTimeToMilitaryTime(time: string): Scalars['Int']['output'];
export function convertIsoTimeToMilitaryTime(time?: null): null;
export function convertIsoTimeToMilitaryTime(
  time?: string | null,
): Scalars['Int']['output'] | null {
  if (time == null) return null;

  const [hour, minute] = time.split(':');
  return parseInt(`${hour}${minute}`, 10);
}

export const convertMilitaryTimeToIsoTime = (
  time: number | string,
): Scalars['LocalTime']['output'] => {
  const paddedTime = `${time}`.padStart(4, '0');
  return `${paddedTime.slice(0, 2)}:${paddedTime.slice(2, 4)}:00`;
};

const convertDateToMilitaryTime = (date: Date): Scalars['Int']['output'] =>
  parseInt(format(date, DateTimeFormats.MilitaryTimeFormat), 10);

type IsPastEventArgs = {
  eventOn?: string;
  eventLocalTime?: number;
};

export const isEventPast = ({eventOn, eventLocalTime}: IsPastEventArgs): boolean => {
  if (!eventOn) {
    return false;
  }

  if (eventOn && eventLocalTime) {
    const eventLocalTimeInIsoFormat = convertMilitaryTimeToIsoTime(eventLocalTime);
    const parsed = parse(
      `${eventOn}${eventLocalTimeInIsoFormat}`,
      'yyyy-MM-ddHH:mm:ss',
      new Date(),
    );
    return parsed < new Date();
  }

  return parseISO(eventOn) < new Date();
};

type EventTimestamp = {
  eventOn: string;
  eventLocalTime?: Maybe<Scalars['LocalTime']['output']>;
};

export const convertLocalEventTimeToDate = (
  {eventOn, eventLocalTime}: EventTimestamp,
  tz?: string,
) => {
  return fromZonedTime(`${eventOn} ${eventLocalTime}`, tz ?? UTC_TIMEZONE);
};

const padMilitaryTime = (time: number) => {
  return time.toString().padStart(4, '0');
};

/**
 * Returns a timestamp for the advance notice cutoff given some event date as a date object
 *
 * @param notice - the notice object
 * @param datetime - the date of the event, as a JavaScript Date object
 * @param timezone - the timezone offset, generally referenced via caterer
 */
export function convertAdvanceNoticeToDate(
  notice: AdvanceNotice,
  eventAt: Date,
  timezone: string,
): Date | null {
  if (!notice || !eventAt) return null;

  if (notice.__typename === 'LeadTime') {
    return subMinutes(eventAt, notice.leadTimeInMinutes);
  }

  invariant(notice.__typename === 'DayBeforeCutoffTime', 'Invalid notice type');

  // get the localized event date, this is necessary because the UTC date might be different if near midnight
  const dayBeforeDateString = formatInTimeZone(subDays(eventAt, 1), timezone, 'yyyy-MM-dd');
  // convert the cutoff time to a more easily parsable format, e.g. 1245 -> 12:45:00
  const cutoffTime = format(
    parse(
      padMilitaryTime(notice.dayBeforeCutoffTime),
      DateTimeFormats.MilitaryTimeFormat,
      new Date(),
    ),
    'HH:mm:00',
  );
  return fromZonedTime(`${dayBeforeDateString} ${cutoffTime}`, timezone);
}

export const convertTimestampToVerboseShortDate = (timestamp: string) =>
  format(new Date(timestamp), DateTimeFormats.VerboseShortDate);
