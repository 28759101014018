import {forwardRef, useState} from 'react';
import {Button, Icon} from '@ezcater/tapas';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons';
import {AnimatePresence, m as motion} from 'framer-motion';
import {twMerge} from 'tailwind-merge';

type AccordionProps = React.PropsWithChildren<{
  defaultOpen?: boolean;
  header: React.ReactNode;
  slotProps?: {
    content?: React.ComponentProps<typeof motion.div>;
    headerButton?: React.ComponentProps<typeof Button>;
    root?: React.ComponentProps<'div'>;
  };
}>;

type Ref = HTMLDivElement;

const Accordion = forwardRef<Ref, AccordionProps>(
  ({children, defaultOpen = false, header, slotProps = {}}, ref) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    return (
      <div ref={ref} {...slotProps?.root} className={twMerge('w-full', slotProps?.root?.className)}>
        <Button
          aria-controls="accordion-content"
          aria-expanded={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          variant="secondary"
          {...slotProps?.headerButton}
          className={twMerge(
            'mb-1 flex w-full items-center justify-between rounded-none border-b border-peppercorn-200 p-0 no-underline hover:bg-transparent active:bg-transparent',
            slotProps?.headerButton?.className,
          )}
        >
          {header}

          <motion.div
            animate={{rotate: isOpen ? 180 : 0}}
            initial={false}
            transition={{duration: 0.2}}
          >
            <Icon icon={faChevronDown} />
          </motion.div>
        </Button>

        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              animate={{height: isOpen ? 'auto' : 0, opacity: isOpen ? 1 : 0, overflow: 'hidden'}}
              exit={{height: 0, opacity: 0, overflow: 'unset'}}
              id="accordion-content"
              initial={{height: 0, opacity: 0, overflow: 'unset'}}
              role="region"
              transition={{duration: 0.2}}
              {...slotProps?.content}
              className={isOpen && twMerge('mt-2 overflow-hidden', slotProps?.content?.className)}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  },
);

export default Accordion;
