import {useRef} from 'react';
import {EzPopover} from '@ezcater/recipe';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons';

import useToggleState from '@/hooks/useToggleState';
import {NavDropdownMenuItem, NavDropdownNavLink} from '../ProfileAppBarItem/MyAccountNav.styles';
import {
  LandingDropdownMouseContainer,
  LandingNavDropdownMenu,
  NavDropdownClickableItem,
  StyledEzIcon,
  StyledNavBarItem,
} from './LandingDropdown.styles';
import {MenuItemType} from './MenuItem.types';

export type LandingDropdownProps = {
  navItemText: string;
  menuItems: MenuItemType[];
  trackLink: (path: string, title: string) => void;
  testId?: string;
};

const LandingDropdown: React.FC<LandingDropdownProps> = ({
  navItemText,
  menuItems,
  trackLink,
  testId = '',
}) => {
  const {toggleValue: showDropdown, on: show, off: hide} = useToggleState();
  const navItemRef = useRef(null);

  return (
    <LandingDropdownMouseContainer onMouseEnter={show} onMouseLeave={hide}>
      <StyledNavBarItem data-testid={testId}>
        {navItemText}
        <StyledEzIcon ref={navItemRef} icon={faChevronDown} size="small" />
      </StyledNavBarItem>
      {showDropdown && (
        <EzPopover targetRef={navItemRef} shouldCloseOnBlur onClose={() => null}>
          <LandingNavDropdownMenu>
            {menuItems.map(item => (
              <NavDropdownMenuItem data-testid="landing-dropdown-menu-item" key={item.text}>
                {item.link != null && (
                  <NavDropdownNavLink
                    data-testid="landing-dropdown-menu-item-link"
                    href={item.link}
                    title={`Navigates to ${item.text} page`}
                    onClick={() => trackLink(item.link || '', item.text)}
                  >
                    {item.text}
                  </NavDropdownNavLink>
                )}
                {item.onClick && (
                  <NavDropdownClickableItem
                    data-testid="landing-dropdown-menu-item-link"
                    title={`Opens ${item.text}`}
                    onClick={() => {
                      trackLink(item.link || '', item.text);
                      item.onClick?.();
                    }}
                  >
                    {item.text}
                  </NavDropdownClickableItem>
                )}
              </NavDropdownMenuItem>
            ))}
          </LandingNavDropdownMenu>
        </EzPopover>
      )}
    </LandingDropdownMouseContainer>
  );
};

export default LandingDropdown;
