import {EzPopover} from '@ezcater/recipe';

import {
  StyledEzPopoverContent,
  StyledEzPopoverContentContainer,
  StyledPopoverArrow,
} from './Popover.styles';

type PopoverProps = React.PropsWithChildren<{
  close: () => void;
  buttonRef: React.RefObject<HTMLElement>;
  className?: string;
}>;

const Popover: React.FC<PopoverProps> = ({close, buttonRef, children, className}) => {
  return (
    <EzPopover
      className={className}
      data-testid="ez-popover"
      placement="bottom-end"
      targetRef={buttonRef}
      shouldCloseOnBlur
      onClose={close}
    >
      <StyledEzPopoverContentContainer>
        <StyledPopoverArrow data-popper-arrow />
        <StyledEzPopoverContent>{children}</StyledEzPopoverContent>
      </StyledEzPopoverContentContainer>
    </EzPopover>
  );
};

export default Popover;
