import {css} from '@emotion/react';
import styled from '@emotion/styled';

import appColors from '@/components/RootProvider/appColors';
import appFonts from '@/components/RootProvider/appFonts';

const activeStyles = () => css`
  border-left: 5px solid ${appColors.blue600};
`;

type CardProps = {
  isUnread?: boolean;
};

export const Card = styled.div<CardProps>`
  display: block;
  border-bottom: 1px solid ${appColors.gray300};
  border-left: 5px solid transparent;
  padding: 12px;
  font-weight: ${appFonts.fontWeightBase};
  color: ${appColors.gray400};
  ${({isUnread}) => isUnread && activeStyles()}
  &:hover {
    color: ${appColors.black};
    background-color: ${appColors.gray100};
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

export const Title = styled.div`
  font-size: ${appFonts.textSm};
  margin-bottom: 12px;
  font-weight: ${appFonts.fontWeightSemiBold};
`;

export const Details = styled.div`
  font-size: ${appFonts.textXs};
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 18px;
  max-height: 36px;
`;
