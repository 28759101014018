import {createContext} from 'react';

import {
  type CateringMenuConsumerCartFragment,
  type CateringMenuFulfillmentDetailWithMessagesFragment,
  type ChannelNameEnum,
  type ConsumerCartRecommendationsQuery,
} from '@/graphql/types';

export const ConsumerCartContext = createContext<CateringMenuConsumerCartFragment | null>(null);

export const RecommendationsContext = createContext<
  ConsumerCartRecommendationsQuery['recommendationsForOrder']
>([]);

export const FulfillmentDetailContext =
  createContext<CateringMenuFulfillmentDetailWithMessagesFragment | null>(null);

export type MenuPageContextPayload = {
  date: string;
  catererUrlId: string;
  channel: ChannelNameEnum;
  fulfillmentDetailId: string | null;
  cartId: string | null;
  pageIsInitializing: boolean;
  setFulfillmentDetailId: (id: string) => void;
  setCartId: (id: string) => void;
};

export const MenuPageContext = createContext<MenuPageContextPayload | null>(null);
