import {css} from '@emotion/react';
import styled from '@emotion/styled';

import appColors from '@/components/RootProvider/appColors';
import recipeMq from '@/styles/recipeMq';

export const Header = styled.div`
  background-color: ${appColors.gray500};
  color: ${appColors.white};
  height: 58px;
  min-height: 58px;
  display: flex;
  justify-content: flex-end;
`;

export const toolbarButtonStyle = () => css`
  display: flex;
  align-items: center;
  color: ${appColors.white};
  letter-spacing: 0.34px;
  padding: 0 16px;
  background: none;
  border: none;

  ${recipeMq('medium')} {
    color: ${appColors.gray500};
    padding: 0 20px;

    &:hover {
      color: ${appColors.gray500};
      background-color: #dbdedf;
    }
  }
`;

export const ToolbarItem = styled.li`
  position: relative;
  display: flex;
  align-items: stretch;
`;

export const ToolbarButton = styled.button`
  ${toolbarButtonStyle}
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
`;
