import {forwardRef} from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import {twMerge} from 'tailwind-merge';

import poweredByGoogleImage from '@/assets/images/powered_by_google_on_white_hdpi.png';

export const AddressAutocompleteListbox = forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
  function AddressAutocompleteListbox({children, ...props}, ref) {
    const {t} = useTranslation('common');

    return (
      <ul {...props} className={twMerge(props?.className, 'p-3')} ref={ref}>
        {children}
        <li className="mr-2 mt-1 flex justify-end">
          <div className="flex">
            <Image
              alt={t('components.EventBar.GoogleAutocomplete.poweredByGoogle')}
              className="mx-auto"
              height="15"
              src={poweredByGoogleImage.src}
              width="120"
            />
          </div>
        </li>
      </ul>
    );
  },
);
