// @ts-strict-ignore
import type React from 'react';
import {createContext, useContext} from 'react';

import {useUserEventsHistoryQuery} from '@/graphql/types';
import {type UserEventsHistoryQueryQueryResult} from '@/graphql/types';

type UserEventsHistoryProviderProps = React.PropsWithChildren<{
  userUuid: string;
}>;

type UserEventsHistoryContextType = Pick<
  UserEventsHistoryQueryQueryResult,
  'data' | 'error' | 'loading' | 'refetch'
>;

export const UserEventsHistoryContext = createContext<UserEventsHistoryContextType>({
  loading: true,
  data: undefined,
  error: undefined,
  refetch: undefined,
});

export const useUserEventsHistory = (): UserEventsHistoryContextType =>
  useContext(UserEventsHistoryContext);

const UserEventsHistoryProvider: React.FC<UserEventsHistoryProviderProps> = ({
  children,
  userUuid,
}) => {
  const response = useUserEventsHistoryQuery({variables: {userUuid}});

  return (
    <UserEventsHistoryContext.Provider value={response}>
      {children}
    </UserEventsHistoryContext.Provider>
  );
};

export default UserEventsHistoryProvider;
