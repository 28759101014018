import {ParsedUrlQuery} from 'querystring';

import {safeLocalStorage} from './storage';

export const SEARCH_CURRENT_PAGE_TOKEN = 'searchCurrentPage';

export const calculateCurrentPage = (query: ParsedUrlQuery): number =>
  parseInt(query?.page as string, 10) || 1;

export const calculateTotalPages = (resultsCount: number, resultsPerPage: number): number =>
  resultsCount ? Math.ceil(resultsCount / resultsPerPage) : 0;

export const calculatePaginationOffset = (page: number, perPage: number): number => {
  if (page < 0 || perPage < 0) return 0;
  return (perPage * (page ? page - 1 : 0) || 0) | 0;
};

export const storeCurrentSearchPage = (page: number) => {
  safeLocalStorage.setItem(SEARCH_CURRENT_PAGE_TOKEN, page.toString());
};

export const getLastSearchPage = () => {
  return safeLocalStorage.getItem(SEARCH_CURRENT_PAGE_TOKEN) || '';
};
