import styled from '@emotion/styled';

import recipeMq from '@/styles/recipeMq';

const TabletAndDesktopExperience = styled.div<{display?: string}>`
  display: none;

  ${recipeMq('medium')} {
    display: ${({display = 'block'}) => display};
  }
`;

export default TabletAndDesktopExperience;
