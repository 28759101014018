import {Link} from '@ezcater/tapas';
import {twJoin} from 'tailwind-merge';

type SearchLinkProps = React.ComponentProps<typeof Link> & {
  label: string;
  tag?: string;
};

const SearchLink: React.FC<SearchLinkProps> = ({label, tag, ...props}) => {
  const linkClasses =
    'text-xl text-peppercorn-800 hover:text-peppercorn-800 active:text-peppercorn-800';

  if (tag)
    return (
      <div className="flex items-center gap-2">
        <Link className={linkClasses} tabIndex={0} {...props}>
          {label}
        </Link>

        <div className="text-xs font-bold uppercase tracking-wide text-peppercorn-300">{tag}</div>
      </div>
    );

  return (
    <Link className={twJoin(linkClasses, 'block w-fit')} tabIndex={0} {...props}>
      {label}
    </Link>
  );
};

export default SearchLink;
