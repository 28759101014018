import {Icon} from '@ezcater/tapas';
import {faCartShopping} from '@fortawesome/free-solid-svg-icons';
import {twJoin} from 'tailwind-merge';

import MobileExperience from '@/components/MobileExperience';
import TabletAndDesktopExperience from '@/components/TabletAndDesktopExperience';
import appsFlyerDismissal from '@/utils/appsFlyerDismissal';

type CartButtonProps = {
  buttonRef?: React.Ref<HTMLButtonElement>;
  hasCarts?: boolean;
  onClick?: () => void;
  menuPageClickHandler?: () => void;
  cartHasItems?: boolean;
};

const cartButtonStyles = {
  root: 'relative inline-block h-9 w-10 whitespace-nowrap rounded border px-4 py-1.5 pl-2 font-bold leading-5 outline-none tablet:mt-[13px] tablet:h-[42px] tablet:w-[92px] tablet:pl-4',
  empty:
    'border-green-90 bg-green-90 text-green-110 hover:border-green-90 hover:bg-green-90 focus:border-green-90 focus:bg-green-90 active:border-green-90 active:bg-green-90',
  populated:
    'border-green-110 bg-green-110 text-green-90 hover:border-green-110 hover:bg-green-110 focus:border-green-110 focus:bg-green-110 active:border-green-110 active:bg-green-110',
};

const CartButton: React.FC<CartButtonProps> = ({
  buttonRef,
  hasCarts,
  onClick,
  menuPageClickHandler,
  cartHasItems,
}) => {
  const populatedCart = hasCarts || cartHasItems;
  const mobileClickHandler = menuPageClickHandler || onClick;
  const cartButtonClassName = twJoin(
    cartButtonStyles.root,
    populatedCart ? cartButtonStyles.populated : cartButtonStyles.empty,
  );

  const handleMobileClick = () => {
    appsFlyerDismissal();
    mobileClickHandler?.();
  };

  return (
    <>
      <TabletAndDesktopExperience>
        <button
          type="button"
          data-testid="cart-button-component"
          className={cartButtonClassName}
          ref={buttonRef}
        >
          <span className="pr-1.5">Cart</span>
          <Icon icon={faCartShopping} className="inline-block text-xl" />
          {hasCarts && (
            <span
              className="absolute -right-2 -top-2 block h-4 w-4 rounded-full border-2 border-white bg-red-100"
              data-testid="red-dot"
            />
          )}
        </button>
      </TabletAndDesktopExperience>
      <MobileExperience>
        <button
          type="button"
          data-testid="cart-button-component"
          className={cartButtonClassName}
          onClick={handleMobileClick}
        >
          <Icon icon={faCartShopping} className="inline-block text-xl" />
          {populatedCart && (
            <span
              className="absolute -right-2 -top-2 block h-4 w-4 rounded-full border-2 border-white bg-red-100"
              data-testid="red-dot"
            />
          )}
        </button>
      </MobileExperience>
    </>
  );
};

export default CartButton;
