// @ts-strict-ignore
import type React from 'react';
import {createContext, memo, useContext, useEffect, useMemo} from 'react';
import {isAfter, parseISO} from 'date-fns';
import {capitalize, orderBy} from 'lodash-es';

import UserEventsHistoryProvider, {
  useUserEventsHistory,
} from '@/components/UserEventsHistoryProvider';
import FeatureFlags from '@/FeatureFlags';
import {ActorTypes} from '@/graphql/types';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIdentity from '@/hooks/useIdentity';
import useToggleState from '@/hooks/useToggleState';

type DcEventHistory = ReturnType<
  typeof useUserEventsHistory
>['data']['userDcEventHistories'][number];
type DcEvent = DcEventHistory['events'][number];
type LastReadDcEventIndex = DcEventHistory['lastReadEventIndices'][number];
type ID = DcEventHistory['orderUuid'];
type DcEventReference = DcEvent['reference'];
type NotificationReference = Exclude<DcEventReference, {__typename?: 'DecisionPoint'}>;
type ReferenceType = {
  at: string | null;
} & NotificationReference;
type NotificationProps = {
  events: DcEvent[];
  lastReadEventIndices: LastReadDcEventIndex[];
  orderUuid: ID;
};

const CUSTOMER = ActorTypes.Customer;

const isCustomerInitiatedEvent = (event: DcEvent): boolean => {
  switch (event.reference.__typename) {
    case 'Message':
      return event.reference.sentByType === CUSTOMER;
    case 'DecisionPoint':
      return event.reference.responderType === CUSTOMER;
    default:
      return false;
  }
};

const createdOrReviewedAt = (event: DcEvent): Date => {
  if (event.reference?.__typename === 'Message' && event.reference.reviewedAt) {
    return parseISO(event.reference.reviewedAt);
  }
  return parseISO(event.at);
};

/**
 * Notifications are the most recent relevant (e.g.!DecisionPoint) event reference
 * from a given DcEventHistory.
 *
 * A DcEventHistory is an ordered (by event `at` asc) list of Direct Connect
 * events regarding an order.
 *
 * A notification is unread when there are:
 *   1) newer events in the DcEventHistory than the LastReadDeEventIndex read by
 *      the customer, and
 *   2) that relevant event did not originate from customer--isn't customer
 *      initiated.
 */
export class Notification {
  events: DcEvent[];

  isUnread: boolean;

  lastReadAt: Date | null;

  orderUuid: string;

  _brandName: string;

  _reference: ReferenceType | null;

  constructor({events, lastReadEventIndices, orderUuid}: NotificationProps) {
    const readAt = lastReadEventIndices.find(index => index.readByType === CUSTOMER)?.readAt;

    // if lastReadAt is null, we set it to Jan 1, 1970, so that isAfter will
    // always return true when lastReadAt is its second argument.
    this.lastReadAt = readAt ? parseISO(readAt) : new Date(0);
    this.isUnread = events.some(
      event =>
        isAfter(createdOrReviewedAt(event), this.lastReadAt) && !isCustomerInitiatedEvent(event),
    );
    this.orderUuid = orderUuid;
    this.events = events;
    this._brandName = null;
    this._reference = null;
  }

  get at(): string | null {
    if (this.reference?.__typename === 'Message' && this.reference.reviewedAt) {
      return this.reference.reviewedAt;
    }
    return this.reference?.at;
  }

  get brandName(): string | null {
    if (this._brandName || !this.reference) return this._brandName;

    this._brandName = this.reference.orderInfo.brandName;

    return this._brandName;
  }

  get changeType(): string | null {
    if (this.reference?.__typename !== 'OrderItemChangeRequest') return null;

    /**
     * lodash.capitalize will lowercase all other letters in the string and
     * then `toUpperCase()` the first character.
     * [_.capitalize](https://lodash.com/docs/4.17.15#capitalize)
     */
    return this.reference.itemChanges.length > 0
      ? capitalize(this.reference.itemChanges[0].changeType)
      : null;
  }

  get id(): string | null {
    return this.reference?.id;
  }

  get messageType(): string | null {
    return this.reference?.__typename;
  }

  get message(): string | null {
    if (!this.reference) return null;

    switch (this.reference.__typename) {
      case 'Message':
        return this.reference.content;
      case 'OrderItemChangeRequest':
        return this.reference.itemChanges[0]?.reason ?? null;
      case 'TimeChangeRequest':
        return this.reference.reason;
      default:
        return null;
    }
  }

  /**
   * Most recent DcEventReference that is not a DecisionPoint
   */
  get reference(): ReferenceType | null {
    if (this._reference) return this._reference;

    for (let i = this.events.length - 1; i >= 0; --i) {
      const {at, reference} = this.events[i];

      if (reference.__typename !== 'DecisionPoint') {
        this._reference = {at, ...reference};
        break;
      }
    }

    return this._reference;
  }
}

type PopoverControls = {
  open: () => void;
  close: () => void;
  toggle: () => void;
};

const NotificationsContext = createContext<Notification[]>([]);
const NotificationsUnreadContext = createContext(false);
const NotificationsPopoverOpenContext = createContext(false);
const NotificationsPopoverControlContext = createContext<PopoverControls>({
  open: () => {},
  close: () => {},
  toggle: () => {},
});

export const useDirectConnectNotifications = (): Notification[] => useContext(NotificationsContext);
export const useDirectConnectNotificationsUnread = (): boolean =>
  useContext(NotificationsUnreadContext);
export const useDirectConnectNotificationsPopoverOpen = (): boolean =>
  useContext(NotificationsPopoverOpenContext);
export const useDirectConnectNotificationsPopoverControl = (): PopoverControls =>
  useContext(NotificationsPopoverControlContext);

const DirectConnectContextStack = memo(function DirectConnectContextStack({
  children,
}: {
  children: React.ReactNode;
}) {
  const {data, refetch} = useUserEventsHistory();
  const {userDcEventHistories} = data ?? {};

  /**
   * lodash.orderBy will always return an array.
   * [_.orderBy](https://lodash.com/docs/4.17.15#orderBy)
   */
  const notifications = useMemo(
    () =>
      orderBy(
        userDcEventHistories
          ?.filter(dcEventHistory => dcEventHistory.events.length > 0)
          .map(dcEventHistory => new Notification(dcEventHistory)),
        ['at'],
        ['desc'],
      ),
    [userDcEventHistories],
  );

  const hasUnreadNotifications = useMemo(
    () => notifications.some(({isUnread}) => isUnread),
    [notifications],
  );

  const {toggleValue: isPopoverOpen, on: open, off: close, toggle} = useToggleState(false);
  const popoverControls = useMemo(() => ({open, close, toggle}), [open, close, toggle]);

  useEffect(() => {
    if (isPopoverOpen && refetch) {
      refetch();
    }
  }, [isPopoverOpen, refetch]);

  return (
    <NotificationsContext.Provider value={notifications}>
      <NotificationsUnreadContext.Provider value={hasUnreadNotifications}>
        <NotificationsPopoverOpenContext.Provider value={isPopoverOpen}>
          <NotificationsPopoverControlContext.Provider value={popoverControls}>
            {children}
          </NotificationsPopoverControlContext.Provider>
        </NotificationsPopoverOpenContext.Provider>
      </NotificationsUnreadContext.Provider>
    </NotificationsContext.Provider>
  );
});

const DirectConnectNotificationsProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const isEnabled = useFeatureFlag(FeatureFlags.EnableDirectComms);
  const {data} = useIdentity();
  const {consumerAccount} = data?.me ?? {};
  const isLoggedIn = Boolean(consumerAccount);

  if (!isEnabled || !isLoggedIn) return <>{children}</>;

  return (
    <UserEventsHistoryProvider userUuid={consumerAccount.identityId}>
      <DirectConnectContextStack>{children}</DirectConnectContextStack>
    </UserEventsHistoryProvider>
  );
};

export default DirectConnectNotificationsProvider;
