import {faBell} from '@fortawesome/pro-regular-svg-icons';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import AppBarIconButton from '../AppBarIconButton';

type NotificationsProps = {
  isAdmin?: boolean;
};

const Notifications: React.FC<NotificationsProps> = ({isAdmin}) => {
  const {t} = useTranslation('app-bar');

  return (
    <AppBarIconButton
      aria-label={t('notifications.label')}
      className={twJoin(isAdmin && 'hidden mobile375:block')}
      icon={faBell}
      title={t('notifications.label')}
    />
  );
};

export default Notifications;
