import {useContext} from 'react';
import invariant from 'tiny-invariant';

import {MenuPageContext, type MenuPageContextPayload} from '../MenuPageProvider/context';

const useMenuPageData = (): MenuPageContextPayload => {
  const contextValue = useContext(MenuPageContext);

  invariant(
    contextValue != null,
    'useMenuPageData cannot be used outside of the MenuPageProvider component',
  );

  return contextValue;
};

export default useMenuPageData;
