import {Link} from '@ezcater/tapas';
import Head from 'next/head';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import ezcaterLogo from '@/assets/icons/ezcater-logo.svg';
import {START_SEARCH_PATH} from '@/paths';
import {compilePath} from '@/utils';

const Logo: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  return (
    <>
      <Head>
        <link rel="preload" href={ezcaterLogo.src} as="image" />
      </Head>

      <Link className="w-24 shrink-0 desktop:w-32" href={compilePath(START_SEARCH_PATH)}>
        <Image alt={t('logo.label')} src={ezcaterLogo} priority />
      </Link>
    </>
  );
};

export default Logo;
