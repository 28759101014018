import {useMemo} from 'react';

import LoadingSpinner from '@/components/Loading/LoadingSpinner';
import {OpenOrdersQuery, useOpenOrdersQuery} from '@/graphql/types';
import RequiredPath from '@/utils/RequiredPath';
import MenuTooltip from '../Menus/MenuTooltip';
import CartButton from './CartButton';
import DraftOrder from './DraftOrder';
import EmptyState from './EmptyState';

export type Order = {
  id: string;
  caterer: {
    id: string;
    urlId: string;
    name: string;
    address?: {
      id: string;
      city: string;
      state: string;
      fullAddress?: string;
      nameOrLocation?: string;
      street?: string;
      timeZoneIdentifier?: string;
      uuid?: string;
    };
  };
  eventName?: string | null;
  eventAt?: string | null;
  updatedAt?: string | null;
  accountName?: string;
};

type FulfillmentDetailConnection = RequiredPath<
  OpenOrdersQuery,
  ['identity', 'fulfillmentDetails']
>;

type FulfillmentDetailConnectionConsumerCart = RequiredPath<
  FulfillmentDetailConnection,
  ['edges', number, 'node', 'consumerCarts', number]
>;

type FormatConsumerCartParams = {
  consumerCart: FulfillmentDetailConnectionConsumerCart;
  eventName?: string | null;
  eventAt?: string | null;
  updatedAt?: string | null;
  accountName?: string;
};

const formatConsumerCart = ({
  consumerCart,
  eventName,
  eventAt,
  updatedAt,
  accountName,
}: FormatConsumerCartParams): Order => {
  return {
    eventName,
    eventAt,
    id: consumerCart.id,
    caterer: {
      ...consumerCart.caterer,
      address: consumerCart.caterer.address ?? undefined,
    },
    updatedAt,
    accountName,
  };
};

const formatOrders = (fulfillmentDetailConnection?: FulfillmentDetailConnection | null) => {
  if (!fulfillmentDetailConnection?.edges) return [];

  return fulfillmentDetailConnection.edges
    .flatMap(fulfillmentDetailEdge => {
      const fulfillmentDetail = fulfillmentDetailEdge.node;
      if (!fulfillmentDetail?.consumerCarts) return null;

      return fulfillmentDetail.consumerCarts.map(cart =>
        formatConsumerCart({
          consumerCart: cart,
          eventName: fulfillmentDetail.eventName,
          eventAt: fulfillmentDetail.eventAt,
          updatedAt: fulfillmentDetail.order?.updatedAt,
          accountName: fulfillmentDetail?.corpAccount?.name,
        }),
      );
    })
    ?.filter(cart => cart != null);
};

const groupAndSortOrders = (orders: Order[]): Order[] => {
  return [...orders].sort((a, b) => {
    // Step 1: Group by presence of date and event name
    const hasDateA = Boolean(a.eventAt);
    const hasDateB = Boolean(b.eventAt);
    const hasEventNameA = Boolean(a.eventName);
    const hasEventNameB = Boolean(b.eventName);

    // Step 2: Apply grouping rules
    if (!hasDateA && !hasDateB) {
      // Case 1: Neither has a date, sort by presence of event name
      if (!hasEventNameA && hasEventNameB) return -1;
      if (hasEventNameA && !hasEventNameB) return 1;
      return 0; // Both lack dates and event names
    }

    if (!hasDateA) return -1; // No date comes before orders with dates
    if (!hasDateB) return 1;

    // Step 3: If both have dates, sort by ascending date
    // Ascending means earlier dates come before later dates
    // Example: 2024-01-01 comes before 2024-01-02
    const dateA = new Date(a.eventAt!).getTime();
    const dateB = new Date(b.eventAt!).getTime();
    return dateA - dateB;
  });
};

const Cart: React.FC<unknown> = () => {
  const {data, loading} = useOpenOrdersQuery();

  const orders = useMemo(() => formatOrders(data?.identity?.fulfillmentDetails), [data]);
  const hasCarts = orders.length > 0;

  const groupedOrders = useMemo(() => groupAndSortOrders(orders), [orders]);
  const showLoadingSpinner = loading && !hasCarts;

  let content: React.ReactNode;

  if (showLoadingSpinner) {
    content = <LoadingSpinner />;
  } else if (!hasCarts) {
    content = <EmptyState />;
  } else {
    content = groupedOrders.map((order, index) => (
      <div key={order.id}>
        <DraftOrder order={order} />
        {index < groupedOrders.length - 1 && <div className="my-2 bg-gray-200"></div>}
      </div>
    ));
  }

  return (
    <>
      <div className="hidden tablet:block">
        <MenuTooltip
          arrowClasses="right-0"
          className="mr-6"
          content={<div className="w-[260px] text-black">{content}</div>}
          placement="bottom-start"
        >
          <div>
            <CartButton hasCarts={hasCarts} />
          </div>
        </MenuTooltip>
      </div>
      <div className="block tablet:hidden">
        <CartButton />
      </div>
    </>
  );
};

export default Cart;
