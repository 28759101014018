import React from 'react';
import {Icon, Link} from '@ezcater/tapas';
import {faCartCircleXmark} from '@fortawesome/pro-light-svg-icons';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import useIdentity from '@/hooks/useIdentity';
import {SIGN_IN_PATH} from '@/paths';
import {compilePath} from '@/utils';

type EmptyStateContentProps = {
  text: React.ReactNode;
  buttonText?: React.ReactNode;
  path?: string;
};

const EmptyStateContent: React.FC<EmptyStateContentProps> = ({text, buttonText, path}) => (
  <div className="align-center flex flex-col py-2 text-center">
    <Icon size="xlarge" icon={faCartCircleXmark} className="mb-4 self-center text-6xl" />
    <div>{text}</div>
    {buttonText && (
      <Link
        className="mx-1 mt-4 w-24 self-center whitespace-nowrap rounded-lg bg-ezgreen-400 px-4 py-2 text-white no-underline hover:bg-ezgreen-500 hover:text-white hover:no-underline active:bg-ezgreen-600 active:text-white active:no-underline"
        href={path}
      >
        {buttonText}
      </Link>
    )}
  </div>
);

const EmptyState: React.FC = () => {
  const {t} = useTranslation('app-bar');
  const {data} = useIdentity();
  const loginPath = compilePath(SIGN_IN_PATH);
  const isLoggedIn = !!data?.me?.consumerAccount;

  return (
    <div>
      {isLoggedIn ? (
        <EmptyStateContent
          text={<Trans i18nKey="app-bar:cart.empty.label" components={{br: <br />}} />}
        />
      ) : (
        <EmptyStateContent
          text={<Trans i18nKey="app-bar:cart.empty.label" components={{br: <br />}} />}
          buttonText={t('navLinks.signIn')}
          path={loginPath}
        />
      )}
    </div>
  );
};

export default EmptyState;
