import {type KeyboardEvent} from 'react';
import {useCallback} from 'react';
import {useLatest} from 'react-use';

type UseDialogProps = {
  onDismiss: () => void;
};

type UseDialogPayload = {
  role: string;
  'aria-modal': boolean;
  tabIndex: number;
  onKeyDown: (event: KeyboardEvent) => void;
};

const useDialog = ({onDismiss}: UseDialogProps): UseDialogPayload => {
  const onDismissRef = useLatest(onDismiss);
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.defaultPrevented) return;
      if (event.key !== 'Escape') return;
      event.stopPropagation();
      onDismissRef.current();
    },
    [onDismissRef],
  );

  return {
    role: 'dialog',
    'aria-modal': true,
    tabIndex: -1,
    onKeyDown,
  };
};

export default useDialog;
