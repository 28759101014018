import {useCallback, useState} from 'react';

type ToggleStatePayload = {
  toggleValue: boolean;
  on: () => void;
  off: () => void;
  toggle: () => void;
  setValue: (newValue: boolean) => void;
};

const useToggleState = (initialValue?: boolean): ToggleStatePayload => {
  const [isOpen, setIsOpen] = useState(Boolean(initialValue));
  const on = useCallback(() => setIsOpen(true), []);
  const off = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen(currentValue => !currentValue), []);

  return {
    toggleValue: isOpen,
    on,
    off,
    toggle,
    setValue: setIsOpen,
  };
};

export default useToggleState;
