// eslint-disable-next-line no-restricted-imports
import {EzLink} from '@ezcater/recipe';

import {ORDER_VIEW_PATH} from '@/paths';
import compilePath from '@/utils/compilePath';
import {Card, Details, Title} from './DirectConnectNotification.styles';

type MaybeString = string | null;

export type DirectConnectNotificationProps = {
  brandName: MaybeString;
  changeType: MaybeString;
  isUnread: boolean;
  message: MaybeString;
  messageType: MaybeString;
  orderUuid: string;
  onCloseCallback: () => void;
  trackLink: (path: string, title: string) => void;
};

const DirectConnectNotification: React.FC<DirectConnectNotificationProps> = ({
  brandName,
  changeType,
  isUnread,
  message,
  messageType,
  orderUuid,
  onCloseCallback,
  trackLink,
}) => {
  const messageTypeText = {
    TimeChangeRequest: 'New Delivery Time Requested:',
    Message: 'New Message:',
    OrderItemChangeRequest: `${changeType} Requested:`,
  };

  return (
    <Card
      data-testid={`direct-connect-notification-${orderUuid}`}
      isUnread={isUnread}
      onClick={() => {
        trackLink(
          compilePath(ORDER_VIEW_PATH, {orderId: orderUuid}),
          `Notification for order ${orderUuid} and brand ${brandName}`,
        );
        onCloseCallback();
      }}
    >
      <EzLink href={compilePath(ORDER_VIEW_PATH, {orderId: orderUuid})}>
        <Title>{brandName}</Title>
      </EzLink>
      <Details>
        <strong>
          {messageType ? messageTypeText[messageType as keyof typeof messageTypeText] : ''}
        </strong>{' '}
        {message}
      </Details>
    </Card>
  );
};

export default DirectConnectNotification;
