import {EzModal} from '@ezcater/recipe';

import {Wrapper} from './Modal.styles';

type ModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  close: () => void;
}>;

const Modal: React.FC<ModalProps> = ({isOpen, close, children}) => {
  return (
    <EzModal isOpen={isOpen} onDismiss={close} headerText="Draft Carts" dismissLabel="Close">
      <Wrapper>{children}</Wrapper>
    </EzModal>
  );
};

export default Modal;
