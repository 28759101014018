import {useEffect, useState} from 'react';
import {Button, Icon} from '@ezcater/tapas';
import {faSpinnerThird, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faLocationCrosshairs} from '@fortawesome/pro-solid-svg-icons';
import {ClickAwayListener} from '@mui/base';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import {useGeocodeCoordinatesQueryLazyQuery} from '@/graphql/types';
import useTracking from '@/hooks/useTracking';
import {geocodedAddressResultToString} from '@/utils/geocodedAddressResult';
import Ribbon, {RibbonState} from '../EventBar/Ribbon';
import useGeolocation from '../GeolocationProvider/useGeolocation';
import {AddressSearchAutocompleteSuffixProps} from './types';

const AddressSearchAutocompleteSuffix: React.FC<AddressSearchAutocompleteSuffixProps> = ({
  geocodedAddressResultHelpers,
  inputHelpers,
  onClear,
  showClearButton = true,
}) => {
  const [hasErrors, setHasErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {track, trackClick} = useTracking();
  const {t} = useTranslation('common');
  const [geocodeCoordinatesQuery, {error: queryError}] = useGeocodeCoordinatesQueryLazyQuery();
  const {getCurrentPosition} = useGeolocation();

  const handleGeolocationSuccess = async (position: GeolocationPosition) => {
    setIsLoading(true);

    track('precise-location-granted', {
      page: window.location.pathname,
      url: window.location.href,
    });

    const {latitude, longitude} = position.coords;
    const {data} = await geocodeCoordinatesQuery({variables: {latitude, longitude}});
    const firstGeocodedAddressResult = data?.geocodedCoordinates?.[0];
    setIsLoading(false);

    if (firstGeocodedAddressResult) {
      const addressString = geocodedAddressResultToString(firstGeocodedAddressResult);
      inputHelpers.setValue(addressString);
      geocodedAddressResultHelpers.setValue(firstGeocodedAddressResult);

      track('precise-location-address-filled', {
        misc_json: JSON.stringify({address_string: addressString}),
        page: window.location.pathname,
        url: window.location.href,
      });
    }
  };

  const handleGeolocationError = (positionError: GeolocationPositionError) => {
    track('precise-location-denied', {
      misc_json: JSON.stringify({error_message: positionError.message}),
      page: window.location.pathname,
      url: window.location.href,
    });

    setHasErrors(true);
  };

  const handleGetGeolocation = () => {
    setIsLoading(true);
    setHasErrors(false);
    trackClick('precise-location-target');
    getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
  };

  useEffect(() => {
    if (queryError) setHasErrors(true);
  }, [queryError]);

  return (
    <div className="-mr-2 flex">
      {showClearButton && (
        <Button
          aria-label={t('components.AddressSearchAutocomplete.aria.clear')}
          className="p-1"
          onClick={onClear}
          variant="secondary"
        >
          <Icon icon={faXmark} size="xsmall" />
        </Button>
      )}

      <Button
        aria-label={t('components.AddressSearchAutocomplete.locator.aria.label')}
        disabled={isLoading}
        className="p-1"
        onClick={handleGetGeolocation}
        variant="secondary"
      >
        {isLoading ? (
          <div aria-hidden className="flex animate-spin">
            <Icon icon={faSpinnerThird} size="xsmall" />
            <span className="sr-only">
              {t('components.AddressSearchAutocomplete.locator.aria.loading')}
            </span>
          </div>
        ) : (
          <Icon
            className={twJoin('text-lg', hasErrors ? 'text-pomegranate-400' : 'text-blueberry-400')}
            icon={faLocationCrosshairs}
          />
        )}
      </Button>

      {hasErrors && (
        <ClickAwayListener onClickAway={() => setHasErrors(false)}>
          <div className="absolute -right-[.3rem] top-8 z-1350">
            <Ribbon
              arrowPlacement="up"
              message={
                <>
                  {t('components.AddressSearchAutocomplete.locator.error.line1')}
                  <br />
                  {t('components.AddressSearchAutocomplete.locator.error.line2')}
                </>
              }
              state={RibbonState.Error}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default AddressSearchAutocompleteSuffix;
