import styled from '@emotion/styled';

import appColors from '@/components/RootProvider/appColors';
import recipeMq from '@/styles/recipeMq';

export const NotificationBadge = styled.span`
  display: flex;
  position: relative;
  width: 23px;
  margin-right: -23px;
  height: 17px;
  left: -29px;
  top: -9px;

  background: ${appColors.red};
  border: 2px solid ${appColors.white};
  border-radius: 100px;

  p {
    margin: auto;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    color: ${appColors.white};
    align-self: center;
  }

  ${recipeMq('baseToMedium')} {
    p {
      color: ${appColors.white};
    }

    left: -19px;
  }
`;

export const DirectConnectMouseContainer = styled.div`
  padding: 12px 0;
`;
