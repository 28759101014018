import styled from '@emotion/styled';

import recipeMq from '@/styles/recipeMq';

const MobileAndTabletExperience = styled.div`
  display: none;

  ${recipeMq('baseToXLarge')} {
    display: block;
  }
`;

export default MobileAndTabletExperience;
