import {useContext} from 'react';
import invariant from 'tiny-invariant';

import DerivedDataContext from '../DerivedMenuDataProvider/context';
import {type DerivedContext} from '../DerivedMenuDataProvider/types';

const useDerivedMenuData = (): DerivedContext => {
  const contextValue = useContext(DerivedDataContext);
  invariant(contextValue.caterer != null, 'caterer is null');
  return contextValue;
};

export default useDerivedMenuData;
