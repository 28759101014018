// @ts-strict-ignore
import {useMemo} from 'react';

import allowCustomQuantitiesOver100 from '@/utils/allowCustomQuantitiesOver100';
import Constants from '../QuantitySelect/Constants';

const useQuantityOptions = quantityRules => {
  const allowCustomQuantity = allowCustomQuantitiesOver100(quantityRules?.quantities);
  const options = useMemo(() => {
    const opts = quantityRules?.quantities?.map(quantity => {
      return {
        label:
          quantity === 1
            ? `${quantity} ${quantityRules.label.singular}`
            : `${quantity} ${quantityRules.label.plural}`,
        value: quantity,
      };
    });

    if (allowCustomQuantity) {
      opts.push({label: '100+', value: Constants.CUSTOM});
    }

    return opts;
  }, [allowCustomQuantity, quantityRules]);

  return options;
};

export default useQuantityOptions;
