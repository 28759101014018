import styled from '@emotion/styled';

import {ROOT_PATH} from '@/paths';

const LogoContainer = styled.div`
  padding-top: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

type LogoProps = {
  href?: string;
  trackLink: (path: string, title: string) => void;
};

const Logo2023: React.FC<LogoProps> = ({href = ROOT_PATH, trackLink}) => {
  return (
    <LogoContainer>
      <a href={href} onClick={() => trackLink(href, 'logo')}>
        <svg
          width="120"
          height="30"
          viewBox="0 0 360 92"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid="ezcater-logo-2023"
          aria-label="ezCater Homepage"
        >
          <path
            d="M176.72 57.1499C175.41 58.5199 174 59.5999 172.51 60.3499C170.85 61.1799 168.79 61.5999 166.39 61.5999C164.18 61.5999 162.17 61.1999 160.42 60.4199C158.67 59.6399 157.15 58.5499 155.9 57.1799C154.65 55.7999 153.66 54.1599 152.96 52.2899C152.26 50.4099 151.9 48.3899 151.9 46.2799C151.9 44.1699 152.21 42.0599 152.82 40.0899C153.42 38.1499 154.32 36.4099 155.49 34.9299C156.63 33.4799 158.08 32.3199 159.78 31.4899C161.48 30.6599 163.52 30.2299 165.86 30.2299C168.2 30.2299 170.34 30.6499 172.04 31.4899C173.62 32.2599 175.16 33.4099 176.64 34.8599C176.94 33.9599 177.27 33.0799 177.64 32.2099C179.01 29.0499 180.79 26.1599 182.95 23.5699C181.21 22.2799 179.31 21.2199 177.26 20.4199C173.78 19.0699 170.09 18.3799 166.29 18.3799C162.2 18.3799 158.44 19.0699 155.09 20.4299C151.73 21.7999 148.81 23.7299 146.41 26.1699C144.01 28.6099 142.12 31.5699 140.79 34.9599C139.47 38.3399 138.8 42.0799 138.8 46.0899C138.8 50.0999 139.47 53.8299 140.79 57.1699C142.12 60.5299 144.04 63.4599 146.48 65.8599C148.92 68.2599 151.85 70.1499 155.21 71.4799C158.55 72.7999 162.28 73.4699 166.29 73.4699C170.08 73.4699 173.72 72.8399 177.13 71.5999C179.25 70.8199 181.22 69.7299 183.03 68.3299C180.83 65.7299 179.02 62.7899 177.64 59.5499C177.3 58.7599 177.01 57.9599 176.73 57.1499H176.72Z"
            fill="#00B373"
          />
          <path
            d="M322.74 45.9699C322.74 42.6399 322.28 39.3099 321.37 36.0799C320.44 32.7899 318.92 29.7899 316.86 27.1699C314.77 24.5299 312.08 22.3599 308.86 20.7299C305.62 19.0899 301.64 18.2599 297.02 18.2599C293.14 18.2599 289.49 18.9699 286.17 20.3699C282.85 21.7699 279.95 23.7399 277.55 26.2099C275.15 28.6899 273.24 31.6299 271.89 34.9699C270.53 38.3099 269.84 41.9799 269.84 45.8599C269.84 49.7399 270.47 53.3799 271.72 56.7099C272.97 60.0599 274.78 63.0199 277.11 65.4899C279.45 67.9699 282.33 69.9499 285.7 71.3499C289.06 72.7499 292.83 73.4599 296.92 73.4599C306.8 73.4599 314.6 69.8399 320.12 62.7099L321.06 61.4899L311.53 54.2099L310.59 55.3699C308.89 57.4599 307 59.1699 305 60.4299C303.09 61.6299 300.8 62.2399 298.17 62.2399C296.19 62.2399 294.24 61.9199 292.39 61.2799C290.55 60.6399 288.91 59.7499 287.53 58.6099C286.16 57.4899 285.04 56.1199 284.2 54.5299C283.61 53.4099 283.22 52.1799 283.06 50.8699H322.74V45.9799V45.9699ZM283.08 40.2499C283.23 39.2799 283.52 38.2999 283.94 37.3099C284.6 35.7799 285.54 34.3599 286.73 33.0999C287.91 31.8599 289.39 30.8299 291.12 30.0399C292.83 29.2599 294.75 28.8699 296.82 28.8699C298.89 28.8699 300.88 29.1999 302.47 29.8599C304.03 30.4999 305.34 31.3899 306.37 32.5099C307.4 33.6299 308.19 34.9799 308.72 36.5199C309.11 37.6699 309.38 38.9099 309.52 40.2499H283.09H283.08Z"
            fill="#00B373"
          />
          <path
            d="M359.02 19.3099C355.11 17.9999 351.9 18.0199 348.54 19.0099C346.89 19.4999 345.34 20.1699 343.95 20.9899C342.55 21.8199 341.26 22.8399 340.12 24.0199C340.09 24.0499 340.07 24.0799 340.04 24.0999V19.5199H327.55V72.2099H340.04V45.7599C340.04 40.7499 341.13 37.0499 343.28 34.7799C345.44 32.4999 348.53 31.3899 352.74 31.3899C352.74 31.3899 355.66 31.3599 359.02 32.5699V19.3099Z"
            fill="#00B373"
          />
          <path
            d="M262.4 19.5199V6.68994H249.9V19.5199H243.11V31.2099H249.9V72.1899H262.4V31.2099H269.19V19.5199H262.4Z"
            fill="#00B373"
          />
          <path
            d="M224.87 19.5199V22.3999C223.65 21.6499 222.37 20.9899 221.04 20.4299C217.66 18.9999 213.97 18.2699 210.08 18.2699C206.19 18.2699 202.5 18.9999 199.12 20.4299C195.74 21.8599 192.76 23.8499 190.26 26.3599C187.76 28.8599 185.77 31.7999 184.34 35.1099C182.9 38.4299 182.17 42.0599 182.17 45.8699C182.17 49.6799 182.9 53.3199 184.34 56.6799C185.78 60.0299 187.77 62.9699 190.27 65.4299C192.77 67.8999 195.75 69.8699 199.12 71.2999C202.51 72.7399 206.19 73.4699 210.08 73.4699C213.97 73.4699 217.65 72.7399 221.04 71.3099C222.37 70.7499 223.66 70.0899 224.88 69.3499V72.2199H237.37V19.5199H224.88H224.87ZM224.87 45.8599C224.87 48.1799 224.51 50.3499 223.81 52.2999C223.12 54.2199 222.13 55.8799 220.89 57.2199C219.64 58.5599 218.09 59.6299 216.27 60.4199C214.45 61.1999 212.36 61.5999 210.07 61.5999C207.78 61.5999 205.69 61.1999 203.87 60.4199C202.05 59.6399 200.5 58.5599 199.26 57.2199C198.01 55.8799 197.03 54.2299 196.34 52.2999C195.64 50.3599 195.28 48.1899 195.28 45.8599C195.28 43.5299 195.64 41.3599 196.34 39.4199C197.03 37.4999 198.01 35.8499 199.26 34.4999C200.5 33.1599 202.05 32.0899 203.87 31.3099C205.69 30.5199 207.78 30.1299 210.07 30.1299C212.36 30.1299 214.44 30.5299 216.27 31.3099C218.09 32.0899 219.64 33.1699 220.88 34.4999C222.13 35.8399 223.11 37.4999 223.8 39.4199C224.5 41.3699 224.86 43.5399 224.86 45.8599H224.87Z"
            fill="#00B373"
          />
          <path
            d="M133.42 90.02H1.26001V1.26001H133.42V90.02ZM11.8 79.36H122.75V11.94H11.81V79.36H11.8Z"
            fill="#00B373"
          />
          <path
            d="M89.26 61.18L115.95 30.05V19.52H73.94V30.81H99.49L73 61.84V72.47H117.43V61.18H89.26Z"
            fill="#00B373"
          />
          <path
            d="M70.3 46.1C70.3 42.75 69.84 39.4 68.93 36.16C68 32.86 66.48 29.85 64.4 27.21C62.31 24.55 59.6 22.38 56.36 20.74C53.1 19.1 49.1 18.26 44.46 18.26C40.55 18.26 36.88 18.97 33.55 20.38C30.22 21.79 27.3 23.76 24.89 26.25C22.48 28.74 20.57 31.7 19.2 35.05C17.83 38.41 17.14 42.09 17.14 45.99C17.14 49.89 17.77 53.55 19.03 56.89C20.29 60.26 22.11 63.23 24.45 65.72C26.8 68.22 29.7 70.2 33.08 71.61C36.45 73.02 40.25 73.73 44.35 73.73C54.28 73.73 62.12 70.09 67.66 62.92L68.61 61.69L59.03 54.37L58.09 55.53C56.38 57.63 54.49 59.34 52.47 60.62C50.56 61.82 48.25 62.43 45.61 62.43C43.62 62.43 41.66 62.1 39.8 61.46C37.95 60.83 36.31 59.92 34.91 58.78C33.54 57.66 32.41 56.28 31.56 54.68C30.96 53.55 30.58 52.32 30.41 51H70.29V46.09L70.3 46.1ZM30.44 40.35C30.59 39.38 30.88 38.39 31.3 37.4C31.96 35.86 32.9 34.44 34.1 33.17C35.29 31.92 36.77 30.89 38.51 30.1C40.23 29.32 42.15 28.92 44.23 28.92C46.31 28.92 48.31 29.25 49.9 29.91C51.46 30.55 52.79 31.45 53.82 32.57C54.86 33.71 55.65 35.06 56.18 36.6C56.57 37.76 56.84 39.01 56.99 40.35H30.43H30.44Z"
            fill="#00B373"
          />
        </svg>
      </a>
    </LogoContainer>
  );
};

export default Logo2023;
