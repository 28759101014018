import {
  FulfillmentDetailAddressInput,
  type GeocodedAddressResult,
  type Maybe,
} from '@/graphql/types';

type StartNewSearchAddressParams = {
  'address[street]'?: string;
  'address[street2]'?: Maybe<string>;
  'address[city]'?: string;
  'address[state]'?: string;
  'address[zip]'?: Maybe<string>;
  'address[latitude]'?: Maybe<string>;
  'address[longitude]'?: Maybe<string>;
};

type PartialAddress = {
  street?: Maybe<string>;
  street2?: Maybe<string>;
  city?: Maybe<string>;
  state?: Maybe<string>;
  zip?: Maybe<string>;
};

export const geocodedAddressResultToString = ({
  street,
  street2,
  city,
  state,
  zip,
}: GeocodedAddressResult | PartialAddress): string => {
  const formattedZip = (zip || '').split('-')[0];
  const stateZip = [state, formattedZip].filter(Boolean).join(' ').trim();

  return [street, street2, city, stateZip].filter(Boolean).join(', ').trim();
};

export const geocodedAddressResultToStartNewSearchAddressParams = (
  address: GeocodedAddressResult,
): StartNewSearchAddressParams => ({
  'address[street]': address.street,
  'address[street2]': address.street2,
  'address[city]': address.city,
  'address[state]': address.state,
  'address[zip]': address.zip,
  'address[latitude]': address.latitude?.toString(),
  'address[longitude]': address.longitude?.toString(),
});

export const geocodedAddressResultToFulfillmentDetailAddressInput = (
  address: GeocodedAddressResult,
): FulfillmentDetailAddressInput => ({
  street1: address.street,
  street2: address.street2,
  city: address.city,
  state: address.state,
  zip: address.zip,
  latitude: address.latitude,
  longitude: address.longitude,
});
