import {useMemo} from 'react';
import {useApolloClient} from '@apollo/client';
import {Card, Icon, Radio} from '@ezcater/tapas';
import {faBuilding} from '@fortawesome/pro-regular-svg-icons';
import {Form, Formik} from 'formik';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import AddressSearchAutocomplete from '@/components/AddressSearchAutocomplete';
import {useGlobalFulfillmentDetailMutation} from '@/components/GlobalFulfillmentDetailProvider/GlobalFulfillmentDetailProvider';
import {
  Address,
  AppBarFulfillmentDetailFragment,
  FulfillmentDetailAddressInput,
  FulfillmentDetailStrategy,
  GeocodeAddressQuery,
  GeocodeAddressQueryDocument,
  GeocodeAddressQueryVariables,
  GeocodedAddressResult,
} from '@/graphql/types';
import {type SavedAddressSearchQuery, useSavedAddressSearchQuery} from '@/graphql/types';
import useFetchGeoData from '@/hooks/useFetchGeoData';
import {
  geocodedAddressResultToFulfillmentDetailAddressInput,
  geocodedAddressResultToString,
} from '@/utils/geocodedAddressResult';

type LocationFormValues = {
  eventAddress: string;
  geocodedAddressResult: GeocodedAddressResult | null;
  googlePlace?: google.maps.places.AutocompletePrediction | null;
  strategy: FulfillmentDetailStrategy;
};

type LocationProps = {
  fulfillmentDetail: AppBarFulfillmentDetailFragment | null;
};

const EMPTY_ADDRESS_CONNECTION = {edges: [], totalCount: 0};

const extractAddresses = (data?: SavedAddressSearchQuery): Address[] => {
  const {edges} = data?.me?.consumerAccount?.addresses ?? EMPTY_ADDRESS_CONNECTION;
  const addresses = edges.map(({node}) => node).filter(node => node) as Address[];

  return addresses;
};

const Location: React.FC<LocationProps> = ({fulfillmentDetail}) => {
  const {t} = useTranslation('app-bar');

  const {updateFulfillmentDetail} = useGlobalFulfillmentDetailMutation();
  const fetchGeoData = useFetchGeoData();
  const apolloClient = useApolloClient();

  const {data} = useSavedAddressSearchQuery({
    variables: {searchString: ' ', limit: 2},
  });

  const savedAddresses = useMemo(() => extractAddresses(data), [data]);

  const updateOrderType = async (orderType: FulfillmentDetailStrategy) => {
    await updateFulfillmentDetail({
      strategy: orderType,
    });
  };

  const updateAddress = async (address: Address) => {
    const {errors} = await updateFulfillmentDetail({
      addressId: address.uuid,
    });

    if (!errors) {
      //#TODO: close the dropdown, once we have the provider in place
    }
  };

  const getGeocodedAddress = async (addressString: string) => {
    const {data: geocodeData} = await apolloClient.query<
      GeocodeAddressQuery,
      GeocodeAddressQueryVariables
    >({
      query: GeocodeAddressQueryDocument,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {addressString},
    });

    return geocodeData.geocodedAddress;
  };

  const submitForm = async (values: LocationFormValues) => {
    let updatedAddress: FulfillmentDetailAddressInput;

    // the user selected an autocomplete result
    if (values.googlePlace?.place_id) {
      const {data: geoData} = await fetchGeoData(values?.googlePlace.place_id);
      updatedAddress = geoData?.mapsAddress;

      // the user selected the geolocation button
    } else if (
      values.geocodedAddressResult &&
      geocodedAddressResultToString(values.geocodedAddressResult) === values.eventAddress
    ) {
      updatedAddress = geocodedAddressResultToFulfillmentDetailAddressInput(
        values.geocodedAddressResult,
      );

      // the user typed in an address
    } else {
      const geocodedAddress = await getGeocodedAddress(values.eventAddress);
      const {city, latitude, longitude, state, street, street2, zip} = geocodedAddress[0];
      updatedAddress = {
        city,
        latitude,
        longitude,
        state,
        street1: street,
        street2,
        zip,
      };
    }

    const {errors} = await updateFulfillmentDetail({
      address: updatedAddress,
    });

    if (!errors) {
      //#TODO: close the dropdown, once we have the provider in place
    }
  };

  const initialValues = {
    eventAddress: fulfillmentDetail?.address?.fullAddress || '',
    geocodedAddressResult: null,
    googlePlace: null,
    strategy: fulfillmentDetail?.strategy || FulfillmentDetailStrategy.Delivery,
  } satisfies LocationFormValues;

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm}>
      {({setFieldValue, values}) => {
        return (
          <Form className="mb-4 flex flex-col gap-4">
            {/* --- Strategy radio buttons --- */}
            <div className="flex flex-col gap-2">
              <div className="font-bold">{t('eventBar.eventLocation.orderType.label')}</div>

              <div className="flex gap-4">
                {(
                  Object.keys(
                    FulfillmentDetailStrategy,
                  ) as (keyof typeof FulfillmentDetailStrategy)[]
                ).map(strategy => {
                  const orderType = FulfillmentDetailStrategy[strategy];

                  return (
                    <Card
                      as="label"
                      className={twJoin(
                        'flex w-full cursor-pointer gap-2 p-3 hover:bg-peppercorn-50',
                        values.strategy === orderType && 'font-bold',
                      )}
                      key={strategy}
                    >
                      <Radio
                        checked={values.strategy === orderType}
                        name="strategy"
                        onChange={() => {
                          setFieldValue('strategy', orderType);
                          updateOrderType(orderType);
                        }}
                      />
                      {t(
                        `eventBar.eventLocation.orderType.${strategy === 'Delivery' ? 'delivery' : 'pickup'}`,
                      )}
                    </Card>
                  );
                })}
              </div>
            </div>

            {/* --- Saved addresses --- */}
            {savedAddresses.length > 0 && (
              <div className="flex flex-col gap-2">
                <div className="font-bold">{t('eventBar.eventLocation.savedAddress.label')}</div>

                {savedAddresses.map(address => (
                  <Card
                    as="button"
                    className={twJoin(
                      'flex w-full cursor-pointer items-center gap-4 p-3 hover:bg-peppercorn-50 hover:shadow-none active:border-peppercorn-300',
                    )}
                    key={address.uuid}
                    onClick={() => {
                      updateAddress(address);
                      setFieldValue('eventAddress', address.fullAddress);
                    }}
                  >
                    <div className="bg-kiwi-50 p-3">
                      <Icon icon={faBuilding} size="medium" />
                    </div>

                    <div className="flex w-3/4 flex-col text-left">
                      <span className="font-bold">{address.name}</span>
                      <span className="truncate font-normal">{address.fullAddress}</span>
                    </div>
                  </Card>
                ))}
              </div>
            )}

            {/* --- Event location --- */}
            <div className="flex flex-col gap-2">
              <label className="font-bold" htmlFor="event-location-address">
                {t('eventBar.eventLocation.addressSearch.label')}
              </label>

              <AddressSearchAutocomplete
                id="event-location-address"
                geocodedAddressResultName="geocodedAddressResult"
                googlePlaceName="googlePlace"
                inputName="eventAddress"
                savedAddressName="savedAddress"
                shouldIncludeSavedAddresses
                shouldSubmitOnSelect
                slotProps={{
                  input: {placeholder: 'Default address'},
                }}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Location;
