import {useState} from 'react';
import {Button, Icon} from '@ezcater/tapas';
import {faAngleDown, type IconDefinition} from '@fortawesome/pro-light-svg-icons';
import {twMerge} from 'tailwind-merge';

import Popup from '@/components/Popup';

type EventBarPopupProps = React.ComponentProps<typeof Button> & {
  defaultOpen?: boolean;
  icon: IconDefinition;
  label: string;
};

const EventBarPopup: React.FC<EventBarPopupProps> = ({
  children,
  defaultOpen = false,
  icon,
  label,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const tailwindClasses = {
    active: 'active:border-ezgreen-600 active:bg-ezgreen-600',
    base: 'flex w-44 items-center gap-2 border-peppercorn-200 px-3 py-2 font-normal',
    focus:
      'focus:z-1 focus:border-ezgreen-600 focus:bg-ezgreen-600 focus:text-white focus:hover:border-ezgreen-600 focus:hover:bg-ezgreen-600',
    hover: 'hover:border-ezgreen-400 hover:bg-ezgreen-400 hover:text-white',
    open: 'z-1 border-ezgreen-600 bg-ezgreen-600 text-white hover:border-ezgreen-600 hover:bg-ezgreen-600',
  };

  return (
    <Popup
      onPopupToggle={setIsOpen}
      open={isOpen}
      placement="bottom-start"
      slotProps={{
        arrow: {className: 'left-16'},
        popup: {className: 'w-[460px]', offset: 15},
      }}
      trigger={
        <Button
          aria-label={label}
          variant="outlined"
          {...props}
          className={twMerge(
            tailwindClasses.base,
            tailwindClasses.hover,
            tailwindClasses.focus,
            tailwindClasses.active,
            isOpen && tailwindClasses.open,
            props?.className,
          )}
        >
          <Icon className="shrink-0" icon={icon} />
          <span className="truncate whitespace-nowrap">{label}</span>
          <Icon className="shrink-0" icon={faAngleDown} />
        </Button>
      }
    >
      {children}
    </Popup>
  );
};

export default EventBarPopup;
