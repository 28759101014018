import {useEffect, useState} from 'react';

import {type GroupOrderUser} from '@/graphql/types';
import useIdentity from '@/hooks/useIdentity';
import {type GroupOrderEater, type ItemsGroupedByShopper} from '../GroupOrderCart/types';
import {type OrderItem} from '../types';

type UseGroupOrderEatersProps = {
  items: OrderItem[];
};

const useGroupOrderEaters = ({items}: UseGroupOrderEatersProps) => {
  const {data} = useIdentity();
  const [currentEater, setCurrentEater] = useState<GroupOrderEater | null>(null);
  const [eaters, setEaters] = useState<GroupOrderEater[]>([]);

  const currentEaterShopperId = data?.identity?.id;

  useEffect(() => {
    // Group items by shopper
    const itemsGroupedByShopper = items.reduce((acc, item) => {
      const shopper = item.shopper as GroupOrderUser;

      if (shopper?.id && shopper?.name) {
        if (!acc[shopper.id]) {
          acc[shopper.id] = {
            shopperId: shopper.id,
            shopperName: shopper.name,
            items: [],
          };
        }
        acc[shopper.id].items.push(item as OrderItem);
      }

      return acc;
    }, {} as ItemsGroupedByShopper);

    const groupOrderEaters: GroupOrderEater[] = Object.values(itemsGroupedByShopper);

    const currentGroupOrderEaterIndex = groupOrderEaters.findIndex(
      eater => eater.shopperId === currentEaterShopperId,
    );

    let currentGroupOrderEater: GroupOrderEater | null = null;
    if (currentGroupOrderEaterIndex > -1) {
      currentGroupOrderEater = groupOrderEaters[currentGroupOrderEaterIndex];
      groupOrderEaters.splice(currentGroupOrderEaterIndex, 1);
    }

    setEaters(groupOrderEaters);
    setCurrentEater(currentGroupOrderEater);
  }, [items, currentEaterShopperId]);

  return {
    currentEaterShopperId,
    currentEater,
    eaters,
  };
};

export default useGroupOrderEaters;
