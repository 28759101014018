const allowCustomQuantitiesOver100 = (quantities: number[]): boolean => {
  if (!quantities || quantities.length < 2) {
    return false;
  }

  const [secondToLastQuantity, lastQuantity] = quantities.slice(-2);

  const nextQuantity = lastQuantity + (lastQuantity - secondToLastQuantity);

  return lastQuantity <= 100 && nextQuantity >= 100;
};

export default allowCustomQuantitiesOver100;
