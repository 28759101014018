import styled from '@emotion/styled';

type CurrentOrderProps = {
  isCurrentOrder: boolean;
};

export const CartOrderContainer = styled.div<CurrentOrderProps>`
  border-bottom: solid #cccfd5 1px;
  padding: 10px;

  &:hover {
    background: ${({isCurrentOrder}) => (isCurrentOrder ? 'unset' : '#f5f7f7')};
  }
`;

export const CartOrderDetails = styled.ul`
  padding: 0;
  margin: 0;
  text-align: left;
`;

export const Title = styled.span<CurrentOrderProps>`
  color: ${({isCurrentOrder}) => (isCurrentOrder ? '#565a5c' : '#3a64df')};
  font-weight: 700;
  font-size: 14px;
`;

export const CatererLocation = styled.p`
  color: #787878;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;

export const AccountName = styled.p`
  color: #787878;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;

export const OrderUpdatedLast = styled.p`
  font-size: 12px;
  color: #787878;
  margin: 0;
  line-height: 14px;
`;
