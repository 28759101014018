import {createContext} from 'react';

export type MenuGTMContextPayload = {
  registerMenuItemImpressionListener: (element: Element, menuItemId: string) => void;
  unregisterMenuItemImpressionListener: (element: Element) => void;
  trackMenuItemClicked: (menuItemId: string) => void;
  trackMenuItemViewed: (menuItemId: string) => void;
};

const MenuGTMContext = createContext<MenuGTMContextPayload | null>(null);

export default MenuGTMContext;
