import styled from '@emotion/styled';
// eslint-disable-next-line no-restricted-imports
import {EzIcon} from '@ezcater/recipe';

import appColors from '@/components/RootProvider/appColors';

export const StyledEzIcon = styled(EzIcon)`
  margin-left: auto;
  margin-right: 0;
`;

export const MobileLandingNavDropdownMenu = styled.div`
  cursor: pointer;
  background: ${appColors.gray200};
`;

export const NavDropdownClickableItem = styled.div`
  display: flex;
  align-items: center;
  color: ${appColors.gray500};
  text-decoration: none;
  cursor: pointer;
  padding-left: 10px;
  height: 50px;
`;
