import styled from '@emotion/styled';

import appColors from '@/components/RootProvider/appColors';
import recipeMq from '@/styles/recipeMq';

export const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`;

export const Dialog = styled.div`
  background: ${appColors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  outline: none;
  width: 100%;
  /* IE fix to prevent flex items overflowing. See: https://github.com/philipwalton/flexbugs#flexbug-3 */
  overflow-y: auto;

  &:focus {
    box-shadow:
      0px 0px 2px 2px ${appColors.blue600},
      0 1px 1px 0 rgba(0, 0, 0, 0.12);
  }

  ${recipeMq('medium')} {
    border-radius: 0.75rem;
  }
`;
