import {useMemo} from 'react';

import {
  type ItemQuantityRulesQuery,
  type OrderingMenuItemQuantityRules,
  type OrderingMenuItemSize,
  useItemQuantityRulesQuery,
  useItemQuantityRulesQueryLazyQuery,
} from '@/graphql/types';
import useMenuPageData from './useMenuPageData';

export type QuantityRulesData = Record<
  string,
  {
    quantityRules: OrderingMenuItemQuantityRules;
    sizes: OrderingMenuItemSize[];
  }
>;

const createAdditionalItemData = (quantityRulesData: ItemQuantityRulesQuery | undefined) => {
  if (quantityRulesData == null) return {};

  const itemMapping: QuantityRulesData = {};
  quantityRulesData.caterer?.orderingMenu?.categories.forEach(category => {
    category.allItems.forEach(ruleItem => {
      itemMapping[ruleItem.id] = {
        quantityRules: ruleItem.quantityRules,
        sizes: ruleItem.sizes,
      } as QuantityRulesData['menuItemUuid'];
    });
  });
  return itemMapping;
};

export const useQuantityRulesMapLazy = (
  date: string,
  urlId: string,
): (() => Promise<QuantityRulesData>) => {
  const [itemQuantityRulesQuery] = useItemQuantityRulesQueryLazyQuery({
    errorPolicy: 'all',
    variables: {
      urlId,
      date,
    },
  });

  const createQuantityRulesMap = async () => {
    const {data: quantityRulesData} = await itemQuantityRulesQuery();
    return createAdditionalItemData(quantityRulesData);
  };

  return createQuantityRulesMap;
};

const useQuantityRulesMap = (): {
  data: QuantityRulesData;
  loading: boolean;
} => {
  const {date, catererUrlId} = useMenuPageData();

  const {data: quantityRulesData, loading: quantityRulesLoading} = useItemQuantityRulesQuery({
    errorPolicy: 'all',
    variables: {
      urlId: catererUrlId,
      date,
    },
  });

  const additionalItemData = useMemo(
    () => createAdditionalItemData(quantityRulesData),
    [quantityRulesData],
  );

  return {data: additionalItemData, loading: quantityRulesLoading};
};

export default useQuantityRulesMap;
