import styled from '@emotion/styled';

import recipeMq from '@/styles/recipeMq';

const MobileExperience = styled.div<{display?: string}>`
  display: ${({display = 'block'}) => display};

  ${recipeMq('medium')} {
    display: none;
  }
`;

export default MobileExperience;
