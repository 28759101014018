import styled from '@emotion/styled';
// eslint-disable-next-line no-restricted-imports
import {EzIcon, EzLink} from '@ezcater/recipe';

import appColors from '../RootProvider/appColors';
import appFonts from '../RootProvider/appFonts';

export const StyledEzIcon = styled(EzIcon)`
  color: ${appColors.darkGray} !important;
  font-size: ${appFonts.textBase};
`;

export const StyledEzLink = styled(EzLink)`
  padding: 11px 8px;
  border-radius: 4px;
  margin-right: 4px;
  color: ${appColors.darkGray};
  font-weight: normal;

  &:hover,
  &:active,
  &:focus {
    background-color: ${appColors.primary90};
    border-color: ${appColors.primary90};
    color: ${appColors.darkGray};
    font-weight: normal;
  }

  @media (max-width: 320px) {
    display: none;
  }
`;

export const StyledSupportNumberLink = styled(StyledEzLink)`
  @media (max-width: 1380px) {
    display: none;
  }
`;

export const AdminLink = styled(EzLink)`
  color: ${appColors.cilantroGreen};
  border: 1px solid ${appColors.cilantroGreen};
  border-radius: 4px;
  margin-right: 48px;
  padding: 8px;
  font-family: Lato;
  font-weight: 400;

  &:hover,
  &:active,
  &:focus {
    color: ${appColors.cilantroGreen};
    border: 1px solid ${appColors.cilantroGreen};
    text-decoration: none;
  }
`;

export const ChannelPartnerLogo = styled.img`
  width: 120px;
  height: 30px;
`;
