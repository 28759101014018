import {useEffect, useState} from 'react';

import {safeLocalStorage} from '@/utils/storage';

const useLocalStorageFilters = (fullAddress: string | undefined) => {
  const [filters, setFilters] = useState<string | null>(null);

  useEffect(() => {
    if (!fullAddress) return;
    const storedFilters = safeLocalStorage.getItem(fullAddress);
    setFilters(storedFilters);
  }, [fullAddress]);

  return filters;
};

export default useLocalStorageFilters;
