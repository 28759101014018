import useTranslation from 'next-translate/useTranslation';

import {CONTACT_PATH} from '@/paths';
import MenuAccordion from './MenuAccordion';
import MenuLink from './MenuLink';

const ContactUsMenu: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  return (
    <MenuAccordion ariaLabel={t('navLinks.contactUs')} header={t('navLinks.contactUs')}>
      <MenuLink label={t('navLinks.sendUsAMessage')} path={CONTACT_PATH} />

      <MenuLink
        label={t('navLinks.supportEmailLabelled')}
        href={t('navLinks.supportEmailMailTo')}
      />

      <MenuLink
        label={t('navLinks.supportPhoneNumberLabelled')}
        href={t('navLinks.supportPhoneNumberTel')}
      />
    </MenuAccordion>
  );
};

export default ContactUsMenu;
