import {useApolloClient} from '@apollo/client';

import {
  GeocodeAddressQuery,
  GeocodeAddressQueryDocument,
  GeocodeAddressQueryVariables,
} from '@/graphql/types';
import {
  GeocodedAddress,
  geocodedAddressResultsToGeocodedAddresses,
} from '@/utils/googleAutocomplete';

export function useGoogleMapsGeocode() {
  const apolloClient = useApolloClient();

  const fetchGeocodedAddress = async (addressString: string): Promise<GeocodedAddress[]> => {
    const {data: geocodeData} = await apolloClient.query<
      GeocodeAddressQuery,
      GeocodeAddressQueryVariables
    >({
      query: GeocodeAddressQueryDocument,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {addressString},
    });

    return geocodedAddressResultsToGeocodedAddresses(geocodeData.geocodedAddress);
  };

  const geocodeAutocompletePrediction = async (
    prediction: google.maps.places.AutocompletePrediction | null,
  ) => {
    if (!prediction) return null;

    const geocodedAddressResults = await fetchGeocodedAddress(prediction.description);

    return geocodedAddressResults;
  };

  const geocodePlaceResult = async (
    placeData: google.maps.places.PlaceResult,
  ): Promise<GeocodedAddress | null> => {
    if (!placeData?.formatted_address) return null;

    const geocodedAddressResults = await fetchGeocodedAddress(placeData.formatted_address);

    return geocodedAddressResults[0];
  };

  return {
    fetchGeocodedAddress,
    geocodeAutocompletePrediction,
    geocodePlaceResult,
  };
}
