import {useRecommendedDefaultAddressQuery} from '@/graphql/types';
import {START_SEARCH_PATH} from '@/paths';
import compilePath from '@/utils/compilePath';
import {geocodedAddressResultToStartNewSearchAddressParams} from '@/utils/geocodedAddressResult';

type EligibilityInputs = {
  onMenuPage: boolean;
  onSearchPage: boolean;
};

type EligibilityReturnValues = {
  startANewOrderButtonSearchPath: string | null;
  shouldShowStartANewOrderButton: boolean;
};

const useStartANewOrderEligibility = ({
  onMenuPage,
  onSearchPage,
}: EligibilityInputs): EligibilityReturnValues => {
  const shouldLoadAddress = !onMenuPage && !onSearchPage;

  const {data} = useRecommendedDefaultAddressQuery({
    skip: !shouldLoadAddress,
  });

  const userRecommendedDefaultAddress = data?.identity?.recommendedDefaultAddress;
  const shouldShowStartANewOrderButton = !!userRecommendedDefaultAddress && shouldLoadAddress;

  const startANewOrderButtonSearchPath = userRecommendedDefaultAddress
    ? compilePath(
        START_SEARCH_PATH,
        {},
        geocodedAddressResultToStartNewSearchAddressParams(userRecommendedDefaultAddress),
        {skipEmptyString: true, skipNull: true},
      )
    : null;

  return {
    startANewOrderButtonSearchPath,
    shouldShowStartANewOrderButton,
  };
};

export default useStartANewOrderEligibility;
