import useTranslation from 'next-translate/useTranslation';

import {
  CATERED_MEETINGS_EVENTS_PATH,
  CONCIERGE_ORDERING_PATH,
  CORPORATE_SOLUTIONS_PATH,
  FOOD_SPEND_MANAGEMENT_PATH,
  RECURRING_EMPLOYEE_MEALS_PATH,
  TAX_EXEMPT_PATH,
} from '@/paths';
import MenuAccordion from './MenuAccordion';
import MenuLink from './MenuLink';

type CompanyMenuProps = {
  isAccordion?: boolean;
};

const CompanyLinks: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');

  return (
    <div className="flex flex-col gap-6 p-2">
      <MenuLink label={t('navLinks.corporateCateringOverview')} path={CORPORATE_SOLUTIONS_PATH} />

      <MenuLink
        label={t('navLinks.meetingsAndEventsCatering')}
        path={CATERED_MEETINGS_EVENTS_PATH}
      />

      <MenuLink label={t('navLinks.employeeMealPrograms')} path={RECURRING_EMPLOYEE_MEALS_PATH} />
      <MenuLink label={t('navLinks.conciergeOrdering')} path={CONCIERGE_ORDERING_PATH} />
      <MenuLink label={t('navLinks.foodSpendManagementTools')} path={FOOD_SPEND_MANAGEMENT_PATH} />
      <MenuLink label={t('navLinks.taxExemptOrdering')} path={TAX_EXEMPT_PATH} />
    </div>
  );
};

const CompanyMenu: React.FC<CompanyMenuProps> = ({isAccordion = false}) => {
  const {t} = useTranslation('app-bar');

  if (!isAccordion) return <CompanyLinks />;

  return (
    <MenuAccordion
      ariaLabel={t('navLinks.corporateCateringSolutions')}
      header={t('navLinks.corporateCateringSolutions')}
    >
      <CompanyLinks />
    </MenuAccordion>
  );
};

export default CompanyMenu;
