import {useEffect, useState} from 'react';
import {faCalendar, faLocationDot, faUsers} from '@fortawesome/pro-light-svg-icons';
import {format} from 'date-fns/format';
import useTranslation from 'next-translate/useTranslation';

import {useGlobalFulfillmentDetail} from '@/components/GlobalFulfillmentDetailProvider/GlobalFulfillmentDetailProvider';
import DateTime from './DateTime';
import Details from './Details';
import EventBarPopup from './EventBarPopup';
import Location from './Location';

const EventBar: React.FC<unknown> = () => {
  const {t} = useTranslation('app-bar');
  const fulfillmentDetail = useGlobalFulfillmentDetail();
  const {eventAt} = fulfillmentDetail || {};
  const [dateLabel, setDateLabel] = useState('');
  const [locationLabel, setLocationLabel] = useState<string>(t('eventBar.eventLocation.label'));

  // Storing the label in a useState prevents hydration errors
  useEffect(() => {
    if (fulfillmentDetail) {
      setLocationLabel(fulfillmentDetail?.address.fullAddress);
    } else {
      setLocationLabel(t('eventBar.eventLocation.label'));
    }
    if (eventAt) {
      setDateLabel(format(eventAt, 'E LLL do'));
    } else {
      setDateLabel(t('eventBar.eventDate.label'));
    }
  }, [eventAt, fulfillmentDetail, t]);

  return (
    <div className="flex items-center rounded-lg">
      <EventBarPopup
        className="rounded-l-full"
        icon={faLocationDot}
        label={locationLabel}
        data-testid="location-dropdown"
      >
        <Location fulfillmentDetail={fulfillmentDetail} />
      </EventBarPopup>

      <EventBarPopup className="-mx-px rounded-none border-x-0" icon={faCalendar} label={dateLabel}>
        <DateTime fulfillmentDetail={fulfillmentDetail} />
      </EventBarPopup>

      <EventBarPopup
        className="rounded-r-full"
        icon={faUsers}
        label={t('eventBar.eventDetails.label')}
      >
        <Details />
      </EventBarPopup>
    </div>
  );
};

export default EventBar;
