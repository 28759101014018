import useTracking from '@/hooks/useTracking';
import {gtm} from '@/utils';

const useNavLinkTracking = () => {
  const {track} = useTracking();

  const trackLink = (path: string, title: string) => {
    track('navlink-clicked', {
      url: window.location.href,
      misc_json: JSON.stringify({item_clicked: {link: path, text: title}}),
    });

    gtm.dataLayer({
      event: 'track_event_header_nav',
      event_category: 'Navigation',
      event_action: 'Header',
      event_label: title,
      event_value: 0,
      non_interaction: false,
    });
  };

  return {trackLink};
};

export default useNavLinkTracking;
