import {forwardRef} from 'react';
import {Modal, Typography} from '@ezcater/tapas';
import {twMerge} from 'tailwind-merge';

type StickyModalProps = React.PropsWithChildren<{
  footer?: React.ReactNode;
  header: React.ReactNode;
  onDismiss: () => void;
  open?: boolean;
  slotProps?: {
    body?: React.ComponentProps<'div'>;
    closeButton?: NonNullable<React.ComponentProps<typeof Modal>['slotProps']>['closeButton'];
    content?: NonNullable<React.ComponentProps<typeof Modal>['slotProps']>['content'];
    header?: React.ComponentProps<'div'>;
    footer?: React.ComponentProps<'div'>;
    root?: React.ComponentProps<typeof Modal>;
  };
}>;

type Ref = HTMLElement;

const StickyModal = forwardRef<Ref, StickyModalProps>(
  ({children, footer, header, onDismiss, open = false, slotProps = {}, ...props}, ref) => (
    <Modal
      aria-labelledby={header ? 'modal-title' : undefined}
      open={open}
      onClose={onDismiss}
      ref={ref}
      {...props}
      slotProps={{
        closeButton: {'aria-label': 'close modal', ...slotProps?.closeButton},
        content: {
          className: 'p-0 tablet:h-auto tablet:min-h-[480px]',
          ...slotProps?.content,
        },
      }}
    >
      <div className="flex h-full flex-col overflow-y-auto text-peppercorn-800">
        {header && (
          <div
            {...slotProps?.header}
            className={twMerge('border-b p-6', slotProps?.header?.className)}
          >
            <Typography className="pr-10" id="modal-title" variant="heading-sm">
              {header}
            </Typography>
          </div>
        )}

        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0} // Required for keyboard scroll
          {...slotProps?.body}
          className={twMerge(
            'min-h-60 flex-1 overflow-y-auto px-8 py-6 focus-within:outline-peppercorn-800',
            slotProps?.body?.className,
          )}
        >
          {children}
        </div>

        {footer && (
          <div
            {...slotProps?.footer}
            className={twMerge('px-8 py-6 shadow-top', slotProps?.footer?.className)}
          >
            {footer}
          </div>
        )}
      </div>
    </Modal>
  ),
);

export default StickyModal;
