import {useContext} from 'react';
import invariant from 'tiny-invariant';

import {GeolocationContext, type GeolocationContextType} from './GeolocationProvider';

const useGeolocation = (): GeolocationContextType => {
  const contextValue = useContext(GeolocationContext);

  invariant(
    contextValue != null,
    'useGeolocation() was called but the GeolocationProvider was not used.',
  );

  return contextValue;
};

export default useGeolocation;
