import {Link} from '@ezcater/tapas';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import {twMerge} from 'tailwind-merge';

import appBarQr from '@/assets/icons/apps-flyer-app-download-cta-qr-app-bar.svg';
import leftMenuNavQr from '@/assets/icons/apps-flyer-native-app-download-with-ezcater-logo-in-left-menu-nav-qr.svg';
import logoVertical from '@/assets/images/logo-vertical-white.png';
import LargeDesktopExperience from '@/components/LegacyAppBar/components/LargeDesktopExperience';
import MobileAndTabletExperience from '@/components/LegacyAppBar/components/MobileAndTabletExperience';
import useTracking from '@/hooks/useTracking';

type AppDownloadCTAProps = {
  isRenderedInAppBar?: boolean;
};

const AppDownloadCTA: React.FC<AppDownloadCTAProps> = ({isRenderedInAppBar = false}) => {
  const {t} = useTranslation('common');
  const {trackClick} = useTracking();
  const qrCode = isRenderedInAppBar ? appBarQr : leftMenuNavQr;
  const appLink = isRenderedInAppBar
    ? 'https://ezcater.onelink.me/T4QA/d8jtnezw'
    : 'https://ezcater.onelink.me/T4QA/ron3zlpv';

  const onClick = () => {
    const eventLabel = isRenderedInAppBar
      ? 'app-download-cta-in-app-bar'
      : 'app-download-cta-in-left-menu-nav';
    trackClick(eventLabel);
  };

  return (
    <>
      <LargeDesktopExperience>
        <div
          className={twMerge(
            'flex items-center gap-3 rounded-lg bg-white py-2 pl-3 pr-4',
            isRenderedInAppBar ? 'max-w-72 bg-blueberry-50' : 'bg-white',
          )}
        >
          <Image alt="" src={qrCode.src} width="72" height="72" />
          <div className="flex flex-col items-start gap-1 py-0.5">
            <Link
              target="_blank"
              className="text-sm font-bold leading-6 text-black hover:text-black active:text-black"
              href={appLink}
              underline
              onClick={onClick}
            >
              {t('AppDownloadCTA.getApp')}
            </Link>
            <p className="text-xs font-normal leading-4 text-black">
              {t('AppDownloadCTA.downloadAppText')}
            </p>
          </div>
        </div>
      </LargeDesktopExperience>
      <MobileAndTabletExperience>
        <div className="flex items-center gap-3 px-4 py-2">
          <Image
            className="rounded-sm bg-ezgreen-300 p-1"
            alt=""
            src={logoVertical.src}
            width="20"
            height="20"
          />
          <Link
            href="https://ezcater.onelink.me/T4QA/7sd7rtk1"
            onClick={() => trackClick('app-download-cta-nav-mobile')}
            className="text-sm text-black hover:text-black hover:decoration-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('AppDownloadCTA.downloadApp')}
          </Link>
        </div>
      </MobileAndTabletExperience>
    </>
  );
};

export default AppDownloadCTA;
